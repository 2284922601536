import React from "react";
import CommonCard from "./CommonCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from "react";
import { GetRegionsLists } from "../Axios/cms.axios";
import { useEffect } from "react";

function Countries(props) {
  console.log("Countryesss", props);
  let Category = props?.Category;

  const [RegionsCountries, setRegionsCountries] = useState([]);
  const [refreshSlider, setRefreshSlider] = useState(false);

  console.log("RegionsCountries", RegionsCountries);

  useEffect(() => {
    AllCountryRegionsLists();
  }, [Category]);

  const AllCountryRegionsLists = async () => {
    try {
      let resp = await GetRegionsLists({ type: Category });
      console.log("REsponsesss", resp);

      let Countries = resp?.data?.filter((item) => item?.type == "Country");
      console.log("Counteireorer", Countries);
      // setRefreshSlider(false)
      setRegionsCountries(Countries);
      // setTimeout(()=>{
      //   setRefreshSlider(true)
      // },[1000]);
    } catch (error) {
      console.log("error", error);
    }
  };
  const Country = [
    // {
    //   id: 1,
    //   countryImg: require("../assets/images/landing/regions/hongkong-bg.png"),
    //   logo: require("../assets/images/landing/regions/hongkong-logo.png"),
    //   stays: "3800",
    //   name: "Hong Kong",
    //   footerbg: "",
    // },
    {
      id: 2,
      countryImg: require("../assets/images/landing/regions/india-bg.png"),
      logo: require("../assets/images/landing/regions/india-logo.png"),
      stays: "4800",
      name: "India",
      footerbg: "black",
    },
    {
      id: 3,
      countryImg: require("../assets/images/landing/regions/china-bg.png"),
      logo: require("../assets/images/landing/regions/china-logo.png"),
      stays: "4000",
      name: "China",
      footerbg: "black",
    },
    {
      id: 4,
      countryImg: require("../assets/images/landing/regions/hongkong-bg.png"),
      logo: require("../assets/images/landing/regions/hongkong-logo.png"),
      stays: "3800",
      name: "Hong Kong",
      footerbg: "",
    },
  ];

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    margin: 10,
    // arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="explore-countries">
      <h3 className="mb-0 fw-700 header-clr text-center">EXPLORE COUNTRIES</h3>
      {/* {
        refreshSlider
          ? */}
      {RegionsCountries?.length ? (
        <Slider {...settings} className="country-slider mt-4">
          {RegionsCountries.map((item, index) => {
            console.log("rrtrtrt", item);
            return (
              <CommonCard
                countryImg={item.stateImage}
                logo={item.flagImage}
                stays={item.amount}
                name={item.state}
                link={item.link}
                // footerbg={item.footerbg}
              />
            );
          })}
        </Slider>
      ) : (
        "No data found"
      )}
      {/* :
          <></>
      } */}
    </div>
  );
}

export default Countries;
