import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Dropdown from "react-bootstrap/Dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/esm/Button";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";
import { FaCirclePlus } from "react-icons/fa6";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import { FaRegStar } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { allData } from "../Data/CommonData";
import { BiRightArrowAlt } from "react-icons/bi";
import { BiSearch } from "react-icons/bi";
import bed from "../assets/images/bed.svg";
import map from "../assets/images/map.png";
import search from "../assets/images/search.png";
import SearchFilter from "../components/SearchFilter";
import HotelSelectFilter from "../components/HotelSelectFilter";
import HotelRoomsList from "../components/HotelRoomsList";
import ActivitiesSearchFilter from "../components/ActivitiesSearchFilter";
import ActivitiesSelectFilter from "../components/ActivitiesSelectFilter";
import ActivitiesRoomsList from "../components/ActivitiesRoomsList";
import Offcanvas from "react-bootstrap/Offcanvas";
import { BsSliders } from "react-icons/bs";
export default function ActivitiesSearch() {
  const [startDate, setStartDate] = useState(new Date());
  const [sliceVal, setSliceVal] = useState(3);
  console.log("sliceVal", sliceVal);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Rooms = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
  ];
  return (
    <>
      <Header />
      <div className="cmn_pdt">
      <div className="search-page">
        <div className="search-header">
          <div className="container custom-container-search">
            <ActivitiesSearchFilter />
            <div className="properties">
              <p className="mb-1 f-12 nav-home">
                <Link to="/">Home</Link>
                <IoIosArrowForward className="mx-2" />
                <Link to="/">Activity and more in Dubai</Link>
              </p>
              <h5 className="mb-0 fw-800">680 Activities in Dubai</h5>
            </div>
          </div>
        </div>
        <div className="sort-sec pb-3">
          <div className="container custom-container-search">
            <div className="select-sort-fields">
              <p className="mb-0 sort-by fw-700 f-15">SORT BY:</p>
              <div className="popular f-14 fw-600 cmn-tabs active">Popular</div>
              <div className="user-rating f-14 cmn-tabs">
                <span className="fw-600">User Rating</span> (Highest First)
              </div>
              <div className="price high f-14 cmn-tabs">
                {" "}
                <span className="fw-600">Price</span> (Highest First)
              </div>
              <div className="price low f-14 cmn-tabs">
                {" "}
                <span className="fw-600">Price</span> (Lowest First)
              </div>
              <div className="search-home">
                <input
                  type="text"
                  placeholder="Search for locality / activity name"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="filter-rooms">
          <div className="container custom-container-search">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-xl-3 col-lg-3 mb-3">
                <div className="select-filter-sec-web d-none d-lg-block">
                  <ActivitiesSelectFilter />
                </div>
                <div className="select-filter-sec-mbl d-block d-lg-none">
                  <div className="filter-slider" onClick={handleShow}>
                    <BsSliders />
                  </div>
                  <Offcanvas show={show} onHide={handleClose} className='selectFilter-offcanvas'>
                    <Offcanvas.Header closeButton></Offcanvas.Header>
                    <Offcanvas.Body className="filter-rooms">
                      <ActivitiesSelectFilter />
                    </Offcanvas.Body>
                  </Offcanvas>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
                <ActivitiesRoomsList />
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <Footer />
    </>
  );
}
