import React, { useState, useEffect, useRef } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Dropdown from "react-bootstrap/Dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TiStar } from "react-icons/ti";
import Button from "react-bootstrap/esm/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import PhotoModal from "../modals/PhotoModal";
import AmenitiesModal from "../modals/AmenitiesModal";
import InclusionModal from "../modals/InclusionModal";
import InformationModal from "../modals/InformationModal";
import config from "../lib/config"
import "swiper/css";
import "swiper/css/navigation";
import { FaCheck } from "react-icons/fa";


import banner from "../assets/images/activitydetail/banner.png";

import bed from "../assets/images/bed.svg";
import map from "../assets/images/map.png";
import search from "../assets/images/search.png";

import calendaractivity from "../assets/images/calendar_activity.png";
import travelleractivity from "../assets/images/traveller_activity.png";
import AddReviewsModal from "../modals/AddReviewsModal";
import NodataFound from "../components/NodataFound";
import { AverageRatings, DateTimeForm, Name_showing, Ratingsquoetes } from "../lib/common";
import { GetUserRatingsandreviews } from "../Axios/activity.axios";
import ReviewPhotoModal from "../modals/Reviewphotomodel";
import * as Activity from "../Axios/activity.axios"



export default function ActivityDetails() {
  const [startDate, setStartDate] = useState(new Date());
  const selectRule = useRef(null);
  const selectReviews = useRef(null);
  const selectOverview = useRef(null);
  var init = {
    All: { loader: true, page: 1, list: [] },
  };
  const [ReviewsandRatings, SetReviewsandRatings] = useState(init);
  const [photoShow, setPhotoShow] = useState(false);
  const [Reviewstars,SetReviewstars]=useState(0)
  const [AllReviews,SetAllReviews]=useState({images:[],rationgscount:0})
  const [Tabname, SetTabname] = useState('All');
  const [loadlimit, setloadlimit] = useState(10);
  const [active, setActive] = useState(1);

  
var reviews = {
    Location: 0,
    Room: 0,
    Food: 0,
    Amenities: 0,
    Hospitality: 0,
    Cleanliness: 0,
  };
  const [Overallreviews, Setoverallreviews] = useState(reviews);

  useEffect(()=>{
    if(Overallreviews){
      Overallratings()
    }
  },[Overallreviews])



  const handlePhotoClose = () => setPhotoShow(false);
  const handlePhotoShow = () => setPhotoShow(true);

  const [amenitiesShow, setAmenitiesShow] = useState(false);
  const handleAmenitiesClose = () => setAmenitiesShow(false);
  const handleAmenitiesShow = () => setAmenitiesShow(true);

  const [inclusionShow, setInclusionShow] = useState(false);
  const handleInclusionClose = () => setInclusionShow(false);
  const handleInclusionShow = () => setInclusionShow(true);

  const [informationShow, setInformationShow] = useState(false);
  const handleInformationClose = () => setInformationShow(false);
  const handleInformationShow = () => setInformationShow(true);
  const [addReview, setAddReview] = useState(false);
  const [ReviewphotoShow, setReviewPhotoShow] = useState(false);


  const handleReviewPhotoShow = () => setReviewPhotoShow(true);
  const handleReviewPhotoClose = () => setReviewPhotoShow(false);


  const handleAddReviewShow = () => {
    setAddReview(true);
  };
  const handleAddReviewClose = () => {
    setAddReview(false)
  }



  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };
  const GetUserReviews = async (page, tab) => {
    let SendData = {
      Activityid: '1',
      TabName: tab,
      limit: 10,
    };
    let { success, data } = await GetUserRatingsandreviews(SendData);
    if (data?.length > 0) {
      SetReviewsandRatings({
        ...ReviewsandRatings,
        ...{
          [tab]: {
            list: [
              ...(ReviewsandRatings[tab]?.list ?? []),
              ...data[0]?.Userreviews,
            ],
            loader: data[0]?.Userreviews?.length == 0 ? false : true,
            page: ReviewsandRatings[tab]?.page,
            // filter: Filter,
            // Collectiontype:type
          },
        },
      });
      Setoverallreviews(data[0]);
    }
  };

  useEffect(() => {
    ReviewsandRatings[Tabname] = { page: 1, list: [], loader: false };
    SetReviewsandRatings(ReviewsandRatings);
    GetUserReviews(1, Tabname);
  

    },[Tabname])

useEffect(()=>{

  GetOverallreviews()

},[])


    const Overallratings = () => {
      let arr = [];
      Object.values(Overallreviews).map((val) => {
        if (typeof val == "number") {
          arr.push(val);
        }
      });
      let averagestars = AverageRatings(arr);
      SetReviewstars(averagestars);
    }

    const GetOverallreviews = async () => {
      let { success, data } = await Activity.GetOverallreviews({
        Activityid: '1',
      });
      let Arr = [];
      if (data) {
        data.map((val) => {
          val?.Images?.map((itm) => {
            Arr.push(itm);
          });
        });
        SetAllReviews({
          ...AllReviews,
          ...{ ["images"]: Arr, ["rationgscount"]: data?.length },
        });
      }
    };

  const Rooms = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
  ];
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };
  const GuestReview = [
    {
      review: "GOOD",
      rate: "5.0",
      desc: "Excellent experience. Food, hospitality, ambience all worth. Keep it up. Are planning to visit again shortly.",
      img1: require("../assets/images/roomdetail/review/img1.png"),
      img2: require("../assets/images/roomdetail/review/img2.png"),
      img3: require("../assets/images/roomdetail/review/img3.png"),
    },
    {
      review: "Excellent Stay",
      rate: "5.0",
      desc: "Excellent experience. Food, hospitality, ambience all worth. Keep it up. Are planning to visit again shortly.",
      img1: require("../assets/images/roomdetail/review/img1.png"),
      img2: require("../assets/images/roomdetail/review/img2.png"),
      img3: require("../assets/images/roomdetail/review/img3.png"),
    },
    {
      review: "Was a cozy stay",
      rate: "5.0",
      desc: "Excellent experience. Food, hospitality, ambience all worth. Keep it up. Are planning to visit again shortly.",
      img1: require("../assets/images/roomdetail/review/img1.png"),
      img2: require("../assets/images/roomdetail/review/img2.png"),
      img3: require("../assets/images/roomdetail/review/img3.png"),
    },
    {
      review: "Average Stay",
      rate: "5.0",
      desc: "Excellent experience. Food, hospitality, ambience all worth. Keep it up. Are planning to visit again shortly.",
      img1: require("../assets/images/roomdetail/review/img1.png"),
      img2: require("../assets/images/roomdetail/review/img2.png"),
      img3: require("../assets/images/roomdetail/review/img3.png"),
    },
    {
      review: "Was a cozy stay",
      rate: "5.0",
      desc: "Excellent experience. Food, hospitality, ambience all worth. Keep it up. Are planning to visit again shortly.",
      img1: require("../assets/images/roomdetail/review/img1.png"),
      img2: require("../assets/images/roomdetail/review/img2.png"),
      img3: require("../assets/images/roomdetail/review/img3.png"),
    },
  ];
  const SimilarProp = [
    {
      name: "Heritage Madurai",
      amount: "₹ 6,220",
      active: true,
    },
    {
      name: "Fortune Pandiyan Hotel, Madurai-Member ITC Hotel Group",
      amount: "₹ 6,479",
      active: false,
    },
    {
      name: "Courtyard by Marriott Madurai",
      amount: "₹ 8,699",
      active: false,
    },
    {
      name: "Hotel Weshtern Park",
      amount: "₹ 3,438",
      active: false,
    },
    {
      name: "Hotel Weshtern Park",
      amount: "₹ 3,438",
      active: false,
    },
  ];
  const SimilarPropsettings = {
    // className: "center",
    centerMode: false,
    infinite: true,
    // centerPadding: "60px",
    margin: "10px",
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const Amenties = {
    className: "center",
    centerMode: true,
    infinite: true,
    // centerPadding: "60px",
    margin: "10px",
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Header />
      <div className="cmn_pdt">
      <div className="search-page particular-room-details">
        <div className="search-header">
          <div className="container custom-container-search">
            <div className="filter-search">
              <div className="city sec1 cmn-sec">
                <Dropdown className="area-city">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="cmn-heading"
                  >
                    CITY, AREAddd OR PROPERTY
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="locaPick-drop">
                    <div className="cityArea-Property">
                      <div className="search mb-2">
                        <img src={search} className="img-fluid" />
                        <input
                          type="text"
                          className="searchInp"
                          placeholder="Search"
                        />
                      </div>
                      <p className="mb-2 f-15 fw-600">Destinations</p>
                      <div className="loc">
                        <div className="loc-details mb-2">
                          <img
                            src={require("../assets/images/map.png")}
                            className="loc-logo img-fluid"
                          />
                          <span className="f-13 loc-txt gray fw-500">
                            Tirunelveli,Tamilnadu,India.
                          </span>
                        </div>
                        <div className="loc-details mb-2">
                          <img src={map} className="loc-logo img-fluid" />
                          <span className="f-13 loc-txt gray fw-500">
                            Madurai,Tamilnadu,India.
                          </span>
                        </div>
                        <div className="loc-details mb-2">
                          <img
                            src={require("../assets/images/map.png")}
                            className="loc-logo img-fluid"
                          />
                          <span className="f-13 loc-txt gray fw-500">
                            Chennai,Tamilnadu,India.
                          </span>
                        </div>
                      </div>
                      <div className="properties mt-4">
                        <p className="mb-2 f-15 fw-600">Properties</p>
                        <div>
                          <div className="prop-details mb-2">
                            <div className="d-flex align-items-center gap-2">
                              <img src={bed} className="loc-logo img-fluid" />

                              <div className="">
                                <p className="mb-0 f-13 fw-500 d-flex align-items-center gap-1">
                                  <span className="purple fw-700">AMD</span>{" "}
                                  Hotel
                                  <span className="ratings">
                                    <FaStar className="star-filled" />
                                    <FaStar className="star-filled" />
                                    <FaStar className="star-filled" />
                                  </span>
                                </p>
                                <p className="prop-loc f-13 fw-500 mb-0">
                                  Filderstadt, DE
                                </p>
                              </div>
                            </div>
                            <div className="hotel fw-600 f-12 gray">Hotel</div>
                          </div>
                          <div className="prop-details">
                            <div className="d-flex align-items-center gap-2">
                              <img src={bed} className="loc-logo img-fluid" />

                              <div className="">
                                <p className="mb-0 f-13 fw-500 d-flex align-items-center gap-1">
                                  <span className="purple fw-700">AMD</span>{" "}
                                  Hotel
                                  <span className="ratings">
                                    <FaStar className="star-filled" />
                                    <FaStar className="star-filled" />
                                    <FaStar className="star-filled" />
                                  </span>
                                </p>
                                <p className="prop-loc f-13 fw-500 mb-0">
                                  Filderstadt, DE
                                </p>
                              </div>
                            </div>
                            <div className="hotel fw-600 f-12 gray">Hotel</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <p className="mb-0 f-14 fw-500">Dubai</p>
              </div>
              <div className="checkin sec2 cmn-sec">
                <Dropdown className="check-inout-dropp">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="cmn-heading"
                  >
                    CHECK-IN
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="check-inout-drop">
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      open={true}
                    />
                  </Dropdown.Menu>
                </Dropdown>
                <p className="mb-0 f-14 fw-500">Sat, 23 Mar 2024</p>
              </div>
              <div className="checkout sec3 cmn-sec">
                <Dropdown className="check-inout-drop">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="cmn-heading"
                  >
                    CHECK-OUT
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="check-inout-drop">
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      open={true}
                    />
                  </Dropdown.Menu>
                </Dropdown>
                <p className="mb-0 f-14 fw-500">Tue, 23 Apr 2024</p>
              </div>
              <div className="rooms-guest sec4 cmn-sec">
                <Dropdown className="">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="cmn-heading"
                  >
                    TRAVELLERS
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="rooms-guest-menu">
                    <div>
                      <div className="room-no">
                        <span className="f-15 fw-600">Travellers</span>
                        {/* <span className="close-icon">
                          <IoMdCloseCircleOutline />
                        </span> */}
                      </div>
                      <div className="customer-details">
                        <div>
                          <span className="f-13 fw-500">Adults</span>
                          <div className="inc-dec mt-1">
                            <Button className="decrement-btn">
                              <FaMinus />
                            </Button>
                            <span className="value"> 2 Adults</span>
                            <Button className="increment-btn">
                              <FaPlus />
                            </Button>
                          </div>
                        </div>
                        <div>
                          <span className="f-13 fw-500">
                            Children<span className="age"> (Age 0-17)</span>
                          </span>
                          <div className="inc-dec mt-1">
                            <Button className="decrement-btn">
                              <FaMinus />
                            </Button>
                            <span className="value"> 0 Child</span>
                            <Button className="increment-btn">
                              <FaPlus />
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="childrens-age mt-2">
                        <span className="f-13 fw-500">Age of children</span>
                        <div className="row">
                          <div className="col-6">
                            <Dropdown className="">
                              <Dropdown.Toggle>Age Required</Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item>1</Dropdown.Item>
                                <Dropdown.Item>2</Dropdown.Item>
                                <Dropdown.Item>3</Dropdown.Item>
                                <Dropdown.Item>4</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="mt-3 f-14 fw-600 d-flex align-items-center justify-content-end">
                      <div className="add-room">
                        <span className="">ADD ROOM</span>
                        <FaCirclePlus className="plus-icon ms-1" />
                      </div>
                    </div> */}
                  </Dropdown.Menu>
                </Dropdown>
                <p className="mb-0 f-14 fw-500">40 Adults</p>
              </div>
              <div className="search sec5">
                <Button className="search-btn">SEARCH</Button>
              </div>
            </div>
          </div>
        </div>
        <div className="sort-sec">
          <div className="container custom-container-search">
            <div className="select-sort-fields select-sort-fields-sm">
              <div
                className="cmn-tabs active"
                onClick={() => scrollToSection(selectOverview)}
              >
                <span className="txt">OVERVIEW</span>
              </div>
             
             
            
              <div
                className="cmn-tabs"
                onClick={() => scrollToSection(selectRule)}
              >
                <span className=" txt">ACTIVITY INFORMATION</span>
              </div>
              <div
                className="cmn-tabs"
                onClick={() => scrollToSection(selectReviews)}
              >
                <span className=" txt">USER REVIEWS</span>
              </div>
          
            </div>
          </div>
        </div>
        <div className="room-name-details" ref={selectOverview}>
          <div className="container custom-container-search">
            <p className=" mb-0 f-15 nav-home">
              <Link to="/">Home</Link>
              <span className="d-flex d-sm-inline align-items-center">
                <IoIosArrowForward className="mx-2" />
              </span>
              <Link to="/">Activity In Dubai</Link>
              <span className="d-flex d-sm-inline align-items-center">
                <IoIosArrowForward className="mx-2" />
              </span>
              <Link to="/">Resort In Dubai</Link>
              <span className="d-flex d-sm-inline align-items-center">
                <IoIosArrowForward className="mx-2" />
              </span>
              <h5 className="mb-0 fw-700 f-15 d-inline text-nowrap">
                Heritage Dubai
              </h5>
            </p>
          </div>
        </div>
        <div className="container-fluid p-0">
          <div className="rd__bannerImgHolder ad__bannerImgHolder" id="bannerBlur">
            <div className="container position-relative">
              <img src={banner} className="img-fluid room-banner" alt="" />
              <Link className="banner-gal view_pho_lin" onClick={handlePhotoShow}>
                <img
                  src={require("../assets/images/roomdetail/gallery/camera-white.png")}
                  className="img-fluid"
                />
                <span className="f-12 view-all fw-600">VIEW ALL PHOTOS</span>
              </Link>
            </div>
            <div className="cus-btm-head">
              <div className="container h-100 d-flex align-items-center ">
                <div className="rating_with_txt">
                  <h4 className="mb-0 title-txt">3-Hour Luxury Yacht Cruise from Dubai Marina</h4>
                  <div className="stars">
                    <TiStar className="filled-star" />
                    <TiStar className="filled-star" />
                    <TiStar className="filled-star" />
                    <TiStar className="filled-star" />
                    <TiStar className="non-filled-star" />
                  </div>
                </div>
              </div>
            </div>
                     </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-lg-8">
              <div className="best-of-luxury">
                <div className="rating-map mt-3 mb-4">
                  <div className="row">
                    <div className="mb-3 mb-sm-0 col-12 col-sm-6 d-flex align-items-center gap-3">
                      <span className="rat">{2}</span>
                      <div className="d-flex flex-column">
                        <span className="rat-txt mb-0 fw-800 f-15 purple">
                        {Ratingsquoetes(2)}
                        </span>
                        <span className="total-rev f-13 fw-700 purple">
                        ({AllReviews?.rationgscount} Ratings)
                        </span>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <span className="d-flex align-items-center gap-3">
                        <div className="position-relative">
                          <img
                            src={require("../assets/images/roomdetail/map-bg-new.png")}
                            className="img-fluid map-bg"
                          />
                          <img
                            src={require("../assets/images/roomdetail/map-icon.png")}
                            className="img-fluid map-icon"
                          />
                        </div>
                        <div className="d-flex flex-column">
                          <span className="mb-0 fw-800 f-15">{"City"}</span>
                          <span className="view-map f-13 fw-700">
                            View On Map
                          </span>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="">
                  <h5 className="heading-txt fw-800 gray">
                    Discover the Best of Luxury
                  </h5>
                  <div className="row mt-3">
                    <div className="mb-3 col-12 col-sm-6 col-md-6 col-xl-4">
                      <div className="cmn-discvr discvr-bst-lux1">
                        <div className="cnt with-blur-backdrop">
                          <img
                            src={require("../assets/images/roomdetail/bst-of-lux1.png")}
                            className="img-fluid"
                          />
                          <p className="txt mt-2 mb-0 text-center">
                            Indulgent Spa Therapies
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3 col-12 col-sm-6 col-md-6 col-xl-4">
                      <div className="cmn-discvr discvr-bst-lux2">
                        <div className="cnt with-blur-backdrop">
                          <img
                            src={require("../assets/images/roomdetail/bst-of-lux2.png")}
                            className="img-fluid"
                          />
                          <p className="txt mt-2 mb-0 text-center">
                            Indulgent Dining
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3 col-12 col-sm-6 col-md-6 col-xl-4">
                      <div className="cmn-discvr discvr-bst-lux3">
                        <div className="cnt with-blur-backdrop">
                          <img
                            src={require("../assets/images/roomdetail/bst-of-lux3.png")}
                            className="img-fluid"
                          />
                          <p className="txt mt-2 mb-0 text-center">
                            Olympic Sized Outdoor Pool
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-5 col-lg-4 col-lg-4">
              <div className="bnrRoom-ryt-card">
                <img
                  src={require("../assets/images/activitydetail/activity_detail_img.png")}
                  className="img-fluid room-img"
                />
                <div className="mt-3">
                  <div className="name mb-3">
                    <span className="name-txt">Select Date and Travellers</span>
                  </div>
                    <div className="calendar_date mb-3">
                        <img src={calendaractivity} className="img-fluid"/>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    //   open={false}
                    />
                    </div>

                    <div className="calendar_date">
                        <img src={travelleractivity} className="img-fluid"/>
                        <input className="form-control" type="number" placeholder="Number of Peoples" />
                    </div>
              
                 
                </div>
                <div className="book-btn mt-3">
                  <Button className="cmn-btn">
                    <Link to="/activityReviewBooking">BOOK THIS NOW</Link>
                  </Button>
                </div>

                <div className="row mt-3 pmt-types-row">
                    <div className="col-12 col-md-6">
                    <p className="mb-1 f-12 fw-400 pmt-types d-flex">
                    <FaCheck size={11} className="green_check" />
                    <span className="ps-1">Pickup non-offered</span>
                  </p>
                    </div>

                    <div className="col-12 col-md-6">
                    <p className="mb-1 f-12 fw-400 pmt-types">
                    <FaCheck size={11} className="green_check" />
                    <span className="ps-1">Mobile ticket</span>
                  </p>
                    </div>

                    <div className="col-12 col-md-6">
                    <p className="mb-1 f-12 fw-400 pmt-types">
                    <FaCheck size={11} className="green_check" />
                    <span className="ps-1">Free Cancellation</span>
                  </p>
                    </div>

                    <div className="col-12 col-md-6">
                    <p className="mb-1 f-12 fw-400 pmt-types">
                    <FaCheck size={11} className="green_check" />
                    <span className="ps-1">3 Hours duration</span>
                  </p>
                    </div>
                </div>
              
              </div>
            </div>
          </div>
        </div>
        <div className="container">
       
       
        
        
     
        
      
          <div className="propty-rls mb-4" ref={selectRule}>
            <h5 className="fw-800 text-uppercase">Activity Information</h5>
            <hr />
            <div className="row">
              <div className="col-12 col-sm-10 col-md-9 col-lg-8">
              <p className="fw-700">Overview</p>
            <p className="info_desc_blk">Journey on Dubai’s most exotic Luxury Yacht tour, which can fulfill your dreams completely on a 
beautiful and majestic yacht. Charter her for a 3 hours and you will be able to enjoy the most attractive 
views and landmarks of Dubai, with a professional and friendly hospitality team who can gift you some 
unforgettable moments in Dubai's water.</p>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12 col-sm-10 col-md-9 col-lg-8">
              <p className="fw-700">What you can expect</p>
            <p className="info_desc_blk">This 3-hour excursion will start with a welcome drink (soft drinks). The yacht will start sailing out from
Dubai marina and pass through such famous icons such as Plam Jumeriah, Atlantis, and the Burj al
Arab. After anchoring in a lagoon for swimming, you can also enjoy fine dining, partying with chill-out
music, and then ending your luxury trip back in the Dubai Marina.</p>
              </div>
            </div>
            
            <hr />
            <div className="row">
              <div className="col-12 col-sm-10 col-md-9 col-lg-8">
              <p className="fw-700">Additional Info</p>
              <p className="mb-1 f-12 fw-400 pmt-types d-flex info_desc_blk">
                    <FaCheck size={11} className="green_check green_check_light" />
                    <span className="ps-1">Wheelchair accessible</span>
                  </p>

                  <p className="mb-1 f-12 fw-400 pmt-types d-flex info_desc_blk">
                    <FaCheck size={11} className="green_check green_check_light" />
                    <span className="ps-1">Infants and small children can ride in a pram or stroller</span>
                  </p>
                  <p className="mb-1 f-12 fw-400 pmt-types d-flex info_desc_blk">
                    <FaCheck size={11} className="green_check green_check_light" />
                    <span className="ps-1">Public transportation options are available nearby</span>
                  </p>
                  <p className="mb-1 f-12 fw-400 pmt-types d-flex info_desc_blk">
                    <FaCheck size={11} className="green_check green_check_light" />
                    <span className="ps-1">Infants are required to sit on an adult’s lap</span>
                  </p>
                  <p className="mb-1 f-12 fw-400 pmt-types d-flex info_desc_blk">
                    <FaCheck size={11} className="green_check green_check_light" />
                    <span className="ps-1">Specialized infant seats are available</span>
                  </p>
                  <p className="mb-1 f-12 fw-400 pmt-types d-flex info_desc_blk">
                    <FaCheck size={11} className="green_check green_check_light" />
                    <span className="ps-1">Transportation options are wheelchair accessible</span>
                  </p>
                  <p className="mb-1 f-12 fw-400 pmt-types d-flex info_desc_blk">
                    <FaCheck size={11} className="green_check green_check_light" />
                    <span className="ps-1">All areas and surfaces are wheelchair accessible</span>
                  </p>
                  <p className="mb-1 f-12 fw-400 pmt-types d-flex info_desc_blk">
                    <FaCheck size={11} className="green_check green_check_light" />
                    <span className="ps-1">Travelers should have at least a moderate level of physical fitness</span>
                  </p>
                  <p className="mb-1 f-12 fw-400 pmt-types d-flex info_desc_blk">
                    <FaCheck size={11} className="green_check green_check_light" />
                    <span className="ps-1">subject to availability</span>
                  </p>
                  <p className="mb-1 f-12 fw-400 pmt-types d-flex info_desc_blk">
                    <FaCheck size={11} className="green_check green_check_light" />
                    <span className="ps-1">A maximum of 23 people per booking</span>
                  </p>
                  <p className="mb-1 f-12 fw-400 pmt-types d-flex info_desc_blk">
                    <FaCheck size={11} className="green_check green_check_light" />
                    <span className="ps-1">Contact the local service provider to reconfirm your booking and departure time; contact details 
are on your booking voucher</span>
                  </p>
                  <p className="mb-1 f-12 fw-400 pmt-types d-flex info_desc_blk">
                    <FaCheck size={11} className="green_check green_check_light" />
                    <span className="ps-1">If you wish to use the fishing equipment, make a note in the 'Special Requirements' field when 
you book</span>
                  </p>
                  <p className="mb-1 f-12 fw-400 pmt-types d-flex info_desc_blk">
                    <FaCheck size={11} className="green_check green_check_light" />
                    <span className="ps-1">The experience is subject to cancellation if weather conditions are poor</span>
                  </p>

              <p className="fw-700 mt-4">Departure & Return</p>
              <p className="green_light_heading">Depature Point</p>
              <p className="info_desc_blk">Near Reem Al bhawadi restaurant</p>

              <p className="green_light_heading">Return Details</p>
              <p className="info_desc_blk">Near Reem Al bhawadi restaurant</p>

              </div>
            </div>
            <hr />
            

            <div className="row">
              <div className="col-12 col-sm-10 col-md-9 col-lg-8">
              <p className="fw-700">What's included</p>
              <p className="mb-1 f-12 fw-400 pmt-types d-flex info_desc_blk">
                    <FaCheck size={11} className="green_check green_check_light" />
                    <span className="ps-1">Unlimited Refreshments(Tea, coffee ,soft drink& water ,No alcohol)</span>
                  </p>

                  <p className="mb-1 f-12 fw-400 pmt-types d-flex info_desc_blk">
                    <FaCheck size={11} className="green_check green_check_light" />
                    <span className="ps-1">Bbq dinner with variety of Veg & Non veg dishes</span>
                  </p>
                  <p className="mb-1 f-12 fw-400 pmt-types d-flex info_desc_blk">
                    <FaCheck size={11} className="green_check green_check_light" />
                    <span className="ps-1">Sunset & Arabic dress Photography opportunity</span>
                  </p>
                  <p className="mb-1 f-12 fw-400 pmt-types d-flex info_desc_blk">
                    <FaCheck size={11} className="green_check green_check_light" />
                    <span className="ps-1">Pick-up &Drop-off -4*4 vehicle- transfer option</span>
                  </p>
                  <p className="mb-1 f-12 fw-400 pmt-types d-flex info_desc_blk">
                    <FaCheck size={11} className="green_check green_check_light" />
                    <span className="ps-1">Dune bashing</span>
                  </p>
                  <p className="mb-1 f-12 fw-400 pmt-types d-flex info_desc_blk">
                    <FaCheck size={11} className="green_check green_check_light" />
                    <span className="ps-1">Sand Boarding</span>
                  </p>
                  <p className="mb-1 f-12 fw-400 pmt-types d-flex info_desc_blk">
                    <FaCheck size={11} className="green_check green_check_light" />
                    <span className="ps-1">Separate washroom facility for Male/Women</span>
                  </p>
                  <p className="mb-1 f-12 fw-400 pmt-types d-flex info_desc_blk">
                    <FaCheck size={11} className="green_check green_check_light" />
                    <span className="ps-1">Fire show</span>
                  </p>
                  <p className="mb-1 f-12 fw-400 pmt-types d-flex info_desc_blk">
                    <FaCheck size={11} className="green_check green_check_light" />
                    <span className="ps-1">Tanoura & Belly dance Performance</span>
                  </p>
                </div>
           </div>

           <hr />
           <div className="row">
              <div className="col-12 col-sm-10 col-md-9 col-lg-8">
              <p className="fw-700">What's Excluded</p>
              <p className="mb-1 f-12 fw-400 pmt-types d-flex info_desc_blk">
                    <FaCheck size={11} className="green_check green_check_light" />
                    <span className="ps-1">Food and drinks</span>
                  </p>

                  <p className="mb-1 f-12 fw-400 pmt-types d-flex info_desc_blk">
                    <FaCheck size={11} className="green_check green_check_light" />
                    <span className="ps-1">Pickup and drop-off</span>
                  </p>
                
                </div>
           </div>
           <hr />
           <div className="row">
              <div className="col-12 col-sm-10 col-md-9 col-lg-8">
              <p className="fw-700">Cancellation Policy</p>
              <p className="info_desc_blk">For a full refund, cancel at least 24 hours in advance of the start date of the experience.</p>
            </div>
            </div>
          
          </div>
          <div className="photos-gal mb-4">
            <h5 className="fw-800">Photos by Guests</h5>
            <div className="row mt-3">
              <div className="col-12 col-lg-6 col-xl-6 mb-2">
                <div className="lft-big-img">
                  <img
                    src={
                      AllReviews?.images.length > 0
                        ? `${config.IMG_URL}/activity/reviewimages/${AllReviews?.images?.[0]}`
                        : require("../assets/images/roomdetail/gallery/ryt-sml2.png")
                    }
                    className="img-fluid big-img cmn-brd"
                  />
                  <Button className="gst-pts" onClick={handleReviewPhotoShow}>
                    <img
                      //  src={AllReviews?.images.length>=1?`${config.IMG_URL}/reviewimages/${AllReviews?.images?.[1]}`:require("../assets/images/roomdetail/gallery/ryt-sml2.png")}
                      className="img-fluid"
                    />
                    <span className="ms-2 fw-700 purple">
                      {AllReviews?.images?.length} GUEST PHOTOS
                    </span>
                  </Button>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-xl-6">
                <div className="row">
                  <div className="col-xl-12 mb-2 px-auto ps-lg-1">
                    <div className="ryt-big-img">
                      <img
                        src={
                          AllReviews?.images.length > 1
                            ? `${config.IMG_URL}/activity/reviewimages/${AllReviews?.images?.[1]}`
                            : require("../assets/images/roomdetail/gallery/ryt-sml2.png")
                        }
                        className="img-fluid cmn-brd"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-xl-6 px-auto pe-sm-1 mb-2 ps-lg-1">
                    <div className="ryt-sml-img cmn-brd">
                      <img
                        src={
                          AllReviews?.images.length > 2
                            ? `${config.IMG_URL}/activity/reviewimages/${AllReviews?.images?.[2]}`
                            : require("../assets/images/roomdetail/gallery/ryt-sml2.png")
                        }
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-xl-6 ps-sm-1 px-auto">
                    <div className="ryt-sml-img cmn-brd">
                      <img
                        src={
                          AllReviews?.images.length > 3
                            ? `${config.IMG_URL}/activity/reviewimages/${AllReviews?.images?.[3]}`
                            : require("../assets/images/roomdetail/gallery/ryt-sml2.png")
                        }
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* )
            } */}
          </div>
          <div className="usr-rat-rev mb-4" ref={selectReviews}>
            <h5 className="fw-800 gray">User Ratings & Reviews</h5>
            <p className="mb-0 gray fw-500 f-13">
              Important information that you need to know before you book!
            </p>
            <Button
                className="cmn-btn addReviewBtn"
                onClick={handleAddReviewShow}
              >
                Add Review
              </Button>
              <div className="cus-srch mt-3">
              <div className={`cmn-tbs ${Tabname=='All'?"active":""}`} >
                <span className="txt" onClick={()=>SetTabname('All')}>ALL</span>
              </div>
              <div className={`cmn-tbs ${Tabname=='Couple'?"active":""}`}>
                <span className="txt"  onClick={()=>SetTabname('Couple')} >COUPLE</span>
              </div>
              <div className={`cmn-tbs ${Tabname=='Family'?"active":""}`}>
                <span className="txt"  onClick={()=>SetTabname('Family')}>FAMILY</span>
              </div>
              <div className={`cmn-tbs ${Tabname=='BUSINESS'?"active":""}`}>
                <span className="txt" onClick={()=>SetTabname('Buisness')}>BUSINESS</span>
              </div>
              <div className={`cmn-tbs ${Tabname=='GROUP'?"active":""}`}>
                <span className="txt" onClick={()=>SetTabname('Group')}>GROUP</span>
              </div>
              <div className={`cmn-tbs ${Tabname=='SOLO'?"active":""}`}>
                <span className="txt" onClick={()=>SetTabname('Solo')}>SOLO</span>
              </div>
            </div>
            <div className="rtng-all mt-3">
              <div className="row">
                <div className="col-lg-4 mb-3">
                  <div className="lft-sec">
                    <span className="rt">{Reviewstars}</span>
                    <div className="">
                      <p className="f-15 fw-800 purple mb-0">
                        {Ratingsquoetes(Reviewstars)}
                      </p>
                      <p className="f-13 fw-500 gray mb-0">
                        <span className="fw-800">
                          {ReviewsandRatings[Tabname]?.list?.length > 0
                            ? ReviewsandRatings[Tabname]?.list?.length
                            : 0}
                        </span>{" "}
                        User Reviews
                        {/* <span className="fw-800"> 1604</span> Ratings */}
                      </p>
                    </div>
                  </div>
                </div>
                {ReviewsandRatings[Tabname]?.list?.length > 0 && (
                  <div className="col-lg-8">
                    <div className="rat-pro-full">
                      <div className="rat-progress mb-2">
                        <p className="mb-1 d-flex align-items-center justify-content-between">
                          <span className="fw-600 f-12">Location</span>
                          <span className="fw-600 f-12">
                            {parseInt(Overallreviews?.Location)}
                          </span>
                        </p>
                        <ProgressBar
                          now={parseInt(Overallreviews?.Location) * 20}
                        />
                      </div>
                      <div className="rat-progress mb-2">
                        <p className="mb-1 d-flex align-items-center justify-content-between">
                          <span className="fw-600 f-12">Room</span>
                          <span className="fw-600 f-12">
                            {" "}
                            {parseInt(Overallreviews?.Room)}
                          </span>
                        </p>
                        <ProgressBar
                          now={parseInt(Overallreviews?.Room) * 20}
                        />
                      </div>
                      <div className="rat-progress mb-2">
                        <p className="mb-1 d-flex align-items-center justify-content-between">
                          <span className="fw-600 f-12">Food</span>
                          <span className="fw-600 f-12">
                            {" "}
                            {parseInt(Overallreviews?.Food)}
                          </span>
                        </p>
                        <ProgressBar
                          now={parseInt(Overallreviews?.Food) * 20}
                        />
                      </div>
                      <div className="rat-progress mb-2">
                        <p className="mb-1 d-flex align-items-center justify-content-between">
                          <span className="fw-600 f-12">Amenities</span>
                          <span className="fw-600 f-12">
                            {" "}
                            {parseInt(Overallreviews?.Amenities)}
                          </span>
                        </p>
                        <ProgressBar
                          now={parseInt(Overallreviews?.Amenities) * 20}
                        />
                      </div>
                      <div className="rat-progress mb-2">
                        <p className="mb-1 d-flex align-items-center justify-content-between">
                          <span className="fw-600 f-12">Hospitality</span>
                          <span className="fw-600 f-12">
                            {" "}
                            {parseInt(Overallreviews?.Hospitality)}
                          </span>
                        </p>
                        <ProgressBar
                          now={parseInt(Overallreviews?.Hospitality) * 20}
                        />
                      </div>
                      <div className="rat-progress">
                        <p className="mb-1 d-flex align-items-center justify-content-between">
                          <span className="fw-600 f-12">Cleanliness</span>
                          <span className="fw-600 f-12">
                            {" "}
                            {parseInt(Overallreviews?.Cleanliness)}
                          </span>
                        </p>
                        <ProgressBar
                          now={parseInt(Overallreviews?.Cleanliness) * 20}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <hr className="my-2" />
              {/* <p className="mb-0 f-12 gray fw-500 mt-3">
                Last 10 Customer Ratings (Latest Rating First)
              </p>
              <div className="cus-rating-box mt-2">
                <span className="cnt">5</span>
                <span className="cnt">5</span>
                <span className="cnt">5</span>
                <span className="cnt">3</span>
                <span className="cnt">5</span>
                <span className="cnt">3</span>
                <span className="cnt">5</span>
                <span className="cnt">4</span>
                <span className="cnt">5</span>
                <span className="cnt">5</span>
              </div> */}
              {/* <div className="traval-imgpression mt-3">
                <div className="header-sec d-flex align-items-center gap-2">
                  <img
                    src={require("../assets/images/roomdetail/robo.png")}
                    className="img-fluid robo"
                  />
                  <div className="">
                    <h6 className="mb-0 fw-800 ">Traveller Impressions</h6>
                    <p className="mb-0 fw-600 gray f-13">
                      Powered by Generative AI using recent user reviews
                    </p>
                  </div>
                </div>
                <p className="mt-3 f-13 fw-600 gray">
                  Heritage Madurai is a beautiful property with lush greenery
                  and well-maintained rooms. The staff is courteous and
                  attentive, going above and beyond to ensure a comfortable
                  stay. The food is delectable with a good spread of options.
                  The property offers a serene and peaceful environment, perfect
                  for a relaxing getaway. The private plunge pool villas are a
                  highlight, though some guests have reported issues with
                  cleanliness. Overall, a great option for those looking for a
                  luxurious stay in Madurai.
                </p>
                <div className="">
                  <div className="row">
                    <div className="col-lg-9 mb-2">
                      <div className="filter-by">
                        <span className="f-12 gray fw-800 text-nowrap">
                          Filter By:
                        </span>
                        <div className="fil-list">
                          <p className="txt mb-0">All Reviews</p>
                          <p className="txt mb-0">Food</p>
                          <p className="txt mb-0">Good Ambience</p>
                          <p className="txt mb-0">Delicious Food</p>
                          <p className="txt mb-0">Space in Rooms</p>
                          <p className="txt mb-0">Service Quality</p>
                          <p className="txt mb-0">Impeccable Service</p>
                          <p className="txt mb-0">Good Room Service</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 d-flex align-items-center justify-content-end">
                      <div className="srt-by">
                        <span className="gray text-nowrap fw-800 f-13">
                          Sort By:
                        </span>
                        <Form.Select>
                          <option>Latest First</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="mt-3 mb-0" />
              </div> */}
            </div>
          </div>
          <div className="gust-reviews mt-4 mb-4">
            <h6 className="f-16 fw-800 gray">Guest Reviews</h6>
            {ReviewsandRatings[Tabname]?.list?.slice(loadlimit - 10, loadlimit)
              .length > 0 ? (
              ReviewsandRatings[Tabname]?.list
                ?.slice(loadlimit - 10, loadlimit)
                .map((item, index) => {
                  return (
                    <div className="cus-card mb-3">
                      <p className="mb-0 fw-800 gray">{item.review}</p>
                      <p className="mb-0 fw-500 f-14 gray">
                        <span className="fw-700">Rated</span>
                        <span className="cnt mx-1">
                          {AverageRatings(Object?.values(item?.Ratings))}
                        </span>
                        by{" "}
                        {Name_showing(
                          item?.Users[0]?.FirstName + item?.Users[0]?.LastName
                        )}{" "}
                        {item?.Type}
                        {DateTimeForm({ date: item?.createdAt, words: true })}
                      </p>
                      <p className="mb-0 gray fw-500 f-14 gray">
                        {item.Reviews}
                      </p>
                      <div className="mt-2 mb-3 reviews-pto-list">
                        {item?.Images?.map((val) => (
                          <img
                            src={`${config.IMG_URL}/activity/userreview/${item.Userid}/${val}`}
                            className="img-fluid rvw-img"
                          />
                        ))}
                      </div>
                      {/* <p className="mb-0 do-u-lik d-flex align-items-center fw-600 f-15">
                    <span className="fw-500 gray">
                      Do you find this helpful?
                    </span>
                    <FaRegThumbsUp className="ms-2 thumb" />
                  </p> */}
                    </div>
                  );
                })
            ) : (
              <NodataFound />
            )}
 {ReviewsandRatings[Tabname]?.list.length > 0 && (
              <div className="my-3 review-pagi">
                <span className="cnt-pv-nxt">
                  <MdKeyboardDoubleArrowLeft />
                </span>
                <span
                  className={`cnt-pv-nxt ${active == 1 ? "active" : ""}`}
                  onClick={(e) => {
                    setloadlimit(10);
                    setActive(1);
                  }}
                >
                  1
                </span>
                <span
                  className={`cnt-pv-nxt ${active == 2 ? "active" : ""}`}
                  onClick={(e) => {
                    setloadlimit(20);
                    setActive(2);
                  }}
                >
                  2
                </span>
                <span
                  className={`cnt-pv-nxt ${active == 3 ? "active" : ""}`}
                  onClick={(e) => {
                    setloadlimit(30);
                    setActive(3);
                  }}
                >
                  3
                </span>
                <span
                  className={`cnt-pv-nxt ${active == 4 ? "active" : ""}`}
                  onClick={(e) => {
                    setloadlimit(40);
                    setActive(4);
                  }}
                >
                  4
                </span>
                <span
                  className={`cnt-pv-nxt ${active == 5 ? "active" : ""}`}
                  onClick={(e) => {
                    setloadlimit(50);
                    setActive(5);
                  }}
                >
                  5
                </span>
                <span
                  className={`cnt-pv-nxt ${active == 6 ? "active" : ""}`}
                  onClick={(e) => {
                    setloadlimit(60);
                    setActive(6);
                  }}
                >
                  6
                </span>
                <span className={`cnt-pv-nxt `}>
                  <MdKeyboardDoubleArrowRight />
                </span>
              </div>
            )}
          </div>
         
        </div>
      </div>
      </div>
      <Footer />

      {photoShow &&
 <PhotoModal
 photoShow={photoShow}
 handlePhotoClose={handlePhotoClose}
/>
}


{amenitiesShow &&
 <AmenitiesModal
 amenitiesShow={amenitiesShow}
 handleAmenitiesClose={handleAmenitiesClose}
/>
}

{inclusionShow &&
 <InclusionModal
 inclusionShow={inclusionShow}
 handleInclusionClose={handleInclusionClose}
/>
}


{informationShow &&
 <InformationModal
 informationShow={informationShow}
 handleInformationClose={handleInformationClose}
/>
}

{addReview && (
        <AddReviewsModal
          show={addReview}
          handleClose={handleAddReviewClose}
          Type={'Activities'}

          // Hotelid={HotelData.hotel.id}
        />
      )}
            {ReviewphotoShow && AllReviews && (
        <ReviewPhotoModal
          photoShow={ReviewphotoShow}
          handlePhotoClose={handleReviewPhotoClose}
          images={AllReviews?.images}
          type={'Activities'}
          // Data={""}
        />
      )}

    </>
  );
}
