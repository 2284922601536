import React, { useState } from "react";

function CouponCard(props) {
console.log(props,"props");
let price = props?.data?.price?.split("").slice(2).join("")
console.log("aaaaaaaaaaa",price);
const [checked, setChecked] = useState(0);
 
  return (
    <div className={checked?"coupons_grid active":"coupons_grid"}>
        <div className="d-flex">
            <div className={checked?"checkbox_multi_round active":"checkbox_multi_round"} onClick={()=>{setChecked(true)}}>

            </div>
            <div className="w-100">
                <div className="d-flex align-items-start justify-content-between w-100">
                <h6 className="mb-0 coupon_title">{props.data.title}</h6>
      <h6 className="mb-0 coupon_price">{(props?.data?.price)}</h6>


                </div>
      <h6 className="mb-0 coupon_desc mt-3">{props.data.description}</h6>

            </div>
        </div>
     

      
     
    </div>
  );
}

export default CouponCard;
