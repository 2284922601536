import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Dropdown from "react-bootstrap/Dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/esm/Button";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link } from "react-router-dom";


import Image from "../components/Image";
import { Col, Container, Row, Form, ProgressBar } from "react-bootstrap";
import Select from "react-select";

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import { BiRightArrowAlt } from "react-icons/bi";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";
import { allData } from "../Data/CommonData";

import InclusionModal from "../modals/InclusionModal";
import InformationModal from "../modals/InformationModal";


import { FaCheck, FaPlus, FaInfoCircle, FaArrowRight } from "react-icons/fa";

import CouponCard from "../components/couponCard";

import bookroom from "../assets/images/activitydetail/bookactivity.png"
import successdollar from "../assets/images/book/successdollar.png"
import successtick from "../assets/images/book/successtick.png"
import dangertimer from "../assets/images/book/dangertimer.png"


import trip from "../assets/images/book/trip.png"


export default function ActivityReviewBooking() {
  const [startDate, setStartDate] = useState(new Date());
  const [value, setValue] = useState();
  const [count, setCount] = useState(1);

  const [inclusionShow, setInclusionShow] = useState(false);
  const [informationShow, setInformationShow] = useState(false);

  const handleInclusionClose = () => setInclusionShow(false);
  const handleInclusionShow = () => setInclusionShow(true);

  const handleInformationClose = () => setInformationShow(false);
  const handleInformationShow = () => setInformationShow(true);




  const [coupons, setCoupons] = useState([
    { 
      title:"WELCOMEPT",
      description:'Congratulations! Discount of INR 489 applied for your 1st hotel booking on TripFi. Use Code WELCOME20 to Book With 0 Payment',
      price:"₹ 1200"
    },
    { 
      title:"TRIPFIFEAST",
      description:'Exclusive Offer on Citi Credit & Debit Cards. Get Flat INR 442 off',
      price:"₹ 1442"
    },
    { 
      title:"PTBOBDC",
      description:'Exclusive Offer on Bank of Baroda Debit Cards. Get INR412 Off',
      price:"₹ 812"
    },
    { 
      title:"PTBESTBUY",
      description:'Get INR 286 Off',
      price:"₹ 286"
    },
    { 
      title:"PTBIG5G",
      description:'EMI Offer on Payment using Credit Cards',
      price:"₹ 20"
    },

  ])

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis leo nisi, blandit a euismod ut, euismod ut eros. Duis quam urna, luctus id facilisis nec, condimentum nec nisi. 
    </Tooltip>
  );

  const renderTooltip1 = (props) => (
    <Tooltip id="button-tooltip1" {...props}>
     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis leo nisi, blandit a euismod ut, euismod ut eros. Duis quam urna, luctus id facilisis nec, condimentum nec nisi. 
    </Tooltip>
  );

  const options = [
    { value: 'mr', label: 'Mr' },
    { value: 'miss', label: 'Miss' },
    { value: 'mrs', label: 'Mrs' },
   

  ];

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      return {
        ...styles,
        backgroundColor: isHovered
          ? "#653883"
          : isSelected
          ? "#653883"
          : isFocused
          ? "#653883"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "38px",
      padding: "0px 10px",
      border:"none",
      backgroundColor: "#fff",
      color: "#000",
      borderColor:"#BEBEBE",
      borderRadius: "4px",
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: "4px",
      backgroundColor: "transparent",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "red",
      padding: "0px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#ffffff",
    }),
    menuList: (base) => ({
      ...base,

      padding: 0,
      width:"100%",

      borderRadius: 5,
      background: "transparent",
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#000",
      };
    },
  };

  const [selectedOption1,setSelectedOption1] = useState("Mr")


  const handleChange1 = (selectedOption) => {
    setSelectedOption1(selectedOption);
  };




  return (
    <>
      <Header />
      <div className="cmn_pdt">
      <div className="search-page particular-room-details">
        <div className="search-header">
          <div className="container">
           <p className="filter_text mb-0">Review your Booking </p>
          </div>
        </div>
       
       
     

        <Container>
          <Row className="justify-content-center mt-5">
            <Col lg={8}>

            <div className="card card_book mb-3">
                <Row className="p-3">
                    <Col sm={9}>
                    <h2 className="rd__blackTitle m-0 text-left">
                    3-Hour Luxury Yacht Cruise from Dubai Marina    
              </h2>
              <p className="book_text_light mt-3">
              11, Melakkal Rd, Near Fenner & Passport Office, Kochadai, Madurai, Tamil Nadu 625016, Madurai, India
              </p>
                    </Col>
                    <Col sm={3} className="text-start text-sm-end">
                        <img src={bookroom} className="img-fluid img_book" />
                        </Col>
                </Row>
           <div className="bg_grey_book">
            <Row className="p-3 align-items-center">
                <Col md={3}>
                    <p className="book_text_small mb-0">CHECK IN</p>
                    <div className="d-flex align-items-center justify-content-between">
                    <p className="book_text_small mb-0">Fri<span className="px-1">29 Mar</span>2024</p>

                    </div>
                    <p className="book_text_small_bold mb-0">12.30 PM - 03:30 PM</p>

                </Col>
              
                <Col md={3}>
                    <p className="book_text_small mb-0">Travellers</p>
                    <div className="d-flex align-items-center justify-content-between">
                    <p className="book_text_small mb-0"><span className="px-1">5 Members</span></p>

                    </div>

                </Col>

                <Col md={3}>
                    <p className="book_text_small mb-0">Duration</p>
                    <div className="d-flex align-items-center justify-content-between">
                    <p className="book_text_small mb-0"><span className="px-1">3 Hours</span></p>

                    </div>

                </Col>
                <Col md={3}>
                    <p className="book_text_small mb-0">Refundability</p>
                    <div className="d-flex align-items-center justify-content-between">
                    <p className="book_text_small mb-0"><span className="px-1">Refundable</span></p>

                    </div>

                </Col>
            </Row>
            </div>

            <div className="px-3">
              <div className="d-flex align-items-center justify-content-between flex_title_block">
                <div className="d-flex flex-column">
                <p className="black_bold_text mt-3 mb-1">Standard Double</p>
            <p className="book_text_light">2 Adults</p>
            </div>
            <a className="purple_a_tag" onClick={handleInclusionShow}>See Inclusions</a>
              </div>

          
            </div>

                <div className="rd__leftBoxSingleFlex mb-2 px-3">
                  <span className="rd__diamond"></span>
                  <p className="mb-2 list_dot">
                   
                   <span className="dot_li" />
                    
                  Free Cancellation
                  </p>
                  <p className="mb-2 list_tick">
                  <span>
                  <FaCheck className="green_check" />

                    </span>
                  {/* Approx. US$16 giveback in $Trip */}
                  </p>
                  <p className="mb-2 list_tick_grey">
                  <span>
                  <FaCheck className="green_check" />

                    </span>
                  Pay with Crypto
                  </p>
                  <p className="mb-2 list_tick_green">
                  {/* <span>
                  <FaCheck className="green_check" />

                    </span>
                  Free Cancellation till check-in
                  <span className="ps-2">
                    <Link to="/cancellationPolicy">Cancellation policy details</Link>
                  </span> */}
                  </p>
                </div>

                <div className="px-3">
                  <div className="progress_div">
                  <ProgressBar now={100} className="custom_progress" />
                  <p className="refund_text">100% Refund</p>
                  <div className="d-flex align-items-start mt-3 justify-content-between">
                    <p className="progress_text_left">NOW</p>
                    <div className="progress_text_right">
                    <p className="mb-0">29 Mar</p>
                    <p className="mb-0">11:59 AM</p>
                    <p className="text_light">Check-in</p>


                    </div>
                  </div>

                  </div>
                </div>

           <div className="card_footer px-3 pb-3">

           <div className="d-flex align-items-center justify-content-between flex_title_block">
                <div className="d-flex flex-column">
                <p className="black_bold_text mt-3 mb-3">Important information</p>

            </div>
            <a className="purple_a_tag" onClick={handleInformationShow}>View All</a>
              </div>


           <p className="mb-2 list_dot">
                   
                   <span className="dot_li" />
                    
                   Unmarried couples are not allowed
                  </p>

                  <p className="mb-2 list_dot">
                   
                   <span className="dot_li" />
                    
                  Free Cancellation. Passport, Aadhar, Govt. ID and Driving License are accepted as ID proof(s)
                  </p>

                  <p className="mb-2 list_dot">
                   
                   <span className="dot_li" />
                    
                   Pets are not allowed.
                  </p>

                  <p className="mb-2 list_dot">
                   
                   <span className="dot_li" />
                    
                   Local ids are allowed
                  </p>
           </div>
              </div>
            

              <div className="card card_book mb-3">
               
          

            <div className="px-3">
             
                <p className="black_bold_text mt-3 mb-3">Guest Details</p>

              

                <div className="px-3">
                  <Form>

                  {[...Array(count)].map((item, index) => {
                  return (
                    <Row className="mt-3">
                      <Col md={12}>
                      <div>
                <Form.Check
                  inline
                  label="Myself"
                  name="group1"
                  type="radio"
                  className="form_chekc_circle"
                  id={`inline-radio-1`}
                
                />

<Form.Check
                  inline
                  label="Someone Else"
                  name="group1"
                  type="radio"
                  className="form_chekc_circle"
                  id={`inline-radio-2`}
                
                />
                </div>
                      </Col>
                      <Col md={2}>
 <p className="black_label_sm mt-4 mb-2">Title</p>
                  <Select
        value={selectedOption1}
        onChange={()=>handleChange1()}
        options={options}
        defaultValue="theme1"
        styles={stylesgraybg}
        isSearchable={false}
        classNamePrefix="customselect"
        // defaultInputValue="Theme 1"
        // menuIsOpen 
      />
                      </Col>
                      <Col md={5}>
                      <p className="black_label_sm mt-4 mb-2">First Name</p>
                        <input
                  type="text"
                  placeholder="First Name"
                  className="cred_input_book"
                />
                        
                      </Col>
                      <Col md={5}>
                      <p className="black_label_sm mt-4 mb-2">Last Name</p>
                        <input
                  type="text"
                  placeholder="Last Name"
                  className="cred_input_book"
                />
                      </Col>

                      <Col md={7}>
                      <p className="black_label_sm mt-4 mb-2">Email Address
                      <span className="label_desc ms-1">
                      (Booking voucher will be sent to this email ID)
                        </span>
                        </p>
                        <input
                  type="text"
                  placeholder="First Name"
                  className="cred_input_book"
                />
                        
                      </Col>

                      <Col md={5}>
                      <p className="black_label_sm mt-4 mb-2">Mobile Number</p>

                      <PhoneInput
                      className="custom_phoneinput"
                      international 
                      defaultCountry="IN"
      placeholder="Enter phone number"
      value={value}
      onChange={setValue}/>

                      </Col>
                      <Col md={12}>
                      <Form.Check
                          className="mt-3 check_review_book"
                          name="group1"
                          label="Enter GST Details (Optional)"
                          type="checkbox"
                          id={"new"}
                        />
                      </Col>
                    </Row>
                  )})}

                    
                 
                   
                 
                
                    </Form>

                    <button className="trans_btn mt-3 mb-3  " onClick={()=>{
                      setCount(count+1)
                    }}>
                      <FaPlus className="mr-2"/> Add Guest
                    </button>

                </div>
          

          
            </div>

               

              

       
              </div>


              <div className="card card_book mb-3">
           
          

            <div className="px-3">
              <div className="d-flex align-items-center justify-content-between flex_title_block">
                <div className="d-flex flex-column">
                <p className="black_bold_text mt-3 mb-3">Trip Secure</p>
            </div>
            <a className="purple_a_tag">View Benefits</a>
              </div>

              <div className="row">
                <div className="col-12 col-md-2">
                <img src={trip} className="img-fluid img_trip" />

                </div>
                <div className="col-12 col-md-7 mt-3 mt-md-0">
                <div className="rd__leftBoxSingleFlex mb-2 px-3">
                 <p className="trip_title">Worry free activity booking!</p>
                 <div className="row">
                  <div className="col-12 col-lg-6 mb-3">
                  <p className="mb-2 list_dot list_dot_small">
                   
                   <span className="dot_li" />
                    
                   24*7 emergency medical assistance..
                  </p>

                  <p className="mb-2 list_dot list_dot_small">
                   
                   <span className="dot_li" />
                    
                   Upto ₹ 10K for activity booking cancellation charges if cancelled due to illness
                  </p>

                  <p className="mb-2 list_dot list_dot_small">
                   
                   <span className="dot_li" />
                    
                  Upto ₹ 20K for bounced booking
                  </p>
                  </div>
                  <div className="col-12 col-lg-6 mb-3">
                  <p className="mb-2 list_dot list_dot_small">
                   
                   <span className="dot_li" />
                    
                   Upto ₹ 20K for loss of tablets and laptops
                  </p>

                  <p className="mb-2 list_dot list_dot_small">
                   
                   <span className="dot_li" />
                    
                   Upto ₹ 20K for loss of baggage
                  </p>

                  <p className="mb-0 list_dot list_dot_small">
                   
                   <span className="dot_li" />
                    
                   Upto ₹ 10K for emergency travel of family members
                  </p>
                    </div>
                 </div>
                
               
                </div>
                  </div>
                  <div className="col-12 col-md-3 flex_end_md">
                  <div className="progress_text_right">
                    <p className="mb-0">₹ 29</p>
                    <p className="text_light_grey mb-0">per person</p>
                    <p className="text_light_grey">Non Refundable | 18% GST Included</p>
                      <div className="text-end">
                      <button className="add_btn_puple mt-0 mb-4 ms-auto">
                      <FaPlus className="mr-2"/> Add
                    </button>
                      </div>
                 

                    </div>
                  </div>
              </div>

          
            </div>

              

             

       
              </div>


              <div className="card card_book mb-3">
               
          

               <div className="px-3">
                
                   <p className="black_bold_text mt-3 mb-3">Payment Options</p>
   
                 
   
                   <div className="px-0">
                     <Form>
   
                  
                       <Row className="mt-3">
                         <Col md={12}>
                         <div>

                         <Form.Check type={"radio"} id={`check-api-radio`} className="checkbox_multiline">
            <Form.Check.Input type={"radio"} />
            <Form.Check.Label>Make full payment with other options</Form.Check.Label>
            <Form.Control.Feedback className="custom_feedback">
            Cancel for free any time before 29 Mar
            </Form.Control.Feedback>
          </Form.Check>


                   {/* <Form.Check
                     inline
                     label="Make full payment with other options"
                     name="group1"
                     type="radio"
                     className="form_chekc_circle"
                     id={`inline-radio-1`}
                   
                   /> */}
   
   {/* <Form.Check
                     inline
                     label="Someone Else"
                     name="group1"
                     type="radio"
                     className="form_chekc_circle"
                     id={`inline-radio-2`}
                   
                   /> */}
                   </div>
                   <hr className="hr_grey" />
                   <div className="mb-4">

<Form.Check type={"radio"} id={`check-api-radio1`} className="checkbox_multiline disabled_check">
<Form.Check.Input type={"radio"} disabled/>
<Form.Check.Label>Book with ₹0 Payment</Form.Check.Label>
<Form.Control.Feedback className="custom_feedback">
You will need to remove the WELCOMEMMT coupon to avail this option
</Form.Control.Feedback>
</Form.Check>


</div>
                         </Col>
                     
   
                        
   
                        
                       </Row>
                   
   
                   
                       </Form>
   
                      
   
                   </div>
             
   
             
               </div>
   
                 </div>


              
              <p className="m-0 rd__secondaryHint text-left mt-2">
              By proceeding, I agree to TripFi’s User Agreement, Terms of Service and Cancellation & Property Booking Policies.
              </p>
            </Col>
            <Col lg={4} className="mt-3 mt-lg-0">

            <div className="card card_book mb-3">
              
          

            <div className="px-3">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                <p className="black_bold_text mt-3 mb-3">Payment Details</p>

            </div>
              </div>

              <div className="row">
                <div className="col-7 col-md-8">
                  <p className="room_dark mb-0">3 Travellers</p>
                  <p  className="room_light mb-0">Base Price</p>

                </div>
                <div className="col-5 col-md-4">
                  <p className="text-end room_dark mb-0">₹ 46,142.33</p>
                  
                  </div>
              </div>
                   <hr className="hr_grey"/>

                   <div className="row">
                <div className="col-7 col-md-8">
                  <p className="room_green mb-0">Total Discount
                  <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip1}
    >
      <Button variant="success" className="tooltip_btn_trans">
        <FaInfoCircle className="mx-2"/>
      </Button>
    </OverlayTrigger></p>

                </div>
                <div className="col-5 col-md-4">
                  <p className="text-end room_green mb-0">₹ 1,000</p>
                  
                  </div>
              </div>
                   <hr className="hr_grey"/>

                   <div className="row">
                <div className="col-7 col-md-8">
                  <p className="room_dark mb-0">Sub Total</p>

                </div>
                <div className="col-5 col-md-4">
                  <p className="text-end room_dark mb-0">₹ 45,142.33</p>
                  
                  </div>
              </div>
                   <hr className="hr_grey"/>

                   <div className="row">
                <div className="col-7 col-md-8">
                  <p className="room_dark mb-0">Taxes & Service Fees
                  <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip1}
    >
      <Button variant="success" className="tooltip_btn_trans">
        <FaInfoCircle className="mx-2"/>
      </Button>
    </OverlayTrigger></p>

                </div>
                <div className="col-5 col-md-4">
                  <p className="text-end room_dark mb-0">₹ 500</p>
                  
                  </div>
              </div>
                   <hr className="hr_grey"/>
                   <Form.Check type={"checkbox"} id={`check-api-checkbox`} className="checkbox_multiline check_with_a">
            <Form.Check.Input type={"checkbox"} />
            <Form.Check.Label>
              <div className="d-flex">
              <p>
                <span>Donate ₹5 to build resilient travel
destinations.</span> <span className="list_tick_green"><Link to="/" className="">T&Cs</Link></span></p>
<p className="min_w_100">
<OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <Button variant="success" className="tooltip_btn_trans">
        <FaInfoCircle className="me-2"/>
      </Button>
    </OverlayTrigger>
    <span>₹ 0</span>
</p>
              </div>
            </Form.Check.Label>
          
          </Form.Check>

            </div>

              

               

           <div className="card_footer card_footer_grey px-3 py-3">

         
           <div className="row">
                <div className="col-7 col-md-8">
                  <p className="room_dark_footer mb-0">Total Amount to be paid</p>

                </div>
                <div className="col-5 col-md-4">
                  <p className="text-end room_dark_footer mb-0">₹ 45,642.33</p>
                  
                  </div>
              </div>
           </div>
              </div>

              <div className="card card_success mb-3 p-3">
                <div className="d-flex align-items-center">
                <img src={successdollar} className="icon_card_img me-3" />

                  <div>
                    <p className="mb-0">Congrats! You’ll receive approx.</p>
                    <p className="mb-0"><span>US$1.98</span> giveback in Trip on this booking</p>
                  </div>

                </div>
              </div>

              <div className="card card_success mb-3 p-3">
                <div className="d-flex align-items-center">
                <img src={successtick} className="icon_card_img me-3" />

                  <div>
                    <p className="mb-0 text_green">Earn a proof-of-travel NFT stamp for this booking</p>
                  </div>

                </div>
              </div>

              <div className="card card_danger mb-3 p-3">
                <div className="d-flex align-items-center">
                <img src={dangertimer} className="icon_card_img me-3" />

                  <div>
                    <p className="mb-0 text_red">Hurry! We only have 2 activities left at this price</p>
                  </div>

                </div>
              </div>


              {/* <div className="card card_book mb-3 pb-4">
              
          

            <div className="px-3">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                <p className="black_bold_text mt-3 mb-3">Coupon Codes</p>

                {coupons.map((item, index) => {
          return (
            <CouponCard data={item} />
          )})}


<div className="card card_pink mb-3 p-3">
               
                    <p className="mb-0 text_red">PT Gift Cards can be applied at payment step</p>

                </div>


                <div className="search_input_grp">
                <input type="email" placeholder="Enter your voucher code" />
                <Button className="btn">
<FaArrowRight />
                </Button>
              </div>
           


              


            </div>
              </div>

                

              
            </div>

              

               

         
              </div> */}
            
                </Col>
          </Row>

          <Link to="/activityPaymentOption" type="button" class="pay-btn btn btn-primary mt-3 mb-4">PAY NOW</Link>
        </Container>
      </div>
</div>
      <Footer />
{inclusionShow &&
 <InclusionModal
 inclusionShow={inclusionShow}
 handleInclusionClose={handleInclusionClose}
/>
}

{informationShow &&
 <InformationModal
 informationShow={informationShow}
 handleInformationClose={handleInformationClose}
/>
}
     
    </>
  );
}
