import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "react-datepicker/dist/react-datepicker.css";
import { IoIosArrowForward } from "react-icons/io";
import { BsSliders } from "react-icons/bs";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useLocation ,useNavigate} from "react-router-dom";
import SearchFilter from "../components/SearchFilter";
import HotelSelectFilter from "../components/HotelSelectFilter";
import HotelRoomsList from "../components/HotelRoomsList";
import * as Hotels from "../Axios/hotel.axios";
import moment from "moment";
import { DateTimeForm, Decryptdata, EncryptData, Ratingsquoetes } from "../lib/common";
import CustomLoader from "../components/CustomLoader";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { socket } from "../hooks/socket";

export default function Search() {
  const [show, setShow] = useState(false);
  const [Hotelsdetails, setHotelsdetails] = useState([]);
  const [Filters, setFilters] = useState("Popular");
  const [searchsubmit, setsearchsubmit] = useState(true);
  const [loader, setloader] = useState(false);
  const [loadlimit, setloadlimit] = useState(10);


  const Hotelsdata = useSelector((state) => state.HotelReducer);

  const dispatch=useDispatch()
  const navigate = useNavigate();


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const paramsData = searchParams.get("searchdata");
  const SearchData = Decryptdata(paramsData);
  

  const initial = {
    Searchdata: SearchData?.SearchData,
    Checkin: SearchData?.Checkin,
    Checkout: SearchData.Checkout,
    SelectedData: SearchData?.SelectedData,
    roomInfo: SearchData.roomInfo,
    Citydata: SearchData.Citydata,
  };
  const [formdata, setFormdata] = useState(initial);

  useEffect(() => {
    if(searchsubmit){
      Searchfun();

    }
  }, [searchsubmit]);




  const Searchfun = async (e) => {
    setloader(true)
    let sendData = {
      searchQuery: {
        checkinDate: moment(formdata?.Checkin).format("YYYY-MM-DD"),
        checkoutDate: moment(formdata?.Checkout).format("YYYY-MM-DD"),
        roomInfo: formdata?.roomInfo,
        searchCriteria: {
          city: formdata?.SelectedData?.id??formdata?.SelectedData?.city,
          nationality:formdata?.SelectedData?.nationality?? formdata?.SelectedData?.Countries?.[0]?.countryid,
          currency: "INR",
        },
        searchPreferences: {
          ratings: [5,4,3,2,1],
          fsc: true,
        },
      },
      sync: true,
    };

    let { success,data } = await Hotels?.Available_Hotel_Search(sendData);
    if(data?.searchResult?.his){

      dispatch({
        type: "Hotels",
        Hotels: data?.searchResult?.his
      })

      data?.searchResult.his.map((item)=>{
        item.roomsonly=item?.pops[0]?.fc[0]
        item.pricepernight=parseInt(item?.pops[0]?.tpc)
        item.userreview=Ratingsquoetes(item?.rt)

      })
      // console.log("data?.searchResult.hisdata?.searchResult.his",data?.searchResult.his);
      setHotelsdetails(data?.searchResult.his)
      setloader(false)
      navigate(`/search?searchdata=${EncryptData(formdata)}`)

    }
    else{
      setloader(false)
      setHotelsdetails([])

    }
    
  }

  const Headtfilters=(data,searchdata)=>{
    let Hotels=Hotelsdata
    setFilters(data)

    //Ratings Ascending Order

    if(data=='Topratings'){
      let test =  Hotels.sort((a, b) => b?.rt - a?.rt );
      setHotelsdetails([...Hotels])

    }
   else if(data=='PriceHightoLow'){

      let test =  Hotels.sort((a, b) => b.pops[0]?.tpc - a.pops[0]?.tpc );

      setHotelsdetails([...Hotels])

    }
    else if(data=='PriceLowtoHigh'){
      let test =  Hotels.sort((a, b) => a.pops[0]?.tpc - b.pops[0]?.tpc );
      setHotelsdetails([...Hotels])

    }
    else if(data=='Popular'){
      let test =  Hotels.sort((a, b) => a?.rt - b?.rt );
      setHotelsdetails([...Hotels])


      // setHotelsdetails([...Hotelsdata])
// Search()
      
    }
    else if(data=='Search'){
      const {value}=searchdata.target 
      
     const getVal =  Hotels?.filter(val => val.name?.toLowerCase().replace(/\s/g,'').includes(value?.toLowerCase().replace(/\s/g,''))
     ||val?.ad?.adr?.toLowerCase().replace(/\s/g,'').includes(value?.toLowerCase().replace(/\s/g,'')) )
     setHotelsdetails(getVal?[...getVal]:[...Hotels])


    }

  }
  const LoadMore =()=>{
    setloadlimit(loadlimit+10)
  }
  
  return (
    <>
      <Header />
<div className="cmn_pdt">
      <div className="search-page">
        <div className="search-header">
          <div className="container custom-container-search">
            <SearchFilter
              Searchdata={formdata ? formdata : ""}
              Setformdata={setFormdata}
              Searchfun={Searchfun}
              Setsearchsubmit={setsearchsubmit}
            />
            <div className="properties">
              <p className="mb-1 f-12 nav-home">
                <Link to="/">Home</Link>
                <IoIosArrowForward className="mx-2" />
                <Link to="/">Hotels and more in {formdata?.SelectedData?.cityName}</Link>
              </p>
              <h5 className="mb-0 fw-800">{""} Properties</h5>
            </div>
          </div>
        </div>
        <div className="sort-sec pb-3">
          <div className="container custom-container-search">
            <div className="select-sort-fields">
              <p className="mb-0 sort-by fw-700 f-15">SORT BY:</p>
              <div className={`popular f-14 fw-600 cmn-tabs ${Filters=='Popular'?'active':""}` } onClick={()=>Headtfilters("Popular")}>
                Popular</div>

              <div className={`user-rating f-14 cmn-tabs ${Filters=='Topratings'?'active':""}`} onClick={()=>Headtfilters("Topratings")}>
                <span className="fw-600">User Rating</span> (Highest First)
              </div>
              <div className={`price high f-14 cmn-tabs ${Filters=='PriceHightoLow'?'active':""}`} onClick={()=>Headtfilters("PriceHightoLow")}>
                {" "}
                <span className="fw-600">Price</span> (Highest First)
              </div>
              <div className={`price low f-14 cmn-tabs ${Filters=='PriceLowtoHigh'?'active':""}`} onClick={()=>Headtfilters("PriceLowtoHigh")}>
                {" "}
                <span className="fw-600">Price</span> (Lowest First)
              </div>
              <div className="search-home">
                <input
                  type="text"
                  placeholder="Search for locality / hotel name"
                  onChange={(data)=>Headtfilters('Search',data)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="filter-rooms">
          <div className="container custom-container-search">
            {loader && 
               <div className="dataFount_loader">
                  <CustomLoader />
                </div>}
            
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-xl-3 col-lg-3 mb-3">
                <div className="select-filter-sec-web d-none d-lg-block">
                  {!loader&&Hotelsdetails&&
                  <HotelSelectFilter Hotelsdetails={Hotelsdetails} SetHotelsdetails={setHotelsdetails}/>

                  }
                </div>
                <div className="select-filter-sec-mbl d-block d-lg-none">
                  <div className="filter-slider" onClick={handleShow}>
                    <BsSliders />
                  </div>
                  <Offcanvas
                    show={show}
                    onHide={handleClose}
                    className="selectFilter-offcanvas"
                  >
                    <Offcanvas.Header closeButton></Offcanvas.Header>
                    <Offcanvas.Body className="filter-rooms">
                      <HotelSelectFilter />
                    </Offcanvas.Body>
                  </Offcanvas>
                </div>
              </div>

              {/* <div className="col-xl-3 mb-3">
                <HotelSelectFilter />
              </div> */}

              <div className="col-12 col-lg-9 col-xl-9">
                {!loader&& (
                  <HotelRoomsList Hotels={Hotelsdetails} formdata={formdata} loadlimit={loadlimit} />
                )}

             {/* {loader && 
               <div className="dataFount_loader">
                  <CustomLoader />
                </div>} */}
              </div>
            

<div className="col-12 mt-3 d-flex align-items-center justify-content-center">
            {Hotelsdetails?.length > loadlimit  &&
             <Button onClick={()=>LoadMore()} className="cmn-btn px-4 fw-600" style={{fontWeight:600}}>
                  Loadmore
                </Button>
                }
             
            </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <Footer />
    </>
  );
}
