import React from "react";
import Button from "react-bootstrap/esm/Button";
import Card from "react-bootstrap/Card";
import { MdArrowForwardIos } from "react-icons/md";
import { Link } from "react-router-dom";
import config from "../lib/config";
function CommonCard({ countryImg, logo, name, stays, link, footerbg, blog }) {
  console.log(
    "fgfdhloklopkp",
    countryImg,
    logo,
    name,
    stays,
    link,
    footerbg,
    blog
  );
  return (
    <div className="country-card">
      <div className="common-card">
        <Link to={link} className="text-decoration-none">
          <Card>
            <Card.Img
              src={`${config.IMG_URL}/CititesCountries/${countryImg}`}
              className="img-fluid country-bg"
            />
            {!blog ? (
              <>
                <Card.Body
                  className={
                    footerbg == "black"
                      ? "d-flex align-items-center justify-content-between bg-black"
                      : "d-flex align-items-center justify-content-between"
                  }
                >
                  <div className="d-flex align-items-center">
                    <img
                      src={`${config.IMG_URL}/CititesCountries/${logo}`}
                      className="img-fluid country-logo"
                    />
                    <span className="ms-2 fw-600 f-15">{name}</span>
                  </div>
                  <span className="fw-600 f-15">{stays}</span>
                </Card.Body>
              </>
            ) : (
              <>
                <Card.Body className={footerbg == "black" ? "bg-black" : ""}>
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="ms-2 fw-600 f-15">{name}</span>
                    <Link to={link} className="blog-card-nav">
                      <span className="ms-2 fw-600 f-15">
                        <MdArrowForwardIos />
                      </span>
                    </Link>
                  </div>
                </Card.Body>
              </>
            )}
          </Card>
        </Link>
      </div>
    </div>
  );
}

export default CommonCard;
