import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Dropdown from "react-bootstrap/Dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/esm/Button";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link } from "react-router-dom";


import Image from "../components/Image";
import { Col, Container, Row, Form, ProgressBar } from "react-bootstrap";
import Select from "react-select";

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import { BiRightArrowAlt } from "react-icons/bi";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";
import { allData } from "../Data/CommonData";

import InclusionModal from "../modals/InclusionModal";
import InformationModal from "../modals/InformationModal";

import Paymenttab from "../components/Paymenttab";


import { FaCheck, FaPlus, FaInfoCircle, FaArrowRight } from "react-icons/fa";

import CouponCard from "../components/couponCard";

import bookroom from "../assets/images/book/bookroom.png"
import payman from "../assets/images/book/pay_man.png"
import img_full from "../assets/images/activitydetail/img_full.png"


import successdollar from "../assets/images/book/successdollar.png"
import successtick from "../assets/images/book/successtick.png"
import dangertimer from "../assets/images/book/dangertimer.png"
import payment from "../assets/images/book/payment.png"
import roomicon from "../assets/images/book/room_icon.png"
import tagicon from "../assets/images/book/tag_icon.png"
import usericon from "../assets/images/book/user_icon.png"




import trip from "../assets/images/book/trip.png"
import verified from "../assets/images/book/verified.png"


export default function PaymentOption() {



useEffect(()=>{

},[])









  return (
    <>
      <Header />
      <div className="cmn_pdt">
      <div className="search-page particular-room-details">
        <div className="search-header">
          <div className="container">
           <p className="filter_text mb-0">Payment Option</p>
          </div>
        </div>
       
       
     

        <Container>
        <Row className="justify-content-center mt-4">
            <Col lg={8}>
            </Col>
            <Col lg={4}>
                <p className="safe_text text-end">
                    <img src={verified} className="me-2" />
                    <span>safe & secure</span>
                </p>
            </Col>
            </Row>
            
          <Row className="justify-content-center mt-0 mb-2">
            <Col lg={8}>

                
            <div className="card card_book card_book_badge mb-3">
                <div className="badge_purple_card">Worry Free activity booking!</div>
                <Row className="p-3 mt-5 mt-sm-3">
                <Col sm={3} md={3} className=" d-flex align-items-center justify-content-center">
                        <img src={payman} className="img-fluid" />
                        </Col>
                    <Col md={6} lg={6} sm={6} className="mt-3 mt-sm-0">
                    <p className="black_bold_text mt-0 mb-1">Trip Secure | One plan, Many benefits</p>

              <p className="book_text_light mt-0 mb-0">
              Hassle free stay. Get upto: Rs. 10K for activity booking cancellation charges if cancelled due to illness, Rs. 20K for bounced booking, 24x7 emergency medical assistance, Rs. 20K for loss of tablets and laptops, Rs. 20K for loss of baggage, Rs. 10K for emergency travel of family members. T&Cs apply.
              </p>
                    </Col>
                    <Col sm={3} md={3} lg={3} className="mt-3 mt-sm-0 text-center text-sm-center d-flex align-items-end justify-content-center flex-column">
                    <button className="btn btn_outline_purple">ADD @ 29</button>
                       <p className="text-center book_text_light mt-1">per person</p>
                        </Col>
                </Row>
                </div>


            <div className="card card_book mb-3 card_book_more_radius">
              <Row className="mx-0">
                <Col md={4} className="bg_pink header_bg">
                <p className="black_bold_text mt-3 mb-3">Payment options</p>

                </Col>
              </Row>
         
<Paymenttab  type={"Activities"}/>



              </div>
              

              <Row className="mt-0 mb-2">
            <Col md={{span:8, offset:4}}>
              <p className="payment_desc_form"><span>When you click the “Complete Reservation” button, you will see the tab to pay with
Binance. Login to your Binance account and make the payment and your booking
will be processed and confirmed.</span></p>
              </Col>
              </Row>
             
           
            </Col>
            <Col lg={4} className="mt-3 mt-lg-0">

            <div className="card card_book mb-3 card_book_more_radius">
                <img src={img_full} className="img-fluid img_pay_full" />

                <div className="purple_gradient">
                    <p className="mb-0">3-Hour Luxury Yacht Cruise from Dubai Marina</p>
                    <p className="mb-0">11, Melakkal Rd, Near Fenner & Passport Office, Kochadai, Madurai,
Tamil Nadu 625016, Madurai, India</p>
                </div>
               
          
            <Row className="p-3 align-items-center">
                <Col md={5}>
                    <p className="book_text_bold mb-0">CHECK IN</p>
                    <div className="d-flex align-items-center justify-content-between">
                    <p className="book_text_big mb-0">Fri, 29 Mar'24</p>

                    </div>
                    <p className="book_text_small_bold mb-0">12:00 PM</p>

                </Col>
                <Col md={2}>
                <span className="badge text-dark text-uppercase">-</span>
                    
                </Col>
                <Col md={5}>
                    <p className="book_text_bold mb-0">CHECK OUT</p>
                    <div className="d-flex align-items-center justify-content-between">
                    <p className="book_text_big mb-0">Sat, 30 Mar'24</p>

                    </div>
                    <p className="book_text_small_bold mb-0">12:00 PM</p>

                </Col>
               
            </Row>
          <div className="bg_green px-3">
            <div className="d-flex align-items-center">
              <img src={payment} />
              <span>Free cancellation till 11:59 AM, 29 Mar 2024</span>
            </div>

          </div>

            <div className="px-3 mt-3">
    
              <div className="d-flex align-items-start justify-content-start mb-3">
                <img src={usericon} className="book_icons mt-1" />
                <div>
                <p className="black_bold_text_ssm mt-0 mb-0 ms-3">Traveler(s)</p>
            <p className="book_text_light_ssm mb-0 ms-3 text-uppercase">Nivitha</p>
            <p className="book_text_light_ssm mb-0 ms-3"><span>nivitha1995@gmail.com</span> | <span>+91-9514348547</span></p>

            </div>
              </div>

          
            </div>

             

            

         
              </div>


           

              <div className="card card_success mb-3 p-3">
                <div className="d-flex align-items-center">
                <img src={successdollar} className="icon_card_img me-3" />

                  <div>
                    <p className="mb-0">Congrats! You’ll receive approx.</p>
                    <p className="mb-0"><span>US$1.98</span> giveback in AVA on this booking</p>
                  </div>

                </div>
              </div>

              <div className="card card_success mb-3 p-3">
                <div className="d-flex align-items-center">
                <img src={successtick} className="icon_card_img me-3" />

                  <div>
                    <p className="mb-0 text_green">Earn a proof-of-travel NFT stamp for this booking</p>
                  </div>

                </div>
              </div>

              <div className="card card_danger mb-3 p-3">
                <div className="d-flex align-items-center">
                <img src={dangertimer} className="icon_card_img me-3" />

                  <div>
                    <p className="mb-0 text_red">Hurry! We only have 2 activities left at this price</p>
                  </div>

                </div>
              </div>


              <div className="card card_book mb-3 card_book_more_radius">
              
          

              <div className="px-3 py-3">
              
  
                <div className="row">
                  <div className="col-7 col-md-8">
                    <p className="room_dark room_dark_big mb-0">Fare Summary</p>
  
                  </div>
                  <div className="col-5 col-md-4">
                    <p className="text-end room_dark_purple mb-0">Close</p>
                    
                    </div>
                </div>
                     <hr className="hr_grey"/>
  
                    
  
                     <div className="row">
                  <div className="col-7 col-md-8">
                    <p className="room_dark mb-0">3 Travellers</p>
  
                  </div>
                  <div className="col-5 col-md-4">
                    <p className="text-end room_dark mb-0">₹46,142.33</p>
                    
                    </div>
                </div>
                     <hr className="hr_grey"/>
  
                 

                     <div className="row">
                  <div className="col-7 col-md-8">
                    <p className="room_dark_purple mb-0">Service fee
                   </p>
  
                  </div>
                  <div className="col-5 col-md-4">
                    <p className="text-end room_dark_purple mb-0">₹1,153.56</p>
                    
                    </div>
                </div>
                     <hr className="hr_grey"/>
                     
                     <div className="row">
                  <div className="col-7 col-md-8">
                    <p className="room_dark_footer_bold mb-0">Total Due</p>
  
                  </div>
                  <div className="col-5 col-md-4">
                    <p className="text-end room_dark_footer_bold mb-0">₹47,295.89</p>
                    
                    </div>
                </div>
              </div>
  
                
  
                 
  
            
                </div>

                <p className="room_dark_purple">Over 53 million satisfied TripFi customers.</p>


           
            
                </Col>
          </Row>

        </Container>
      </div>
      </div>
      <Footer />



     
    </>
  );
}
