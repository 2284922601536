import React, { useState, useEffect, useRef } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Dropdown from "react-bootstrap/Dropdown";
import "react-datepicker/dist/react-datepicker.css";
import { TiStar } from "react-icons/ti";
import { IoMdStar } from "react-icons/io";

import Button from "react-bootstrap/esm/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";

import { IoIosArrowForward } from "react-icons/io";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import Image from "../components/Image";
import { Col, Row, Form } from "react-bootstrap";
import PhotoModal from "../modals/PhotoModal";
import InclusionModal from "../modals/InclusionModal";
import InformationModal from "../modals/InformationModal";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";
import { FaCheck } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import { IoSearch } from "react-icons/io5";
import Slider from "react-slick";

import noImg from "../assets/images/noimage.png"
import * as Hotels from "../Axios/hotel.axios";
import {
  AverageRatings,
  DateTimeForm,
  Decryptdata,
  EncryptData,
  Name_showing,
  Ratingsquoetes,
  isEmpty,
} from "../lib/common";
import SearchFilter from "../components/SearchFilter";
import ViewMapModal from "../modals/ViewMapModal";
import Fecility from "../modals/fecilitesModel";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import AddReviewsModal from "../modals/AddReviewsModal";
import config from "../lib/config";
import NodataFound from "../components/NodataFound";
import ReviewPhotoModal from "../modals/Reviewphotomodel";
import { FaLocationDot } from "react-icons/fa6";
import {
  withGoogleMap,
  GoogleMap,
  InfoWindow,
  Marker,
} from "react-google-maps";

export default function RoomDetails() {
  // const  {CurrencyConvert}  = useSelector((state) => state.LoginReducer);
  // console.log("InrTouseddddds123",CurrencyConvert);
  const [HotelData, setHotelData] = useState(false);
  const [Fecilities, setFecility] = useState("");
  const [active, setActive] = useState(1);
  const [loadlimit, setloadlimit] = useState(10);
  const [MapLoaded, SetMapLoaded] = useState(false);
  const [getCenter, SetgetCenter] = useState("");
  const [Clicked, SetClicked] = useState("");
  const [photoShow, setPhotoShow] = useState(false);
  const [ReviewphotoShow, setReviewPhotoShow] = useState(false);
  const [SearchData, setSearchData] = useState({});
  const [HotelImages, setHotelImages] = useState([]);
  const [roomloadlimit, setroomloadlimit] = useState(3);



  var reviews = {
    Location: 0,
    Room: 0,
    Food: 0,
    Amenities: 0,
    Hospitality: 0,
    Cleanliness: 0,
  };
  const [Overallreviews, Setoverallreviews] = useState(reviews);

  var init = {
    All: { loader: true, page: 1, list: [] },
  };
  const [ReviewsandRatings, SetReviewsandRatings] = useState(init);
  const [Reviewstars, SetReviewstars] = useState(0)
  const [AllReviews, SetAllReviews] = useState({ images: [], rationgscount: 0 })
  const [BookedRooms, SetBookedRooms] = useState(HotelData?.hotel?.ops ?? [])
  const [AllHotelsdata, SetAllHotelsdata] = useState([])




  const [Tabname, SetTabname] = useState('All');
  const [Cancellation, setCancellation] = useState('');
  const [type, settype] = useState('');
  const selectRoom = useRef(null);
  const selectLocation = useRef(null);
  const selectAmenties = useRef(null);
  const selectRule = useRef(null);
  const selectReviews = useRef(null);
  const selectProperties = useRef(null);
  const selectOverview = useRef(null);

  const handlePhotoClose = () => setPhotoShow(false);
  const handlePhotoShow = () => setPhotoShow(true);

  const handleReviewPhotoClose = () => setReviewPhotoShow(false);
  const handleReviewPhotoShow = () => setReviewPhotoShow(true);

  const [amenitiesShow, setAmenitiesShow] = useState(false);
  const handleAmenitiesClose = () => setAmenitiesShow(false);
  const handleAmenitiesShow = (data, type) => {
    setFecility(data);
    setAmenitiesShow(true);
    settype(type);
  };

  const [inclusionShow, setInclusionShow] = useState(false);
  const handleInclusionClose = () => setInclusionShow(false);

  const handleInclusionShow = (data) => {
    setCancellation(data);
    setInclusionShow(true);
  };

  const [informationShow, setInformationShow] = useState(false);
  const handleInformationClose = () => setInformationShow(false);
  const handleInformationShow = () => setInformationShow(true);

  const [viewMapShow, setViewMapShow] = useState(false);
  const handleMapShow = () => setViewMapShow(true);
  const handleMapclose = () => setViewMapShow(false);
  const [addReview, setAddReview] = useState(false);

  const scrollToSection = (ref) => {
    ref?.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    var pos = {
      lat: Number(HotelData?.hotel?.gl?.lt),
      lng: Number(HotelData?.hotel?.gl?.ln),
    };
    SetgetCenter(pos);
    SetClicked(pos);
  }, [HotelData]);

  const MapContainer = withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={15}
      defaultCenter={getCenter}
      onTilesLoaded={() => SetMapLoaded(true)}
      onClick={(e) => {
        // SetIsOpen(false);
        SetClicked({
          lat: e?.latLng?.lat(),
          lng: e?.latLng?.lng(),
        });
      }}
    >
      <Marker
        clickable={true}
        // draggable
        position={Clicked}
      ></Marker>
    </GoogleMap>
  ));


  const SimilarPropsettings = {
    // className: "center",
    centerMode: false,
    infinite: true,
    // centerPadding: "60px",
    margin: "10px",
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const Amenties = {
    className: "center",
    centerMode: true,
    infinite: true,
    // centerPadding: "60px",
    margin: "10px",
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  /**Redux and dispatch */
  const dispatch = useDispatch();


  /**Navigation */
  const location = useLocation();
  const navigate = useNavigate();

  /**Props Data Get */
  const searchParams = new URLSearchParams(location?.search);
  const Hotelid = searchParams.get("hotelid");

  const initial = {
    Searchdata: SearchData?.SearchData,
    Checkin: SearchData?.checkinDate,
    Checkout: SearchData?.checkoutDate,
    SelectedData: SearchData?.searchCriteria,
    roomInfo: SearchData?.roomInfo,
    Citydata: SearchData?.searchCriteria,
  };
  const [formdata, setFormdata] = useState(initial);

  useEffect(() => {
    if (SearchData) {
      setFormdata({
        ...formdata,
        ...{
          Searchdata: SearchData?.SearchData,
          Checkin: SearchData?.checkinDate,
          Checkout: SearchData?.checkoutDate,
          SelectedData: SearchData?.searchCriteria,
          roomInfo: SearchData?.roomInfo,
          Citydata: SearchData?.searchCriteria,
        },
      });
    }
  }, [SearchData]);

  const NavigatetoSearch = () => {
    navigate(`/search?searchdata=${EncryptData(formdata)}`);
  };

  useEffect(() => {
    if (SearchData) {
      setFormdata({
        ...formdata,
        ...{
          Searchdata: SearchData?.SearchData,
          Checkin: SearchData?.checkinDate,
          Checkout: SearchData?.checkoutDate,
          SelectedData: SearchData?.searchCriteria,
          roomInfo: SearchData?.roomInfo,
          Citydata: SearchData?.searchCriteria

        }

      })

    }


  }, [SearchData])


  useEffect(() => {
    HotelDetails()
    scrollToSection(selectOverview)
    GetOverallreviews()
  }, [location])

  useEffect(() => {
    Search()

  }, [HotelData])



  useEffect(() => {
    ReviewsandRatings[Tabname] = { page: 1, list: [], loader: false };
    SetReviewsandRatings(ReviewsandRatings);
    GetUserReviews(1, Tabname);


  }, [Tabname])

  useEffect(() => {
    if (Overallreviews) {
      Overallratings()

    }
  }, [Overallreviews])

  useEffect(() => {
    if (HotelData) {
      BookingHotelDetails()

    }

  }, [HotelData])


  const GetUserReviews = async (page, tab) => {
    let SendData = {
      Hotelid: Hotelid?.split("-")[1],
      TabName: tab,
      limit: 10,
    };
    let { success, data } = await Hotels.GetUserRatingsandreviews(SendData);
    if (data?.length > 0) {
      SetReviewsandRatings({
        ...ReviewsandRatings,
        ...{
          [tab]: {
            list: [
              ...(ReviewsandRatings[tab]?.list ?? []),
              ...data[0]?.Userreviews,
            ],
            loader: data[0]?.Userreviews?.length == 0 ? false : true,
            page: ReviewsandRatings[tab]?.page,
            // filter: Filter,
            // Collectiontype:type
          },
        },
      });
      Setoverallreviews(data[0]);
    }
  };





  const GetOverallreviews = async () => {
    let { success, data } = await Hotels.GetOverallreviews({
      Hotelid: Hotelid?.split("-")[1],
    });
    console.log("datadata", data);
    let Arr = [];
    if (data) {
      data.map((val) => {
        val?.Images?.map((itm) => {
          Arr.push(itm);
        });
      });
      SetAllReviews({
        ...AllReviews,
        ...{ ["images"]: Arr, ["rationgscount"]: data?.length },
      });
    }
  };

  const Search = async (e) => {
    // setloader(true)

    let sendData = {
      searchQuery: {
        checkinDate: moment(formdata?.Checkin).format("YYYY-MM-DD"),
        checkoutDate: moment(formdata?.Checkout).format("YYYY-MM-DD"),
        roomInfo: formdata?.roomInfo,
        searchCriteria: {
          city: formdata?.SelectedData?.city,
          nationality: formdata?.SelectedData?.nationality,
          currency: "INR",
        },
        searchPreferences: {
          ratings: [5, 4, 3, 2, 1],
          fsc: true,
        },
      },
      sync: true,
    };

    let { success, data } = await Hotels?.Available_Hotel_Search(sendData);
    console.log("hotelsListsData", data);
    if (data) {
      dispatch({
        type: "Hotels",
        Hotels: data?.searchResult?.his ?? [],
      })

    }
    SetAllHotelsdata(data?.searchResult?.his)
  }


  /** Particular Hotel and Room  Details*/

  const HotelDetails = async () => {
    let Arr = [];
    let { status, data } = await Hotels.Hotel_Details({ 'id': Hotelid });
    if (data) {

      data.hotel?.img?.length > 0 &&
        data.hotel?.img?.map((item) => {
          let imagename = item?.url?.split("/")[item?.url?.split("/").length - 1];
          let imageforsplit = imagename.split(".")[0][imagename.split(".")[0]?.length - 1];

          if (imageforsplit == "z") {
            Arr.push(item);
          }

        });
      setHotelImages(Arr);

    }

    setHotelData(data)
    setSearchData(data?.searchQuery)


  }
  const BookingHotelDetails = async () => {
    let { success, data } = await Hotels.Get_Booking_Hotel_Details({ 'HotelId': Hotelid.split('-')[1] });

    let Bookingrooms = HotelData?.hotel?.ops?.filter((val) => (data?.length > 0 && !data?.some((item) => item?.RoomId == val?.id)))
    let Bookedrooms = Bookingrooms?.length > 0 ? Bookingrooms : HotelData?.hotel?.ops

    // ris[0].imgs

    SetBookedRooms(Bookingrooms?.length > 0 ? Bookingrooms : HotelData?.hotel?.ops)

  }
  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <TiStar
          key={i}
          className={rating >= i ? "filled-star" : "non-filled-star"}
        />
      );
    }
    return stars;
  };

  const handleAddReviewShow = () => {
    setAddReview(true);
  };
  const handleAddReviewClose = () => {
    setAddReview(false)
  }




  const Overallratings = () => {
    let arr = [];
    Object.values(Overallreviews).map((val) => {
      if (typeof val == "number") {
        arr.push(val);
      }
    });
    let averagestars = AverageRatings(arr);
    SetReviewstars(averagestars);
  };
  const LoadMore = () => {
    setroomloadlimit(roomloadlimit + 3)
  }

  return (
    <>
      <Header />
      <div className="cmn_pdt">
        <div className="search-page particular-room-details">
          <div className="search-header">
            <div className="container custom-container-search">
              <SearchFilter
                Searchdata={formdata ? formdata : ""}
                Setformdata={setFormdata}
                Searchfun={NavigatetoSearch}
              />
            </div>
          </div>
          <div className="sort-sec">
            <div className="container custom-container-search">
              <div className="select-sort-fields">
                <div
                  className="cmn-tabs active"
                  onClick={() => scrollToSection(selectOverview)}
                >
                  <span className="txt">OVERVIEW</span>
                </div>
                <div
                  className="cmn-tabs"
                  onClick={() => scrollToSection(selectRoom)}
                >
                  <span className=" txt">ROOMS</span>
                </div>
                <div
                  className="cmn-tabs"
                  onClick={() => scrollToSection(selectLocation)}
                >
                  <span className=" txt">LOCATION</span>
                </div>
                <div
                  className="cmn-tabs"
                  onClick={() => scrollToSection(selectAmenties)}
                >
                  <span className=" txt">AMENITIES</span>
                </div>
                <div
                  className="cmn-tabs"
                  onClick={() => scrollToSection(selectRule)}
                >
                  <span className=" txt">PROPERTY RULES</span>
                </div>
                <div
                  className="cmn-tabs"
                  onClick={() => scrollToSection(selectReviews)}
                >
                  <span className=" txt">USER REVIEWS</span>
                </div>
                {AllHotelsdata?.length > 0 &&
                  <div
                    className="cmn-tabs"
                    onClick={() => scrollToSection(selectProperties)}
                  >
                    <span className=" txt">SIMILAR PROPERTIES</span>
                  </div>}
              </div>
            </div>
          </div>
          {/* <div className="room-name-details" ref={selectOverview}>
          <div className="container custom-container-search">
            <p className=" mb-0 f-15 nav-home">
              <Link to="/">Home</Link>
              <span className="d-flex d-sm-inline align-items-center">
                <IoIosArrowForward className="mx-2" />
              </span>
              <Link to="/">Hotels In Dubai</Link>
              <span className="d-flex d-sm-inline align-items-center">
                <IoIosArrowForward className="mx-2" />
              </span>
              <Link to="/">Resort In Dubai</Link>
              <span className="d-flex d-sm-inline align-items-center">
                <IoIosArrowForward className="mx-2" />
              </span>
              <h5 className="mb-0 fw-700 f-15 d-inline text-nowrap">
                Heritage Dubai
              </h5>
            </p>
          </div>
        </div> */}
          {/* <div className="container-fluid p-0">
          <div className="rd__bannerImgHolder" id="bannerBlur">
            <div className="container position-relative">
              <img
                src={HotelData?.hotel?.img?.[0]?.url ?? banner}
                className="img-fluid room-banner"
                alt=""
              />
              <Button
                className="banner-gal view_pho_lin"
                to={""}
                onClick={handlePhotoShow}
              >
                <img
                  src={require("../assets/images/roomdetail/gallery/camera-white.png")}
                  className="img-fluid"
                />
                <span className="ms-2 f-12 view-all fw-600">
                  VIEW ALL PHOTOS
                </span>
              </Button>
            </div>
            <div className="cus-btm-head">
              <div className="container h-100 d-flex align-items-center ">
                <div className="rating_with_txt">
                  <h4 className="mb-0 title-txt">{HotelData?.hotel?.name}</h4>
                  <div className="stars">
                    {renderStars(HotelData?.hotel?.rt)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
          <div className="container-fluid px-0 roomDetail_banner px-0">
            <div className="roomHeader">
              <div className="container h-100 d-flex align-items-center ">
                <div className="rating_with_txt">
                  <h4 className="mb-0 title-txt">{HotelData?.hotel?.name}</h4>
                  <div className="stars">{renderStars(HotelData?.hotel?.rt)}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="roomDetail_banner mt-3">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-2 mb-lg-0 px-1">
                  <img src={HotelImages?.[0]?.url ?? noImg} className="img-fluid img_lftBig" />
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="row sub-image">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 col">
                      <div className="px-1 mb-2">
                        <img src={HotelImages?.[1]?.url ?? noImg} className="img-fluid img_rytSmall" />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 col">
                      <div className="px-1 mb-2">
                        <img src={HotelImages?.[2]?.url ?? noImg} className="img-fluid img_rytSmall" />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 col">
                      <div className="px-1 mb-2 mb-lg-0">
                        <img src={HotelImages?.[3]?.url ?? noImg} className="img-fluid img_rytSmall" />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 col">
                      <div
                        className="show-all-img px-1"
                        onClick={handlePhotoShow}
                      >
                        <img src={HotelImages?.[4]?.url ?? noImg} className="img-fluid img_rytSmall" />
                        <span class="show_all_btn">
                          <Button
                            className="banner-gal view_pho_lin"

                          // onClick={handlePhotoShow}
                          >
                            <img
                              src={require("../assets/images/roomdetail/gallery/camera-white.png")}
                              className="img-fluid"
                            />
                            <span className="ms-2 f-12 view-all fw-600">
                              VIEW ALL PHOTOS
                            </span>
                          </Button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-3 mt-lg-4">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-lg-8">
                <div className="best-of-luxury">
                  <div className="rating-map mt-3 mb-4">
                    <div className="row">
                      <div className="mb-3 mb-sm-0 col-12 col-sm-6 d-flex align-items-center gap-3">
                        <span className="rat">{HotelData?.hotel?.rt}</span>
                        <div className="d-flex flex-column">
                          <span className="rat-txt mb-0 fw-800 f-15 purple">
                            {Ratingsquoetes(HotelData?.hotel?.rt)}
                          </span>
                          <span className="total-rev f-13 fw-700 purple">
                            ({AllReviews?.rationgscount} Ratings)
                          </span>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <span className="d-flex align-items-center gap-3">
                          <div className="position-relative">
                            <img
                              src={require("../assets/images/roomdetail/map-bg-new.png")}
                              className="img-fluid map-bg"
                            />
                            <img
                              src={require("../assets/images/roomdetail/map-icon.png")}
                              className="img-fluid map-icon"
                            />
                          </div>
                          <div className="d-flex flex-column">
                            <span className="mb-0 fw-800 f-15">
                              {HotelData?.hotel?.ad?.city?.name}
                            </span>
                            <span
                              className="view-map f-13 fw-700"
                              onClick={handleMapShow}
                            >
                              View On Map
                            </span>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <h5 className="heading-txt fw-800 gray">
                      Discover the Best of Luxury
                    </h5>
                    <div className="row mt-3">
                      <div className="mb-3 col-12 col-sm-6 col-md-6 col-xl-4">
                        <div className="cmn-discvr discvr-bst-lux1">
                          <div className="cnt with-blur-backdrop">
                            <img
                              src={require("../assets/images/roomdetail/bst-of-lux1.png")}
                              className="img-fluid"
                            />
                            <p className="txt mt-2 mb-0 text-center">
                              Indulgent Spa Therapies
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 col-12 col-sm-6 col-md-6 col-xl-4">
                        <div className="cmn-discvr discvr-bst-lux2">
                          <div className="cnt with-blur-backdrop">
                            <img
                              src={require("../assets/images/roomdetail/bst-of-lux2.png")}
                              className="img-fluid"
                            />
                            <p className="txt mt-2 mb-0 text-center">
                              Indulgent Dining
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 col-12 col-sm-6 col-md-6 col-xl-4">
                        <div className="cmn-discvr discvr-bst-lux3">
                          <div className="cnt with-blur-backdrop">
                            <img
                              src={require("../assets/images/roomdetail/bst-of-lux3.png")}
                              className="img-fluid"
                            />
                            <p className="txt mt-2 mb-0 text-center">
                              Olympic Sized Outdoor Pool
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-5 col-lg-4 col-lg-4">
                <div className="bnrRoom-ryt-card">
                  {BookedRooms?.[0]?.ris?.[0]?.imgs?.[0].url ? (
                    <img
                      src={BookedRooms?.[0]?.ris?.[0]?.imgs?.[0]?.url}
                      className="img-fluid room-img"
                    />
                  ) : (
                    <img
                      src={require("../assets/images/noimage.png")}
                      className="img-fluid rd__tableSwiperImg rounded-2"
                      alt=""
                    />
                  )}
                  <div className="mt-3">
                    <div className="name">
                      <span className="name-txt">{HotelData?.hotel?.name}</span>
                      <span className="cryp-acc">Cryptocurrency accepted</span>
                    </div>
                    <div className="details mt-2">
                      <div className="lft-sec">
                        <div className="d-flex align-items-center gap-2">
                          <img
                            src={require("../assets/images/roomdetail/user.png")}
                            className="img-fluid"
                          />
                          <span className="f-12 fw-700">
                            For {BookedRooms?.[0]?.ris?.[0]?.ad} Adults
                          </span>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <img
                            src={require("../assets/images/roomdetail/check.png")}
                            className="img-fluid"
                          />
                          {/* {console.log("HotelData?.ops?.[0]?.ris?.[0]",HotelData?.hotel.ops?.[0]?.ris?.[0])} */}
                          <span className="f-13 fw-600">
                            {BookedRooms?.[0]?.ris?.[0]?.mb}
                          </span>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <img
                            src={require("../assets/images/roomdetail/check.png")}
                            className="img-fluid"
                          />

                          {HotelData?.hotel?.cnp?.ifra ? (
                            <span className="f-13 free-can fw-600">
                              Free cancellation available
                            </span>
                          ) : (
                            <span className="f-13 free-can fw-600">
                              Non refundable
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="ryt-sec">
                        <p className="mb-0 f-12 gray">Per Night</p>
                        {/* <p className="mb-0 f-13 text-decoration-line-through">
                          ₹{" "}
                          {parseInt(CurrencyConvert * BookedRooms?.[0]?.ris?.[0]?.tp) +
                            300}
                        </p> */}
                        <p className="mb-0 f-13 text-decoration-line-through">
                        ₹{" "}
                          {parseInt(BookedRooms?.[0]?.ris?.[0]?.tp) +
                            3}
                        </p>
                        <p className="mb-0 f-15 fw-800 amt">
                        ₹ {parseInt(BookedRooms?.[0]?.ris?.[0]?.tp)}
                        </p>
                        <p className="mb-0 f-12 gray">
                          + ₹{" "}
                          {/* {BookedRooms?.[0]?.ris?.[0]?.tfcs?.TAF?.TSF}{" "} */}
                          {parseInt(BookedRooms?.[0]?.ris?.[0]?.tfcs?.TAF)}{" "}

                          taxes & fees
                        </p>
                        {/* <p className="f-11 mb-0 fw-600 savings">Saving ₹ 3,280</p> */}
                      </div>
                    </div>
                    <Button
                      className="btn-link mb-0 mt-2 viewOther-rooms"
                      onClick={() => scrollToSection(selectRoom)}
                    >
                      VIEW OTHER ROOMS
                      {/* <IoIosArrowDown className="ms-1" /> */}
                    </Button>
                  </div>
                  <div className="book-btn mt-3">
                    <Button className="cmn-btn">
                      <Link
                        to={`/reviewBooking?hotelid=${Hotelid}&&roomid=${BookedRooms?.[0]?.ris?.[0]?.id
                          }&&index=${0}&&searchdata=${EncryptData(formdata)}`}
                      >
                        BOOK THIS NOW
                      </Link>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <Row className="justify-content-center mt-3 mt-lg-0">
              <Col lg={8}>
                <h2 className="rd__blackTitle m-0 text-center">
                  About {HotelData?.hotel?.name}
                </h2>
                <p className="m-0 rd__secondaryHint text-center mt-2">
                  {HotelData?.hotel?.des
                    ? JSON.parse(HotelData?.hotel?.des)?.amenities
                    : ""}
                </p>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={7} className="mb-3 mb-lg-0">
                <div className="rd__leftBox rounded-1 p-3">
                  {HotelData?.hotel?.des &&
                    !isEmpty(JSON.parse(HotelData?.hotel?.des).dining) && (
                      <div className="rd__leftBoxSingleFlex d-flex justify-content-start align-items-start gap-3 mb-2">
                        <span className="rd__diamond"></span>
                        <p className="m-0 rd__leftBoxHint">
                          {HotelData?.hotel?.des
                            ? JSON.parse(HotelData?.hotel?.des)?.dining
                            : ""}
                        </p>
                      </div>
                    )}

                  {HotelData?.hotel?.des &&
                    !isEmpty(
                      JSON.parse(HotelData?.hotel?.des).business_amenities
                    ) && (
                      <div className="rd__leftBoxSingleFlex d-flex justify-content-start align-items-start gap-3 mb-2">
                        <span className="rd__diamond"></span>
                        <p className="m-0 rd__leftBoxHint">
                          {HotelData?.hotel?.des
                            ? JSON.parse(HotelData?.hotel?.des)
                              ?.business_amenities
                            : ""}
                        </p>
                      </div>
                    )}

                  {HotelData?.hotel?.des &&
                    !isEmpty(JSON.parse(HotelData?.hotel?.des).rooms) && (
                      <div className="rd__leftBoxSingleFlex d-flex justify-content-start align-items-start gap-3">
                        <span className="rd__diamond"></span>
                        <p className="m-0 rd__leftBoxHint">
                          {HotelData?.hotel?.des
                            ? JSON.parse(HotelData?.hotel?.des)?.rooms
                            : ""}
                        </p>
                      </div>
                    )}
                </div>
              </Col>

              <Col lg={5}>
                <Row>
                  {HotelData?.hotel?.fl?.length > 0 &&
                    HotelData?.hotel?.fl?.slice(0, 7)?.map((item) => (
                      <Col sm={6} md={4} lg={6} xl={4} className="mb-4">
                        <div className="rd__leftBox p-2 rounded-1">
                          <div className="d-flex justify-content-between align-items-start">
                            <p className="m-0 rd__rightBoxHint">{item}</p>
                            {/* <BiRightArrowAlt className="rd__rightArrow" /> */}
                          </div>
                          <div className="rd__rightBoxImg text-end mt-2">
                            <img
                              src={Image.dining}
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                        </div>
                      </Col>
                    ))}
                </Row>
              </Col>
            </Row>
            <div className="rd__roomSelector" ref={selectRoom}>
              <h5 className="m-0 rd__secondaryMinTitle">Select Rooms</h5>

              {/* <div className="rd__roomSelectorBadgeHolder d-flex justify-content-start align-items-center gap-2 mt-3">
              <div className="rd__roomSelectorBadge p-2 rounded-2">
                <Form>
                  {["Free Cancellation"]?.map((item, index) => (
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="pm_mapping_check rd__checkLabel">
                        <Form.Check
                          className="mt-1"
                          name="group1"
                          label={item}
                          type="checkbox"
                          id={index}
                        />
                      </div>
                    </div>
                  ))}
                </Form>
              </div>

              <div className="rd__roomSelectorBadge p-2 rounded-2">
                <Form>
                  {["Breakfast Included"]?.map((item, index) => (
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="pm_mapping_check rd__checkLabel">
                        <Form.Check
                          className="mt-1"
                          name="group1"
                          label={item}
                          type="checkbox"
                          id={index}
                        />
                      </div>
                    </div>
                  ))}
                </Form>
              </div>
            </div> */}
            </div>
            <div className="room-types-filter mt-5">
              <div className="row">
                <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                  <div className="gry-bdr-fst">
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic" className="rm-tys">
                        {/* <IoIosArrowDown className="ms-1" /> */}
                        Only {BookedRooms?.length} Rooms Left
                      </Dropdown.Toggle>

                      {/* <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    </Dropdown.Menu> */}
                    </Dropdown>

                    {/* <p className="f-11 mb-0 fw-500 only-lft">
                          <img
                            src={require("../assets/images/roomdetail/fire.png")}
                            className="img-fluid me-1"
                          />
                          Only 5 Left
                        </p> */}
                  </div>
                </div>
                <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                  <p className="mb-0 opt-pri">Options</p>
                </div>
                <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                  <div className="gry-bdr-snd">
                    <p className="mb-0 opt-pri price">Price</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="all_roomList">
              {/* {HotelData?.hotel?.ops?.length > 0 &&
              HotelData?.hotel?.ops?.slice(0,roomloadlimit).map((item, index) => ( */}

              {BookedRooms?.length > 0 &&
                BookedRooms?.slice(0, roomloadlimit)?.map((item, index) => (
                  <div className="rd__tablerHolder">
                    <div>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                          <div className="p-2 cus-gry-bdr-fst">
                            <div className="rd__tablerSwiperHolder">
                              <Swiper
                                navigation={true}
                                modules={[Navigation, Autoplay]}
                                autoplay={true}
                                className="mySwiper rounded-2"
                              >
                                {item.ris[0].imgs?.length > 0 ? (
                                  item.ris[0].imgs?.map((item, index) => (

                                    <SwiperSlide>


                                      <img
                                        src={item?.url}
                                        className="img-fluid rd__tableSwiperImg rounded-2"
                                        alt=""
                                      />
                                    </SwiperSlide>
                                  ))
                                ) : (
                                  <>
                                    <img
                                      src={require("../assets/images/noimage.png")}
                                      className="img-fluid rd__tableSwiperImg rounded-2"
                                      alt=""
                                    />
                                  </>
                                )}
                              </Swiper>
                              <h4 className="m-0 rd__lowerTitle mt-3">
                                {HotelData?.hotel?.name}
                              </h4>
                              <div className="d-flex justify-content-start align-items-center gap-2 mt-2">
                                <img
                                  src={Image.window}
                                  className="img-fluid rd__tableFirstColWindow"
                                  alt=""
                                />
                                <p className="m-0 rd__tablesSecMinLabel">
                                  {item?.ris[0]?.srn}
                                </p>
                              </div>

                              <div className="d-flex justify-content-start align-items-center gap-2 mt-2">
                                <img
                                  src={Image.bed}
                                  className="img-fluid rd__tableFirstColWindow"
                                  alt=""
                                />
                                <p className="m-0 rd__tablesSecMinLabel">
                                  {item?.ris[0]?.rt}
                                </p>
                              </div>

                              <div className="rd__ulHolder mt-3">
                                <ul className="d-flex gap-2">
                                  {item?.ris[0]?.fcs.map((item) => (
                                    <li className="me-3">{item}</li>
                                  ))}
                                </ul>
                              </div>
                              {item?.ris[0]?.des && (
                                <Button
                                  className="m-0 moreDetailsBtn mt-3 btn-link"
                                  onClick={() =>
                                    handleAmenitiesShow(
                                      item?.ris[0]?.des,
                                      "Fecilites"
                                    )
                                  }
                                >
                                  More Details
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 px-unset px-sm-3 px-md-0">
                          <div className="room-reccommended px-2 py-2">
                            <button className="rd__recommendBadge rounded-5 text-uppercase">
                              recommended
                            </button>
                            <p className="mb-2 mt-3 rm-cncl">
                              {item?.ris?.[0]?.mb}{" "}
                            </p>
                            <p className="mb-1">
                              <img
                                src={require("../assets/images/roomdetail/breakfast.png")}
                                className="img-fluid"
                              />
                              <span className="ms-2 free-bft">
                                {item?.ris?.[0]?.mb}
                              </span>
                            </p>
                            {
                              <p className="free-cnl mb-1">
                                <FaCheck size={11} />
                                {item?.cnp?.ifra == true ? (
                                  <span className="ps-3">
                                    Free Cancellation Available
                                  </span>
                                ) : (
                                  <span className="ps-3">
                                    Non refundable
                                  </span>
                                )}
                              </p>
                            }
                            <p className="approx mb-1">
                              <img
                                src={require("../assets/images/roomdetail/coin.png")}
                                className="img-fluid"
                              />
                              <span className="ms-2 free-bft">
                                {/* Approx. US$16 giveback in $Trip */}
                              </span>
                            </p>

                            <p className="mb-1 mt-2 f-12 black fw-500">
                              Payment:
                            </p>
                            <p className="mb-1 f-12 fw-400 pmt-types">
                              <FaCheck size={11} />
                              <span className="ps-2">Pay with Credit card</span>
                            </p>
                            <p className="mb-1 f-12 fw-400 pmt-types">
                              <FaCheck size={11} />
                              <span className="ps-2">Pay with Crypto</span>
                            </p>
                            {/* <Button className="mx-0 search__showMore mt-4 text-uppercase no_dec d-block"   onClick={(data)=>handleInclusionShow({ hotelid:HotelData?.hotel?.id,
optionid:HotelData?.hotel?.ops?.[0]?.id}
                                 )}>
                      More Details
                    </Button> */}
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                          <div className="h-100 exclusive-offer cus-gry-bdr-snd pb-2">
                            {/* <div className="ofr">
                    Exclusive Offer on SBI Credit Cards. Get INR 1083 Off. Avail
                    No-Cost EMI starting from ₹1,276 per month
                    <p className="mb-0 slct-avil">SELECT TO AVAIL</p>
                  </div> */}

                            <div className="px-2 pt-2">
                              <div className="row">
                                <div className="col-6 col-xl-6">
                                  <p className="mb-0 f-11 per-night">Per Night</p>
                                  <p className="mb-0 f-10 fw-700 per-night text-decoration-line-through">
                                    {/* {parseInt(item?.tp) + 300} */}
                                  </p>
                                  <p className="mb-0 f-14 fw-700 amt">
                                    {" "}
                                    ₹{parseInt(item?.tp)}
                                  </p>
                                  <p className="mb-1 f-13 fw-700 amt">
                                    +₹ {parseInt(item?.ris?.[0]?.tafcs?.TAF?.TSF)} taxes &
                                    fees
                                  </p>
                                  <p className="f-11 mb-0 fw-500 cryp-acc">
                                    Cryptocurrency accepted
                                  </p>
                                  {/* <p className="f-11 mb-0 fw-500 only-lft">
                          <img
                            src={require("../assets/images/roomdetail/fire.png")}
                            className="img-fluid me-1"
                          />
                          Only 5 Left
                        </p> */}
                                </div>
                                <div className="col-6 col-xl-6 d-flex align-items-center justify-content-center">
                                  <Link
                                    to={`/reviewBooking?hotelid=${Hotelid}&&roomid=${item?.ris?.[0].id
                                      }&&index=${index}&&searchdata=${EncryptData(
                                        formdata
                                      )}`}
                                    className="cmn-btn select-room-btn"
                                  >
                                    SELECT ROOM
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {BookedRooms?.length > roomloadlimit &&
                <div className="d-flex align-items-center justify-content-center mt-3">
                  <Button className="cmn-btn f-15 fw-600 px-3 py-2" style={{ fontWeight: '700' }} onClick={LoadMore}>Load More</Button>
                </div>}
            </div>
            <div className="location mt-3 mb-4" ref={selectLocation}>
              <h5 className="fw-700">Location</h5>
              <p className="f-15 fw-600 loc-desc">
                {/* The Location of Heritage Madurai has been rated 4.5 by 1604 guests */}
              </p>
              <div className="maps_sec loca-accor">
                <div className="row">
                  <div className="col-xl-4 col-lg-4 mt-4 pe-lg-0 mb-3 mb-lg-0">
                    {/* <div className="loc-tabs">
                    <h6 className="mb-3 fw-800 ps-3">Around this property:</h6>
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Key Landmarks</Accordion.Header>
                        <Accordion.Body>
                          <div className="loc-tol-acc">
                            <div className="loc-acc-body d-flex gap-2">
                              <Form.Check // prettier-ignore
                                type="checkbox"
                              />
                              <div>
                                <p className="mb-0 f-14 fw-700">
                                  Periyar Bus Stand
                                </p>
                                <p className="mb-0 fw-500 f-11 km">4 km</p>
                              </div>
                            </div>
                            <hr className="cus-loc-acc-hr my-2" />
                            <div className="loc-acc-body d-flex gap-2">
                              <Form.Check // prettier-ignore
                                type="checkbox"
                              />
                              <div>
                                <p className="mb-0 f-14 fw-700">
                                  Meenakshi Amman Temple
                                </p>
                                <p className="mb-0 fw-500 f-11 km">6.4 km</p>
                              </div>
                            </div>
                            <hr className="cus-loc-acc-hr my-1" />
                            <div className="loc-acc-body d-flex gap-2">
                              <Form.Check // prettier-ignore
                                type="checkbox"
                              />
                              <div>
                                <p className="mb-0 f-14 fw-700">
                                  Arapalayam Bus Stand
                                </p>
                                <p className="mb-0 fw-500 f-11 km">3.1 km</p>
                              </div>
                            </div>
                            <hr className="cus-loc-acc-hr my-1" />
                            <div className="loc-acc-body d-flex gap-2">
                              <Form.Check // prettier-ignore
                                type="checkbox"
                              />
                              <div>
                                <p className="mb-0 f-14 fw-700">
                                  Madurai Airport
                                </p>
                                <p className="mb-0 fw-500 f-11 km">15 km</p>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Tourist Attractions</Accordion.Header>
                        <Accordion.Body>
                          <div className="loc-tol-acc">
                            <div className="loc-acc-body d-flex gap-2">
                              <Form.Check // prettier-ignore
                                type="checkbox"
                              />
                              <div>
                                <p className="mb-0 f-14 fw-700">
                                  Periyar Bus Stand
                                </p>
                                <p className="mb-0 fw-500 f-11 km">4 km</p>
                              </div>
                            </div>
                            <hr className="cus-loc-acc-hr my-2" />
                            <div className="loc-acc-body d-flex gap-2">
                              <Form.Check // prettier-ignore
                                type="checkbox"
                              />
                              <div>
                                <p className="mb-0 f-14 fw-700">
                                  Meenakshi Amman Temple
                                </p>
                                <p className="mb-0 fw-500 f-11 km">6.4 km</p>
                              </div>
                            </div>
                            <hr className="cus-loc-acc-hr my-1" />
                            <div className="loc-acc-body d-flex gap-2">
                              <Form.Check // prettier-ignore
                                type="checkbox"
                              />
                              <div>
                                <p className="mb-0 f-14 fw-700">
                                  Arapalayam Bus Stand
                                </p>
                                <p className="mb-0 fw-500 f-11 km">3.1 km</p>
                              </div>
                            </div>
                            <hr className="cus-loc-acc-hr my-1" />
                            <div className="loc-acc-body d-flex gap-2">
                              <Form.Check // prettier-ignore
                                type="checkbox"
                              />
                              <div>
                                <p className="mb-0 f-14 fw-700">
                                  Madurai Airport
                                </p>
                                <p className="mb-0 fw-500 f-11 km">15 km</p>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>Airport</Accordion.Header>
                        <Accordion.Body>
                          <div className="loc-tol-acc">
                            <div className="loc-acc-body d-flex gap-2">
                              <Form.Check // prettier-ignore
                                type="checkbox"
                              />
                              <div>
                                <p className="mb-0 f-14 fw-700">
                                  Periyar Bus Stand
                                </p>
                                <p className="mb-0 fw-500 f-11 km">4 km</p>
                              </div>
                            </div>
                            <hr className="cus-loc-acc-hr my-2" />
                            <div className="loc-acc-body d-flex gap-2">
                              <Form.Check // prettier-ignore
                                type="checkbox"
                              />
                              <div>
                                <p className="mb-0 f-14 fw-700">
                                  Meenakshi Amman Temple
                                </p>
                                <p className="mb-0 fw-500 f-11 km">6.4 km</p>
                              </div>
                            </div>
                            <hr className="cus-loc-acc-hr my-1" />
                            <div className="loc-acc-body d-flex gap-2">
                              <Form.Check // prettier-ignore
                                type="checkbox"
                              />
                              <div>
                                <p className="mb-0 f-14 fw-700">
                                  Arapalayam Bus Stand
                                </p>
                                <p className="mb-0 fw-500 f-11 km">3.1 km</p>
                              </div>
                            </div>
                            <hr className="cus-loc-acc-hr my-1" />
                            <div className="loc-acc-body d-flex gap-2">
                              <Form.Check // prettier-ignore
                                type="checkbox"
                              />
                              <div>
                                <p className="mb-0 f-14 fw-700">
                                  Madurai Airport
                                </p>
                                <p className="mb-0 fw-500 f-11 km">15 km</p>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>Other Landmarks</Accordion.Header>
                        <Accordion.Body>
                          <div className="loc-tol-acc">
                            <div className="loc-acc-body d-flex gap-2">
                              <Form.Check // prettier-ignore
                                type="checkbox"
                              />
                              <div>
                                <p className="mb-0 f-14 fw-700">
                                  Periyar Bus Stand
                                </p>
                                <p className="mb-0 fw-500 f-11 km">4 km</p>
                              </div>
                            </div>
                            <hr className="cus-loc-acc-hr my-2" />
                            <div className="loc-acc-body d-flex gap-2">
                              <Form.Check // prettier-ignore
                                type="checkbox"
                              />
                              <div>
                                <p className="mb-0 f-14 fw-700">
                                  Meenakshi Amman Temple
                                </p>
                                <p className="mb-0 fw-500 f-11 km">6.4 km</p>
                              </div>
                            </div>
                            <hr className="cus-loc-acc-hr my-1" />
                            <div className="loc-acc-body d-flex gap-2">
                              <Form.Check // prettier-ignore
                                type="checkbox"
                              />
                              <div>
                                <p className="mb-0 f-14 fw-700">
                                  Arapalayam Bus Stand
                                </p>
                                <p className="mb-0 fw-500 f-11 km">3.1 km</p>
                              </div>
                            </div>
                            <hr className="cus-loc-acc-hr my-1" />
                            <div className="loc-acc-body d-flex gap-2">
                              <Form.Check // prettier-ignore
                                type="checkbox"
                              />
                              <div>
                                <p className="mb-0 f-14 fw-700">
                                  Madurai Airport
                                </p>
                                <p className="mb-0 fw-500 f-11 km">15 km</p>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div> */}
                  </div>
                  <div className="col-xl-8 col-lg-8  ps-lg-0">
                    <div className="cus-ryt-map">
                      <div className="cus-map">
                        {HotelData && (
                          <MapContainer
                            containerElement={<div style={{ height: `400px` }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                          />
                        )}
                      </div>
                      {/* <div className="custom-search">
                      <div className="srch-inp">
                        <input
                          type="text"
                          className=""
                          placeholder="Search for landmarks, locality or property"
                        />
                        <IoSearch className="srch-icon" />
                      </div>
                    </div> */}
                      {/* <div className="map-fltr">
                      <div className="d-flex align-items-center cmn-pd cmn-check">
                        <Form.Check type="checkbox" />
                        <span className="cmn-txt ms-2">
                          Show Nearby Properties
                        </span>
                      </div>
                      <div className="d-flex align-items-center cmn-pd pdr">
                        <span className="cmn-txt ms-2">Recenter</span>
                      </div>
                      <div className="d-flex align-items-center cmn-pd">
                        <span className="cmn-txt ms-2">Enter Full Screen</span>
                      </div>
                    </div> */}
                      {/* <div className="robo-logo">
                      <img
                        src={require("../assets/images/roomdetail/map-robo.png")}
                        className="img-fluid"
                      />
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sig-amen-exp mb-4" ref={selectAmenties}>
              {/* <h5 className="fw-700">Signature Amenities and Experiences</h5> */}
              {/* <div className="row mt-3">
              <div className="col-xl-4">
                <div className="cus-card">
                  <img
                    src={require("../assets/images/roomdetail/Fitness.png")}
                    className="img-fluid"
                  />
                  <span className="name">Well-equipped Gym</span>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="cus-card">
                  <img
                    src={require("../assets/images/roomdetail/Expansive.png")}
                    className="img-fluid"
                  />
                  <span className="name">Classy Event Venues</span>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="cus-card">
                  <img
                    src={require("../assets/images/roomdetail/Fitness.png")}
                    className="img-fluid"
                  />
                  <span className="name">Well-equipped Gym</span>
                </div>
              </div>
            </div> */}
              {/* <div className="ament-cus-swipper mt-3">
              <Swiper
                spaceBetween={50}
                slidesPerView={3}
                navigation={true}
                // centeredSlides={true}
                modules={[Navigation]}
                className="mySwiper"
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                breakpoints={{
                  400: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  600: {
                    slidesPerView: 2,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  850: {
                    slidesPerView: 4,
                  },
                  1000: {
                    slidesPerView: 5,
                  },
                  1200: {
                    slidesPerView: 3,
                  },
                  1400: {
                    slidesPerView: 4,
                  },
                }}
              >
                <SwiperSlide>
                  <div className="cus-card">
                    <img
                      src={require("../assets/images/roomdetail/Fitness.png")}
                      className="img-fluid"
                    />
                    <span className="name">Well-equipped Gym</span>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="cus-card">
                    <img
                      src={require("../assets/images/roomdetail/Expansive.png")}
                      className="img-fluid"
                    />
                    <span className="name">Classy Event Venues</span>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="cus-card">
                    <img
                      src={require("../assets/images/roomdetail/Fitness.png")}
                      className="img-fluid"
                    />
                    <span className="name">Well-equipped Gym</span>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="cus-card">
                    <img
                      src={require("../assets/images/roomdetail/Expansive.png")}
                      className="img-fluid"
                    />
                    <span className="name">Classy Event Venues</span>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div> */}

              {/* <div className="">
              <Slider {...Amenties} className="ament-cus-slider mt-3">
                <div className="cus-card">
                  <img
                    src={require("../assets/images/roomdetail/Fitness.png")}
                    className="img-fluid"
                  />
                  <span className="name">Well-equipped Gym</span>
                </div>
                <div className="cus-card">
                  <img
                    src={require("../assets/images/roomdetail/Expansive.png")}
                    className="img-fluid"
                  />
                  <span className="name">Classy Event Venues</span>
                </div>
                <div className="cus-card">
                  <img
                    src={require("../assets/images/roomdetail/Fitness.png")}
                    className="img-fluid"
                  />
                  <span className="name">Well-equipped Gym</span>
                </div>
                <div className="cus-card">
                  <img
                    src={require("../assets/images/roomdetail/Expansive.png")}
                    className="img-fluid"
                  />
                  <span className="name">Classy Event Venues</span>
                </div>
              </Slider>
            </div> */}
            </div>
            <div className="amen-heri mb-4">
              <h5 className="fw-800">{HotelData?.hotel?.name}</h5>
              {/* <div className="features mt-3">
              <div className="d-flex align-items-center gap-2 gap-lg-3">
                <img
                  src={require("../assets/images/roomdetail/feature/swimming.png")}
                  className="img-fluid"
                />
                <span className="name f-15 fw-600">Swimming Pool</span>
              </div>

              <div className="d-flex align-items-center gap-2 gap-lg-3">
                <img
                  src={require("../assets/images/roomdetail/feature/spa.png")}
                  className="img-fluid"
                />
                <span className="name f-15 fw-600">Spa</span>
              </div>

              <div className="d-flex align-items-center gap-2 gap-lg-3">
                <img
                  src={require("../assets/images/roomdetail/feature/restaurant.png")}
                  className="img-fluid"
                />
                <span className="name f-15 fw-600">Restaurant</span>
              </div>

              <div className="d-flex align-items-center gap-2 gap-lg-3">
                <img
                  src={require("../assets/images/roomdetail/feature/games.png")}
                  className="img-fluid"
                />
                <span className="name f-15 fw-600">Indoor Games</span>
              </div>

              <div className="d-flex align-items-center gap-2 gap-lg-3">
                <img
                  src={require("../assets/images/roomdetail/feature/kidsplay.png")}
                  className="img-fluid"
                />
                <span className="name f-15 fw-600">Kids Play Area</span>
              </div>
            </div> */}
              <hr className="mt-3 mb-3" />

              <div className="featu-lists">

                <div>
                  <h6 className="heading-txt fw-700">Highlighted Amenities</h6>
                  <ul>
                    {HotelData?.hotel?.fl?.length > 0 &&
                      HotelData?.hotel?.fl
                        ?.slice(0)
                        .map((data) => <li>- {data} </li>)}
                  </ul>
                </div>

                {/* <div>
                <h6 className="heading-txt fw-700">Basic Facilities</h6>
                <ul>
                  <li>- Swimming Pool</li>
                  <li>- Smoke Detector</li>
                  <li>- Room Service</li>
                  <li>- Power Backup</li>
                </ul>
              </div> */}
                {/* <div>
                <h6 className="heading-txt fw-700">Transfers</h6>
                <ul>
                  <li>- Paid Shuttle Service</li>
                  <li>- Paid Airport Transfers</li>
                </ul>
              </div> */}
                {/* <div>
                <h6 className="heading-txt fw-700">Family and kids</h6>
                <ul>
                  <li>- Kids Play Area</li>
                  <li>- Childcare Services</li>
                </ul>
              </div> */}
                {/* <div>
                <h6 className="heading-txt fw-700">Food and Drinks</h6>
                <ul>
                  <li>- Restaurant</li>
                  <li>- Bar</li>
                  <li>- Cafe</li>
                  <li>- Dining Area</li>
                </ul>
              </div> */}
              </div>
              <Button
                className="fw-800 all-ament btn-link"
                onClick={() =>
                  handleAmenitiesShow(HotelData?.hotel?.fl, "Amenities")
                }
              >
                ALL {HotelData?.hotel?.fl?.length} AMENITIES
              </Button>
            </div>
            <div className="amen-heri mb-4">

              {HotelData?.hotel?.inst?.length > 0 ? (
                <div className="propty-rls mb-4" ref={selectRule}>
                  <h5 className="fw-800">Property Rules</h5>
                  {HotelData?.hotel?.inst?.map((item) => (
                    item?.type == 'POLICIES' &&
                    <>
                      <p className="checkin fw-600 mt-2 mb-2">
                        <span className="fw-800">{item.type}</span>
                      </p>

                      <div className="row">
                        <div className="col-xl-6 col-lg-6">
                          <ul>
                            {Object.values(JSON.parse(item.msg)).map((item) => (
                              <li>{item}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              ) : (
                <NodataFound />
              )}
              <Button
                className="fw-800 all-ament btn-link"
                onClick={() =>
                  handleInformationShow()
                }
              >
                View More
              </Button>
            </div>


            <div className="photos-gal mb-4">
              <h5 className="fw-800">Photos by Guests</h5>
              <div className="row mt-3">
                <div className="col-12 col-lg-6 col-xl-6 mb-2">
                  <div className="lft-big-img">
                    <img
                      src={
                        AllReviews?.images.length > 0
                          ? `${config.IMG_URL}/hotels/reviewimages/${AllReviews?.images?.[0]}`
                          : require("../assets/images/roomdetail/gallery/ryt-sml2.png")
                      }
                      className="img-fluid big-img cmn-brd"
                    />
                    <Button className="gst-pts" onClick={handleReviewPhotoShow}>
                      <img
                        //  src={AllReviews?.images.length>=1?`${config.IMG_URL}/reviewimages/${AllReviews?.images?.[1]}`:require("../assets/images/roomdetail/gallery/ryt-sml2.png")}
                        className="img-fluid"
                      />
                      <span className="ms-2 fw-700 purple">
                        {AllReviews?.images?.length} GUEST PHOTOS
                      </span>
                    </Button>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-6">
                  <div className="row">
                    <div className="col-xl-12 mb-2 px-auto ps-lg-1">
                      <div className="ryt-big-img">
                        <img
                          src={
                            AllReviews?.images.length > 1
                              ? `${config.IMG_URL}/hotels/reviewimages/${AllReviews?.images?.[1]}`
                              : require("../assets/images/roomdetail/gallery/ryt-sml2.png")
                          }
                          className="img-fluid cmn-brd"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-xl-6 px-auto pe-sm-1 mb-2 ps-lg-1">
                      <div className="ryt-sml-img cmn-brd">
                        <img
                          src={
                            AllReviews?.images.length > 2
                              ? `${config.IMG_URL}/hotels/reviewimages/${AllReviews?.images?.[2]}`
                              : require("../assets/images/roomdetail/gallery/ryt-sml2.png")
                          }
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-xl-6 ps-sm-1 px-auto">
                      <div className="ryt-sml-img cmn-brd">
                        <img
                          src={
                            AllReviews?.images.length > 3
                              ? `${config.IMG_URL}/hotels/reviewimages/${AllReviews?.images?.[3]}`
                              : require("../assets/images/roomdetail/gallery/ryt-sml2.png")
                          }
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* )
            } */}
            </div>
            <div className="usr-rat-rev mb-4" ref={selectReviews}>
              <div className="header-sec">
                <div>
                  <h5 className="fw-800 gray">User Ratings & Reviews</h5>
                  <p className="mb-0 gray fw-500 f-13">
                    Important information that you need to know before you book!
                  </p>
                </div>
                <Button
                  className="cmn-btn addReviewBtn"
                  onClick={handleAddReviewShow}
                >
                  Add Review
                </Button>
              </div>

              <div className="cus-srch mt-3">
                <div className={`cmn-tbs ${Tabname == 'All' ? "active" : ""}`} >
                  <span className="txt" onClick={() => SetTabname('All')}>ALL</span>
                </div>
                <div className={`cmn-tbs ${Tabname == 'Couple' ? "active" : ""}`}>
                  <span className="txt" onClick={() => SetTabname('Couple')} >COUPLE</span>
                </div>
                <div className={`cmn-tbs ${Tabname == 'Family' ? "active" : ""}`}>
                  <span className="txt" onClick={() => SetTabname('Family')}>FAMILY</span>
                </div>
                <div className={`cmn-tbs ${Tabname == 'BUSINESS' ? "active" : ""}`}>
                  <span className="txt" onClick={() => SetTabname('Buisness')}>BUSINESS</span>
                </div>
                <div className={`cmn-tbs ${Tabname == 'GROUP' ? "active" : ""}`}>
                  <span className="txt" onClick={() => SetTabname('Group')}>GROUP</span>
                </div>
                <div className={`cmn-tbs ${Tabname == 'SOLO' ? "active" : ""}`}>
                  <span className="txt" onClick={() => SetTabname('Solo')}>SOLO</span>
                </div>
              </div>
              { }
              <div className="rtng-all mt-3">
                <div className="row">
                  <div className="col-lg-4 mb-3">
                    <div className="lft-sec">
                      <span className="rt">{Reviewstars}</span>
                      <div className="">
                        <p className="f-15 fw-800 purple mb-0">
                          {Ratingsquoetes(Reviewstars)}
                        </p>
                        <p className="f-13 fw-500 gray mb-0">
                          <span className="fw-800">
                            {ReviewsandRatings[Tabname]?.list?.length > 0
                              ? ReviewsandRatings[Tabname]?.list?.length
                              : 0}
                          </span>{" "}
                          User Reviews
                          {/* <span className="fw-800"> 1604</span> Ratings */}
                        </p>
                      </div>
                    </div>
                  </div>
                  {ReviewsandRatings[Tabname]?.list?.length > 0 && (
                    <div className="col-lg-8">
                      <div className="rat-pro-full">
                        <div className="rat-progress mb-2">
                          <p className="mb-1 d-flex align-items-center justify-content-between">
                            <span className="fw-600 f-12">Location</span>
                            <span className="fw-600 f-12">
                              {parseInt(Overallreviews?.Location)}
                            </span>
                          </p>
                          <ProgressBar
                            now={parseInt(Overallreviews?.Location) * 20}
                          />
                        </div>
                        <div className="rat-progress mb-2">
                          <p className="mb-1 d-flex align-items-center justify-content-between">
                            <span className="fw-600 f-12">Room</span>
                            <span className="fw-600 f-12">
                              {" "}
                              {parseInt(Overallreviews?.Room)}
                            </span>
                          </p>
                          <ProgressBar
                            now={parseInt(Overallreviews?.Room) * 20}
                          />
                        </div>
                        <div className="rat-progress mb-2">
                          <p className="mb-1 d-flex align-items-center justify-content-between">
                            <span className="fw-600 f-12">Food</span>
                            <span className="fw-600 f-12">
                              {" "}
                              {parseInt(Overallreviews?.Food)}
                            </span>
                          </p>
                          <ProgressBar
                            now={parseInt(Overallreviews?.Food) * 20}
                          />
                        </div>
                        <div className="rat-progress mb-2">
                          <p className="mb-1 d-flex align-items-center justify-content-between">
                            <span className="fw-600 f-12">Amenities</span>
                            <span className="fw-600 f-12">
                              {" "}
                              {parseInt(Overallreviews?.Amenities)}
                            </span>
                          </p>
                          <ProgressBar
                            now={parseInt(Overallreviews?.Amenities) * 20}
                          />
                        </div>
                        <div className="rat-progress mb-2">
                          <p className="mb-1 d-flex align-items-center justify-content-between">
                            <span className="fw-600 f-12">Hospitality</span>
                            <span className="fw-600 f-12">
                              {" "}
                              {parseInt(Overallreviews?.Hospitality)}
                            </span>
                          </p>
                          <ProgressBar
                            now={parseInt(Overallreviews?.Hospitality) * 20}
                          />
                        </div>
                        <div className="rat-progress">
                          <p className="mb-1 d-flex align-items-center justify-content-between">
                            <span className="fw-600 f-12">Cleanliness</span>
                            <span className="fw-600 f-12">
                              {" "}
                              {parseInt(Overallreviews?.Cleanliness)}
                            </span>
                          </p>
                          <ProgressBar
                            now={parseInt(Overallreviews?.Cleanliness) * 20}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <hr className="my-2" />
                {/* <p className="mb-0 f-12 gray fw-500 mt-3">
                Last 10 Customer Ratings (Latest Rating First)
              </p>
              <div className="cus-rating-box mt-2">
                <span className="cnt">5</span>
                <span className="cnt">5</span>
                <span className="cnt">5</span>
                <span className="cnt">3</span>
                <span className="cnt">5</span>
                <span className="cnt">3</span>
                <span className="cnt">5</span>
                <span className="cnt">4</span>
                <span className="cnt">5</span>
                <span className="cnt">5</span>
              </div> */}
                {/* <div className="traval-imgpression mt-3">
                <div className="header-sec d-flex align-items-center gap-2">
                  <img
                    src={require("../assets/images/roomdetail/robo.png")}
                    className="img-fluid robo"
                  />
                  <div className="">
                    <h6 className="mb-0 fw-800 ">Traveller Impressions</h6>
                    <p className="mb-0 fw-600 gray f-13">
                      Powered by Generative AI using recent user reviews
                    </p>
                  </div>
                </div>
                <p className="mt-3 f-13 fw-600 gray">
                  Heritage Madurai is a beautiful property with lush greenery
                  and well-maintained rooms. The staff is courteous and
                  attentive, going above and beyond to ensure a comfortable
                  stay. The food is delectable with a good spread of options.
                  The property offers a serene and peaceful environment, perfect
                  for a relaxing getaway. The private plunge pool villas are a
                  highlight, though some guests have reported issues with
                  cleanliness. Overall, a great option for those looking for a
                  luxurious stay in Madurai.
                </p>
                <div className="">
                  <div className="row">
                    <div className="col-lg-9 mb-2">
                      <div className="filter-by">
                        <span className="f-12 gray fw-800 text-nowrap">
                          Filter By:
                        </span>
                        <div className="fil-list">
                          <p className="txt mb-0">All Reviews</p>
                          <p className="txt mb-0">Food</p>
                          <p className="txt mb-0">Good Ambience</p>
                          <p className="txt mb-0">Delicious Food</p>
                          <p className="txt mb-0">Space in Rooms</p>
                          <p className="txt mb-0">Service Quality</p>
                          <p className="txt mb-0">Impeccable Service</p>
                          <p className="txt mb-0">Good Room Service</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 d-flex align-items-center justify-content-end">
                      <div className="srt-by">
                        <span className="gray text-nowrap fw-800 f-13">
                          Sort By:
                        </span>
                        <Form.Select>
                          <option>Latest First</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="mt-3 mb-0" />
              </div> */}
              </div>
            </div>
            <div className="gust-reviews mt-4 mb-4">
              <h6 className="f-16 fw-800 gray">Guest Reviews</h6>
              {ReviewsandRatings[Tabname]?.list?.slice(loadlimit - 10, loadlimit)
                .length > 0 ? (
                ReviewsandRatings[Tabname]?.list
                  ?.slice(loadlimit - 10, loadlimit)
                  .map((item, index) => {
                    return (
                      <div className="cus-card mb-3">
                        <p className="mb-0 fw-800 gray">{item.review}</p>
                        <p className="mb-0 fw-500 f-14 gray">
                          <span className="fw-700">Rated</span>
                          <span className="cnt mx-1">
                            {AverageRatings(Object?.values(item?.Ratings))}
                          </span>
                          by{" "}
                          {Name_showing(
                            item?.Users[0]?.FirstName + item?.Users[0]?.LastName
                          )}{" "}
                          {item?.Type}
                          {DateTimeForm({ date: item?.createdAt, words: true })}
                        </p>
                        <p className="mb-0 gray fw-500 f-14 gray">
                          {item.Reviews}
                        </p>
                        <div className="mt-2 mb-3 reviews-pto-list">
                          {item?.Images?.map((val) => (
                            <img
                              src={`${config.IMG_URL}/hotels/userreview/${item.Userid}/${val}`}
                              className="img-fluid rvw-img"
                            />
                          ))}
                        </div>
                        {/* <p className="mb-0 do-u-lik d-flex align-items-center fw-600 f-15">
                    <span className="fw-500 gray">
                      Do you find this helpful?
                    </span>
                    <FaRegThumbsUp className="ms-2 thumb" />
                  </p> */}
                      </div>
                    );
                  })
              ) : (
                <NodataFound />
              )}

              {ReviewsandRatings[Tabname]?.list.length > 0 && (
                <div className="my-3 review-pagi">
                  <span className="cnt-pv-nxt">
                    <MdKeyboardDoubleArrowLeft />
                  </span>
                  <span
                    className={`cnt-pv-nxt ${active == 1 ? "active" : ""}`}
                    onClick={(e) => {
                      setloadlimit(10);
                      setActive(1);
                    }}
                  >
                    1
                  </span>
                  <span
                    className={`cnt-pv-nxt ${active == 2 ? "active" : ""}`}
                    onClick={(e) => {
                      setloadlimit(20);
                      setActive(2);
                    }}
                  >
                    2
                  </span>
                  <span
                    className={`cnt-pv-nxt ${active == 3 ? "active" : ""}`}
                    onClick={(e) => {
                      setloadlimit(30);
                      setActive(3);
                    }}
                  >
                    3
                  </span>
                  <span
                    className={`cnt-pv-nxt ${active == 4 ? "active" : ""}`}
                    onClick={(e) => {
                      setloadlimit(40);
                      setActive(4);
                    }}
                  >
                    4
                  </span>
                  <span
                    className={`cnt-pv-nxt ${active == 5 ? "active" : ""}`}
                    onClick={(e) => {
                      setloadlimit(50);
                      setActive(5);
                    }}
                  >
                    5
                  </span>
                  <span
                    className={`cnt-pv-nxt ${active == 6 ? "active" : ""}`}
                    onClick={(e) => {
                      setloadlimit(60);
                      setActive(6);
                    }}
                  >
                    6
                  </span>
                  <span className={`cnt-pv-nxt `}>
                    <MdKeyboardDoubleArrowRight />
                  </span>
                </div>
              )}
            </div>

            {AllHotelsdata?.length > 0 &&
              <div className="similar-properties mb-4" ref={selectProperties}>
                <h5 className="heading-txt gray fw-700">Similar Properties</h5>
                <Slider
                  {...SimilarPropsettings}
                  className="similar-properties-slick"
                >

                  {AllHotelsdata?.filter(((val) => Hotelid.split('-')[1] !== val?.id.split('-')[1])).slice(0, 7).map((item, index) => {
                    return (
                      <div
                        className={

                          "active mt-4 simi-pro-card"

                        }
                      >
                        <img
                          src={item?.img[0]?.url ? item?.img[0]?.url : ""}
                          className="img-fluid room-img"
                        />
                        <div className="text-center">
                          <div className="mt-3 card-des">
                            <p className="f-14 fw-700 mb-0">{item.name}</p>
                            <div className="stars">
                              <IoMdStar className="star-fill" />
                              <IoMdStar className="star-fill" />
                              <IoMdStar className="star-fill" />
                              <IoMdStar className="star-fill" />
                              <IoMdStar className="star-non-fill" />
                            </div>
                            <p className="amt mb-0"> ₹ {parseInt(item?.pops[0]?.tpc)}</p>

                            <p className="Per-Night mb-0">Per Night</p>

                            {item.active ? (
                              <p className="crytp-acptd mb-0">
                                Cryptocurrency accepted
                              </p>
                            ) : (
                              <></>
                            )}
                            {
                              item.active ?
                                <Button
                                  className={
                                    "active view-details my-3"

                                  }
                                  onClick={() => scrollToSection(selectRoom)}
                                >
                                  Select stay
                                </Button>
                                :
                                <Link to={`/roomDetails?hotelid=${item.id}`}
                                  target="_blank"
                                  className={

                                    "view-details my-3"
                                  }
                                >
                                  View Details
                                </Link>

                            }

                          </div>

                          <hr className="hr-btm-bdr my-0" />
                          <div className="my-3 d-flex justify-content-center align-items-center gap-2">
                            <span className="cmn-rvw">{item.rt}</span>
                            <span className="purple fw-800 f-14">
                              {Ratingsquoetes(item?.rt)}
                            </span>
                          </div>
                          <hr className="hr-btm-bdr my-0" />
                          <p className="mb-0 py-4 fw-600 gray gray-bg f-14">
                            {item?.pt}
                          </p>
                          <hr className="hr-btm-bdr my-0" />
                          <p className="mb-0 py-4 fw-600 gray f-14">
                            {item?.ad?.adr}{" "}
                          </p>
                          {/* <hr className="hr-btm-bdr my-0" />
                          <p className="mb-0 py-4 fw-600 gray gray-bg f-14">
                            {item?.ops[0].roomsonly}
                          </p> */}
                          <hr className="hr-btm-bdr my-0" />
                          <p className="mb-0 py-4 fw-600 gray f-14">
                            {item?.ops[0]?.ris?.[0]?.mb}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            }
          </div>
        </div>
      </div>
      <Footer />
      {photoShow && HotelData?.hotel && (
        <PhotoModal
          photoShow={photoShow}
          handlePhotoClose={handlePhotoClose}
          images={HotelImages}
          Hotels={HotelData}
        />
      )}

      {ReviewphotoShow && AllReviews && (
        <ReviewPhotoModal
          photoShow={ReviewphotoShow}
          handlePhotoClose={handleReviewPhotoClose}
          images={AllReviews.images}
          Hotels={HotelData}
        />
      )}

      {amenitiesShow && Fecilities && type && (
        <Fecility
          amenitiesShow={amenitiesShow}
          handleAmenitiesClose={handleAmenitiesClose}
          Fecilities={Fecilities}
          Type={type}
        />
      )}

      {inclusionShow && Cancellation && (
        <InclusionModal
          inclusionShow={inclusionShow}
          handleInclusionClose={handleInclusionClose}
          Data={Cancellation}
        />
      )}

      {informationShow && (
        <InformationModal
          informationShow={informationShow}
          handleInformationClose={handleInformationClose}
          Hoteldata={HotelData?.hotel}

        />
      )}

      {viewMapShow && (
        <ViewMapModal
          viewMapShow={viewMapShow}
          handleViewMapClose={handleMapclose}
          location={HotelData?.hotel?.gl}
        />
      )}

      {addReview && HotelData && (
        <AddReviewsModal
          show={addReview}
          handleClose={handleAddReviewClose}
          Hotelid={HotelData?.hotel?.id}
          Type={'Hotels'}
        />
      )}
    </>
  );
}
