import React from "react";
import Button from "react-bootstrap/esm/Button";
import Card from "react-bootstrap/Card";
import { MdArrowForwardIos } from "react-icons/md";
import { Link } from "react-router-dom";
import config from '../lib/config'

function BlogCard({  data,type ,footerbg}) {
  console.log("BlogCards",data);
  return (
    <div className="country-card blog_cusCard">
      <div className="common-card">

            <Card>
          <Card.Img src={`${config?.IMG_URL}/blogs/${data?.Symbol}/${data?.Image}`} className="img-fluid country-bg" />
                  <Link to="/blog-details" state={data} className="blog-card-nav text-decoration-none">
          <Card.Body className={footerbg == "black" ? "bg-black" : ""}>
                <div className="d-flex align-items-center justify-content-between">
                  <span className="ms-2 fw-600 f-15">{data?.Heading?.length > 25 ? data?.Heading?.slice(0,25) + "..." : data?.Heading}
                 
                  </span>
                    <span className="ms-2 fw-600 f-15">
                      <MdArrowForwardIos className="arrowIcon" />
                    </span>
                </div>
              </Card.Body>
                  </Link>
    
        </Card>
      </div>
    </div>
  );
}

export default BlogCard;
