// import ABIARRAY_BEP20data from '../Abi/BEP20.json'
import ABIARRAY_BEP20data from '../Abi/ERC20.json'


let key = {};
var Back_Url = "";
var FRONT_URL = "";
var IMG_URL = "";
var EnvName = "demo";
var Axios_api_key = "";
var Host=""
var Facebookid=''
var BNB_URL=''
var Adminaddress=''
var Adminprivatekey=''
var ABIARRAY_BEP20
var TokenAddress
var Nowpayment_Api_key
var ADMIN_URL
var chainlist
var chainId


if (EnvName === "local") {
  FRONT_URL = "http://localhost:3001";
  Back_Url = "http://localhost:3331";
  IMG_URL="http://localhost:3331";
  Axios_api_key=process.env.REACT_APP_AXIOS_KEY
  Host=process.env.REACT_APP_HOST
  Facebookid=process.env.REACT_APP_FACEBOOK_KEY
  BNB_URL="https://sepolia.infura.io/v3/be5bdb23c61c4b92b174cb569e57f7b2"
  Adminaddress=process.env.REACT_APP_ADMINADDRESS
  Adminprivatekey=process.env.REACT_APP_ADMINPRIVKEY
  ABIARRAY_BEP20=ABIARRAY_BEP20data
  TokenAddress=process.env.REACT_APP_TOKENADDRESS
  Nowpayment_Api_key=process.env.REACT_APP_NOWPAYMENTAPIKEY
  
  chainId = 97
  chainlist=[11155111,97,80001]
}
if (EnvName === "demo") {
  FRONT_URL = "https://pietrip.maticz.in";
  Back_Url = "https://pietripbackend.maticz.in";
  IMG_URL="https://pietripbackend.maticz.in";
  ADMIN_URL='https://pietripadmin.maticz.in'

  Axios_api_key=process.env.REACT_APP_AXIOS_KEY
  Host=process.env.REACT_APP_HOST
  Facebookid=process.env.REACT_APP_FACEBOOK_KEY
  BNB_URL="https://sepolia.infura.io/v3/be5bdb23c61c4b92b174cb569e57f7b2"
  Adminaddress=process.env.REACT_APP_ADMINADDRESS
  Adminprivatekey=process.env.REACT_APP_ADMINPRIVKEY
  ABIARRAY_BEP20=ABIARRAY_BEP20data
  TokenAddress=process.env.REACT_APP_TOKENADDRESS
  Nowpayment_Api_key=process.env.REACT_APP_NOWPAYMENTAPIKEY

  chainId = 97
  chainlist=[11155111,97,80001]
}
if (EnvName === "staging") {
  FRONT_URL = "https://tripfi.io";
  Back_Url = "https://api.tripfi.io";
  IMG_URL="https://api.tripfi.io";
  ADMIN_URL='https://admin.tripfi.io'

  Axios_api_key=process.env.REACT_APP_AXIOS_KEY
  Host=process.env.REACT_APP_HOST
  Facebookid=process.env.REACT_APP_FACEBOOK_KEY
  BNB_URL="https://sepolia.infura.io/v3/be5bdb23c61c4b92b174cb569e57f7b2"
  Adminaddress=process.env.REACT_APP_ADMINADDRESS
  Adminprivatekey=process.env.REACT_APP_ADMINPRIVKEY
  ABIARRAY_BEP20=ABIARRAY_BEP20data
  TokenAddress=process.env.REACT_APP_TOKENADDRESS
  Nowpayment_Api_key=process.env.REACT_APP_NOWPAYMENTAPIKEY

  chainId = 97
  chainlist=[11155111,97,80001]
}
key = {
  FRONT_URL:FRONT_URL,
  Back_Url: `${Back_Url}`,
  IMG_URL:IMG_URL,
  EMAIL: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  MOBILE: /^\d{10}$/,
  OTP: /^\d{6}$/,
  NumOnly: /^[1-9]\d*(\.\d+)?$/,
  PASSWORD:/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/,
  PANCARD:/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
  PASSPORT:/^[A-PR-WYa-pr-wy][1-9]\d\s?\d{4}[1-9]$/,
  SECRET_KEY: "trippie",
  Axios_api_key:Axios_api_key,
  Host:Host,
  Facebookid:Facebookid,
  BNB_URL:BNB_URL,
  Adminaddress:Adminaddress,
  Adminprivatekey:Adminprivatekey,
  TokenAddress:TokenAddress,
  Nowpayment_Api_key:Nowpayment_Api_key,
  ABIARRAY_BEP20:ABIARRAY_BEP20,
  chainId:chainId,
  chainlist:chainlist

};

export default key;