import Web3 from "web3";
import config from "../lib/config"
import { useSelector } from "react-redux";

export const connectWallet = async () => {
    try {
        if (!window.ethereum) {
            alert("Please Wallet Connect.");
            return null;
        }
        const web3 = new Web3(window.ethereum);
        const getChain = await web3.eth.getChainId();
        const chainId = parseInt(getChain);
        const correctChainId = config.chainlist.includes(chainId)
            ? chainId
            : config.chainId;


        await window.ethereum.request({ method: "eth_requestAccounts" });

        const accounts = await web3.eth.getAccounts();
        const account = accounts[0]?.toLowerCase();

        return {
            address: account,
            web3: web3,
            chain: correctChainId?.toString()
        };
    } catch (error) {
        console.log("ConnectWallet_error:", error);
    }

};
