import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { GetCms, GetOffersDetails } from "../Axios/cms.axios";
import config from "../lib/config"
import roomBg1 from "../assets/images/landing/room-bg1.png";
import { Link } from "react-router-dom";

function Offers() {
  const options = {
    loop: false,
    // center: true,
    items: 2,
    margin: 20,
    autoplay: false,
    dots: true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 2,
      },
    },
  };
  const [MediaRecords, setMediaRecords] = useState([])
  console.log("MEDDDDDD", MediaRecords);
  const [OffersMiddle, setOfferMiddle] = useState([])

  useEffect(() => {
    GetCmsMedias()
    GetOffersMiddle()
  }, [])
  const Media = [
    {
      logo: require("../assets/images/landing/media/icon1.png"),
    },
    {
      logo: require("../assets/images/landing/media/icon2.png"),
    },
    {
      logo: require("../assets/images/landing/media/icon3.png"),
    },
    {
      logo: require("../assets/images/landing/media/icon4.png"),
    },
  ];

  const GetCmsMedias = async () => {
    try {
      let resp = await GetCms({ Type: "all" });
      console.log("Repsnsesin_Medias", resp);
      if (resp.status == "success") {
        setMediaRecords(resp?.data)
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const CmsData = {
    MediaData: MediaRecords?.find((val) => val?.Type == "Media"),
    offersmiddle: MediaRecords.find((val) => val.Type == "offersmiddle"),
    // privacy: MediaRecords.find((val)=> val.identifier == "privacypolicy")

  }

  console.log("MEDisalls", CmsData);

  const GetOffersMiddle = async () => {
    try {
      let resp = await GetOffersDetails({ Type: "all" });
      console.log("GetOfferstop", resp?.data);
      let Offermiddle = resp?.data?.filter((item) => item?.pagename == "OffersMiddle")
      console.log("OffermiddleOffermiddle", Offermiddle);
      if (resp.status == "success") {
        setOfferMiddle(Offermiddle)
      }
    } catch (error) {
      console.log("error", error);
    }
  }


  return (
    <div className="container custom-container">
      <div className="offers">
        <div className="header-sec text-center">
          <h3 className="mb-0 fw-700 header-clr">OFFERS</h3>
          <p className="mb-0 header-sub-clr">
            Promotions, deals and special offers for you
          </p>
        </div>

        <div className="mt-4">
          <OwlCarousel
            id="customer-testimonoals"
            className="owl-carousel owl-theme offers-carousal"
            {...options}
          >

            {/* <div className="offers-custom-card">
              <div>
                <p className="f-15 mb-1 fw-700">
                  Fly away to your dream holiday
                </p>
                <p className="mb-0 f-12 fw-500">
                  Get inspired, compare and book flights with more flexibility
                </p>
                <Button className="search-flight cmn-btn mt-3">
                  Search Flight
                </Button>
              </div>
              <div className="flight-sec-logo">
                <img
                  src={require("../assets/images/landing/offers-flight.png")}
                  className="img-fluid"
                />
              </div>
            </div> */}

            {OffersMiddle?.map((item, index) => {
              console.log("edfrefetr", item);
              return (
                <Link to={item?.link} className="text-decoration-none">

                <div
                  className="rooms-det"
                  style={{
                    background: `linear-gradient(-45deg, rgba(102, 102, 102, 0), rgba(0, 0, 0, 0.9)) fixed, url(${config.IMG_URL}/offersimage/${item?.image}) fixed`,
                  }}
                >
                    <div>
                      <h5 className="mb-0 fw-700">{item?.Heading}</h5>
                      <div className="mb-0 f-15 fw-500" dangerouslySetInnerHTML={{ __html: item?.Content }}></div>
                    </div>
                </div>
          </Link>

              );
            })}

            {/* <div className="offers-custom-card">
              <div>
                <p className="f-15 mb-1 fw-700">
                  Fly away to your dream holiday
                </p>
                <p className="mb-0 f-12 fw-500">
                  Get inspired, compare and book flights with more flexibility
                </p>
                <Button className="search-flight cmn-btn mt-3">
                  Search Flight
                </Button>
              </div>
              <div className="flight-sec-logo">
                <img
                  src={require("../assets/images/landing/offers-flight.png")}
                  className="img-fluid"
                />
              </div>
            </div> */}

            {/* <div className="rooms-det">
              <div>
                <h5 className="mb-0 fw-700">New Year, New Stay</h5>
                <p className="mb-0 f-15">
                  Save 15% or more when you book and stay before
                </p>
                <p className="mb-0 f-15">1 April 2024</p>
              </div>
            </div> */}

          </OwlCarousel>
        </div>
      </div>
      <div className="land-rating">
        <div className="lft-sec">
          <h3 className="mb-0 fw-800 mb-2">{CmsData?.offersmiddle?.Description}</h3>
          <p className="f-13 fw-500">
            <div dangerouslySetInnerHTML={{ __html: CmsData?.offersmiddle?.Content }}></div>
            {/* Escape to the epitome of luxury, packed with signature amenities and
            services */}
          </p>
        </div>
        <div className="ryt-sec">
          <div className="d-flex align-items-center">
            <span className="f-14 fw-600">4.8 out of 5</span>
            <img
              src={require("../assets/images/landing/green-star.png")}
              className="img-fluid star mx-1"
            />
            <span className="fw-600">Trustpilot</span>
          </div>
          <div>
            <span className="f-14 fw-600">4.6 out of 5</span>
            <span>
              <img
                src={require("../assets/images/landing/Google.png")}
                className="img-fluid google ms-1"
              />
            </span>
          </div>
        </div>
      </div>
      <div className="land-media">
        <div className="header-sec text-center">
          <h3 className="mb-0 fw-700 header-clr">{CmsData?.MediaData?.Description}</h3>
        </div>
        <div className="row mt-5">
          {CmsData?.MediaData?.image?.map((item, index) => {
            return (
              <div className="mb-4 col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 d-flex align-items-center justify-content-center">
                <div className="logo">
                  <img src={`${config.IMG_URL}/cmsimages/${item}`} className="img-fluid" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Offers;
