import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Dropdown from "react-bootstrap/Dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/esm/Button";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link } from "react-router-dom";


import Image from "../components/Image";
import { Col, Container, Row, Form, ProgressBar } from "react-bootstrap";

import 'react-phone-number-input/style.css'


export default function CancellationPolicy() {



  return (
    <>
      <Header />
      <div className="cmn_pdt">
      <div className="search-page particular-room-details">
        <div className="search-header">
          <div className="container">
           <p className="filter_text mb-0">Benefits Details</p>
          </div>
        </div>
       
       

        <Container className="pt-5 pb-4">
        <p className="cms_heading mt-0 mb-3">Trip Assure program</p>

<p className="cms_desc mt-0 mb-0">
The Trip Assure Program (hereinafter the Program) can be availed by the customers of TripFi in order to safeguard their travel.
</p>
<p className="cms_desc mt-0 mb-1 mt-3">Trip Secure can be purchased while making a domestic hotel booking through the TripFi Platform. You get the following benefits:</p>
<p className="cms_desc mt-0 mb-1 mt-3">The Program safeguards you by providing you the following benefits:
</p>
     
    
    
     
      <p className="cms_heading_3 mt-3">Hotel stay insurance:</p>
      <p className="cms_desc mt-0 mb-1 mt-3">The Trip Assure program comes with hotel stay insurance benefits which is provided by Aditya Birla Health Insurance and consists of below benefits.</p>
      <p className="cms_heading_4 mt-3">a) Inclusions</p>
      <div className="table-responsive">
      <table class="table table-bordered table_cms">
      <thead>
    <tr>
     
      <th scope="col">Coverage</th>
      <th scope="col">Coverage Amount (INR)</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>In-patient Care with Day Care treatment for injury or illness (For Emergency Care)</td>
      <td>₹ 300000</td>
    </tr>

    <tr>
      <td>OPD Expenses for illness or injury</td>
      <td>₹ 15000</td>
    </tr>

    <tr>
      <td>Repatriation of Mortal Remains</td>
      <td>₹ 15000</td>
    </tr>
    

    <tr>
      <td>Bounced Booking (in case the hotel denies check-in for a confirmed booking, insured can claim for the additional amount paid for alternate accommodation booked by insured on their own)</td>
      <td>₹ 12500</td>
    </tr>

    <tr>
      <td>Emergency Hotel Accommodation for immediate family member in case the insured is hospitalized</td>
      <td>₹ 12500</td>
    </tr>

    <tr>
      <td>Home Burglary (Except Cash and Jewelry)</td>
      <td>₹ 15000</td>
    </tr>

    <tr>
      <td>Hotel Cancellation charges if insured cancels the booking due to any illness or natural calamities</td>
      <td>₹ 75000</td>
    </tr>

    <tr>
      <td>Medical Evacuation</td>
      <td>₹ 20000</td>
    </tr>

    <tr>
      <td>Loss of Laptop/ Tablet / Hand baggage during trip</td>
      <td>₹ 12500</td>
    </tr>

    <tr>
      <td>Personal Accident (Accidental Death & Permanent Total Disability)</td>
      <td>₹ 10000</td>
    </tr>

  
  
  </tbody>

</table>
      </div>
      <p className="cms_desc  mb-2">UIN Number: ADITGBP23002V012223</p>
      <p className="cms_heading_4 mt-3">b) Conditions applicable to this cover</p>
      <p className="cms_desc_lit  mb-2 ms-3">The Person should be between the age of 3 months to 60 years.</p>
      <p className="cms_desc_lit  mb-2 ms-3">Hotel Stay insurance will be applicable for Indian residents only</p>
      <p className="cms_desc_lit  mb-2 ms-3">The policy is valid only for customers of TripFi</p>
      <p className="cms_heading_4 mt-3">c) Exclusions:</p>
      <p className="cms_desc_lit  mb-2 ms-3">Claims resulting from any tour operator, travel agent, airline or other service provider becoming insolvent and not being able or willing to carry out any part of their duty to you.</p>
      <p className="cms_desc_lit  mb-2 ms-3">Any claim resulting from a tropical disease / contagious disease where you have not had the recommended inoculations and /or taken the recommended medication as per the government advisory issued by the country where You are travelling.</p>
      <p className="cms_desc_lit  mb-2 ms-3">The insurance policy does not cover treatment for pre-existing diseases/ conditions or Testing done at a Diagnostic centre other than the ones authorized/certified by the Union Health Ministry of India.</p>
      <p className="cms_desc_lit  mb-2 ms-3">Travelling against the advice of Physician.</p>
      <p className="cms_desc_lit  mb-2 ms-3">Self-Quarantine or Quarantine/ Isolation Advised by any authorized testing centre</p>
       <p className="cms_desc_lit  mb-2 ms-3">Self-Isolation during lockdown.</p>
       <p className="cms_desc  mb-2 mt-3">For detailed T&C of travel insurance, customer can refer to the wordings.</p>
         
       <p className="cms_heading_3 mt-3">Emergency Assistance:</p>
      <p className="cms_heading_4 mt-3">a) Inclusions</p>
      <p className="cms_desc_lit  mb-2 ms-3">
        <span className="bold_text_lit">Ambulance Assistance:</span>
        Post confirmation & payment from the customer, BLS Ambulance will reach the incident location. It will take 30-45 minutes within city limits for 20 Kms. If distance is lesser then time limit will reduce and vice versa. Ambulance will take the customer to the basis his/her preference or to the nearest hospital/ clinic and will call up the necessary agencies for taking action. The emergencies covered by the BLS Ambulance Services are for any life-threatening situations faced by the customer including Heart Attack, Asthma Attack, Blood Pressure, Fire, Accidents, Gas Leakage, Short Circuit. Ambulance Services will be provided to the customer on 24x7 basis for the duration of the subscription only.
      </p>


      <p className="cms_desc_lit  mb-2 ms-3">
        <span className="bold_text_lit">Pandemic assistance:</span>
        24/7 customer helpline for any information during the pandemic. Paramedic will be aligned & will reach within 2 hours for any location within city limits (Metro/ tier1/ tier2 cities) and within 3 hours for remote locations. Oxygen concentrator / Bed availability will be provided within 60 - 90 min of the first call.
      </p>


      <p className="cms_desc_lit  mb-2 ms-3">
        <span className="bold_text_lit">House call- doctor:</span>
        A general physician will be aligned for visit within city limits and will reach within 45- 60 minutes of the request for a distance of 20Kms. If distance is lesser then time limit will reduce and vice versa.
      </p>


      <p className="cms_desc_lit  mb-2 ms-3">
        <span className="bold_text_lit">Teleconsultation:</span>
        Tele consultation with general physician will be arranged within 15- 20 minutes.
      </p>

      <p className="cms_desc_lit  mb-2 ms-3">
        <span className="bold_text_lit">Repatriation of mortal remains:</span>
        Repatriation vehicle for mortal remains will be within 60 minutes post the clearance and confirmation from the morgue.
      </p>

      <p className="cms_desc  mb-2 mt-3">Note: All medical and ambulance costs and costs for the above inclusions shall be borne by the customer at actuals.</p>
       <p className="cms_desc  mb-2">Contact details for assistance will be shared over mail pots purchase of the product.</p>
        

       <p className="cms_heading_4 mt-3">b) Exclusions:</p>
      <p className="cms_desc_lit  mb-2 ms-3">No responsibility is taken for the medical condition or medical progress of patient.</p>
      <p className="cms_desc_lit  mb-2 ms-3">In case of pandemic situation, the hospital admission, ambulance services and pandemic crisis support would be provided as per applicable government guidelines, hospital/clinic’s discretion, and best effort basis.</p>
      <p className="cms_desc_lit  mb-2 ms-3">No responsibility is taken for the medical condition or medical progress of patient.</p>
      <p className="cms_desc_lit  mb-2 ms-3">If customer is not in a position to access the medical reports; medical progress report or medical records shall be provided subject to hospital administration approval and associated guidelines.</p>
      <p className="cms_desc_lit  mb-2 ms-3">The emergency assistance plan is non-transferable and will be applicable during the travel period only.</p>
      <p className="cms_desc_lit  mb-2 ms-3">Ambulance Services will be provided to the customer on 24x7 basis for the duration of the subscription only.</p>



      <p className="cms_heading_4 mt-3">c) Process:</p>
      <p className="cms_desc_lit  mb-2 ms-3">Notification via call received at Alarm Centre</p>
      <p className="cms_desc_lit  mb-2 ms-3">Case registered post obtaining necessary details</p>
      <p className="cms_desc_lit  mb-2 ms-3">Service partner will be assigned to arrange the service for the customer.</p>
      <p className="cms_desc_lit  mb-2 ms-3">Ensure consent from customer on actual service charges</p>
      <p className="cms_desc_lit  mb-2 ms-3">Across Assist will coordinate with service partner and customer for ongoing case updates</p>
      <p className="cms_desc_lit  mb-2 ms-3">Once the emergency service will complete, close the case file</p>
      <p className="cms_desc  mb-2 mt-3">An email with booking confirmation will be sent by TripFi in the name of the individual as registered with TripFi, on his/her email id within 3 working days of the transaction.</p>
          </Container>
      </div>
</div>
      <Footer />




     
    </>
  );
}
