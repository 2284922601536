import React from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Countries from "./Countries";
import Cities from "./Cities";
import { useState } from "react";
import { GetRegionsCategories } from "../Axios/cms.axios";
import { useEffect } from "react";
import { toast } from "react-toastify"

function Regions() {


  const [AllRegions, setAllRegions] = useState([])
  console.log("AllRegions", AllRegions);
  const [SelectedCountry, setSelectedCountry] = useState("All")
  console.log("SelectedCountry", SelectedCountry);
  const [EventKey, setEventKey] = useState("")
  console.log("eventndddd", EventKey);
  useEffect(() => {
    GetAllRegionsCategory()
  }, [])
  const GetAllRegionsCategory = async () => {
    try {
      let resp = await GetRegionsCategories()
      console.log("Responsess", resp);
      if (resp?.status) {
        setAllRegions(resp?.data?.[0])
      }
      else {
        toast.error("Something error")
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const handleSelectCategory = async (cat) => {
    console.log("Cattt", cat);
    try {
      setSelectedCountry(cat)
    } catch (error) {
      console.log("error", error);
    }
  }
  return (
    <div className="container custom-container">
      <div className="regions">
        <div className="header-sec text-center">
          <h3 className="mb-0 fw-700 header-clr">{AllRegions?.Heading}</h3>
          <p className="mb-0 header-sub-clr">
            {/* Where do you want to go? Find the best hotels in top destinations */}
            <div dangerouslySetInnerHTML={{ __html: AllRegions?.Content }}></div>
          </p>
        </div>
        <div className="regions-custom-tabs">
          <Tab.Container id="left-tabs-example" defaultActiveKey={1}>
            <div className="row">
              <div className="col-xl-12">
                <Nav variant="pills" className="">
                  <Nav.Item>
                    <Nav.Link eventKey={1} onClick={() => handleSelectCategory("All")}>{"All"}</Nav.Link>
                  </Nav.Item>
                  {
                    // AllRegions?.Categories?.length > 0 ?
                    AllRegions?.Categories?.map((item, i) => {


                      // <li key={i}></li>
                      console.log("fgdrttrrrrrrrr", item);
                      return (
                        <>
                          <Nav.Item>
                            <Nav.Link eventKey={i + 2} onClick={() => handleSelectCategory(item)}>{item}</Nav.Link>
                          </Nav.Item>
                        </>
                      )

                    })
                    // :"No data found"
                  }
                </Nav>
                {/* <Nav.Item>
                    <Nav.Link eventKey="one">EUROPE</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="two">NORTH AMERICA</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="three">ASIA</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="four">CENTRAL AMERICA</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="five">OCEANIA</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="six">SOUTH AMERICA</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="seven">MIDDLE EAST</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="seven">AFRICA</Nav.Link>
                  </Nav.Item> */}
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <Tab.Content>
                  <Tab.Pane eventKey={1}>
                    <div className="countries mt-5">
                      <Countries Category={SelectedCountry} />
                    </div>
                    <div className="cities mt-5">
                      <Cities Category={SelectedCountry} />
                    </div>
                  </Tab.Pane>

                  {
                    AllRegions?.Categories?.map((item, i) => {
                      console.log("FRTGgggggggg", item);
                      return (
                        <Tab.Pane eventKey={i + 2}>
                          <div className="countries mt-5">
                            <Countries Category={SelectedCountry} />
                          </div>
                          <div className="cities mt-5">
                            <Cities Category={SelectedCountry} />
                          </div>
                        </Tab.Pane>

                      )
                    })
                  }
                  {/* 
                  <Tab.Pane eventKey="two">
                    <div className="countries mt-5">
                      <Countries />
                    </div>
                    <div className="cities mt-5">
                      <Cities />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="three">
                    <div className="countries mt-5">
                      <Countries />
                    </div>
                    <div className="cities mt-5">
                      <Cities />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="four">
                    <div className="countries mt-5">
                      <Countries />
                    </div>
                    <div className="cities mt-5">
                      <Cities />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="five">
                    <div className="countries mt-5">
                      <Countries />
                    </div>
                    <div className="cities mt-5">
                      <Cities />
                    </div>
                  </Tab.Pane> */}
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
}

export default Regions;
