import React, { useState, useEffect, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import metamask from "../assets/images/wallet/metamask.png";
import walletConnect from "../assets/images/wallet/wallet-connect.png";
import { connectWallet } from "../pages/Contract";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify"
const ConnectWalletModal = ({ show, handleClose }) => {

  const dispatch = useDispatch()


  const handleSelect = async (type) => {
    console.log("Selected_walltet", type);
    const id = toast.loading("loading...")
    try {
      if (type == "Metamask") {
        const getData = await connectWallet();
        if (getData?.address) {
          toast.update(id, { render: "Wallet Connected Successfully", type: "success", isLoading: false, autoClose: 1000 });
          localStorage.setItem("account", getData?.address)
          localStorage.setItem("account_wallettype", type)

          dispatch({
            type: "WalletConnectAddress",
            WalletConnectAddress: { ConnetedAddress: getData?.address, web3: getData?.web3 }
          })
          console.log('getData---->', getData);
          handleClose()
        }
      }

    } catch (error) {
      console.log("error", error)
    }
  }


  return (
    <div>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="md"
        className="common_modal connect_walletModal"
      >
        <Modal.Header closeButton className="justify-content-center">
          <Modal.Title>Select Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-4">
          <div className="wallet_content">
            <div className="wallet_item" onClick={() => handleSelect("Metamask")}>
              <img src={metamask} className="icon img-fluid" />
              <p className="title-txt f-15 mb-0 fw-600">Metamask</p>
            </div>
            {/* <hr className="custom_hr" /> */}
            <div className="wallet_item" onClick={() => handleSelect("WalletConnect")}>
              <img src={walletConnect} className="icon img-fluid" />
              <p className="title-txt f-15 mb-0 fw-600">WalletConnect</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ConnectWalletModal;
