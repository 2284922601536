import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowRight } from "react-icons/fa";
import Button from "react-bootstrap/esm/Button";
function CryptoCurrency() {
  const Crypto = [
    // {
    //   logo: require("../assets/images/landing/CryptoCurrency/icon1.png"),
    // },
    // {
    //   logo: require("../assets/images/landing/CryptoCurrency/icon2.png"),
    // },
    {
      logo: require("../assets/images/landing/CryptoCurrency/icon3.png"),
    },
    // {
    //   logo: require("../assets/images/landing/CryptoCurrency/icon4.png"),
    // },
    {
      logo: require("../assets/images/landing/CryptoCurrency/icon5.png"),
    },
    {
      logo: require("../assets/images/landing/CryptoCurrency/icon6.png"),
    },
    // {
    //   logo: require("../assets/images/landing/CryptoCurrency/icon7.png"),
    // },
    {
      logo: require("../assets/images/landing/CryptoCurrency/icon8.png"),
    },
    // {
    //   logo: require("../assets/images/landing/CryptoCurrency/icon1.png"),
    // },
    // {
    //   logo: require("../assets/images/landing/CryptoCurrency/icon2.png"),
    // },
    // {
    //   logo: require("../assets/images/landing/CryptoCurrency/icon3.png"),
    // },
    // {
    //   logo: require("../assets/images/landing/CryptoCurrency/icon4.png"),
    // },
    // {
    //   logo: require("../assets/images/landing/CryptoCurrency/icon5.png"),
    // },
    // {
    //   logo: require("../assets/images/landing/CryptoCurrency/icon6.png"),
    // },
    // {
    //   logo: require("../assets/images/landing/CryptoCurrency/icon7.png"),
    // },
    // {
    //   logo: require("../assets/images/landing/CryptoCurrency/icon8.png"),
    // },
  ];

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 10,
    slidesToScroll: 10,
    margin: 10,
    // arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          // initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
    ],
  };

  return (
    <div className="container custom-container">
      <div className="row d-flex align-items-center justify-content-center country-slider">
        <div className="cryptocurrency col-xl-9">
          <div className="header-sec text-center">
            <h3 className="mb-0 fw-700 header-clr">
              Cryptocurrency Friendly Travel Booking
            </h3>
            <p className="mb-0 header-sub-clr f-15 mt-2">
              We pride ourselves on being the leading cryptocurrency friendly
              accommodation booking platform. Pay for your stay anywhere in the
              world seamlessly using your favourite cryptocurrency.
            </p>
          </div>
           <Slider {...settings} className="crypto-slide">
            {Crypto.map((item, index) => {
              return (
                <div className="crypto-icons" key={index}>
                  <img src={item.logo} className="img-fluid logo" />
                </div>
              );
            })}
          </Slider>
          {/* <div className="d-flex align-items-center justify-content-center">
            <Button className="payment-option cmn-btnbg-Transparent f-13 fw-800">
              Payment options
              <FaArrowRight className="ms-2" />
            </Button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default CryptoCurrency;
