import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";

import { Elements } from "@stripe/react-stripe-js";
import Payment from "../modals/stripepayment";


const StripepaymentModal = ({ show, handleClose,Data,Client,Bookingid,Travellerdata }) => {
  console.log("stripepayment_logs",Data,Client,Travellerdata);
  const [loader, setLoader] = useState(false);

    


  return (
    <div>
      <Modal
        show={show}
        // onHide={ loader || hold_loader ?"":handleClose}
        centered
        size="md"
        className="common_modal reservation_modal"
      >
        <Modal.Header >
          <Modal.Title>Card Payment</Modal.Title>
        </Modal.Header>
        <Modal.Header >
          <Modal.Title>Note:Please don't refresh or close the popup,if you close the popup you have to book from the initial </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-4">
          <div className="reservation_modal_details">
          {  Data && Client &&
 
 (             <Elements
                            stripe={Data}
                            options={{
                              clientSecret: Client,
                              appearance: {
                                theme: "flat",
                                variables: { colorPrimaryText: "#262626" },
                              },
                            }}
                          >
                            <Payment
                            Datas={Travellerdata}
                            setLoader={setLoader}
                            handleClose={handleClose}
         
                            />
                          </Elements>
                        )}
          
          </div>


     
 
        </Modal.Body>
      </Modal>

    </div>
    
  );
};

export default StripepaymentModal;
