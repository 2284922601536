import React, { useEffect, useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { CiSearch } from "react-icons/ci";
import { TiArrowSortedDown } from "react-icons/ti";
import { FiSearch } from "react-icons/fi";
import "react-datepicker/dist/react-datepicker.css";
import { TfiLocationPin } from "react-icons/tfi";
import { Link, json } from "react-router-dom";
import Button from "react-bootstrap/esm/Button";
import Card from "react-bootstrap/Card";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";
import { FaCirclePlus } from "react-icons/fa6";
import { FaStar } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import plane from "../assets/images/banner/plane.svg";
import arrow from "../assets/images/banner/two-arrow.svg";
import marker from "../assets/images/banner/marker.svg";
import calendar from "../assets/images/banner/datepicker.svg";
import passenger from "../assets/images/banner/passenger.svg";
import bed from "../assets/images/bed.svg";
import map from "../assets/images/map.png";
import search from "../assets/images/search.png";
import { DateTimeForm, EncryptData, isEmpty } from "../lib/common";
import * as Hotels from "../Axios/hotel.axios";
import NodataFound from "../components/NodataFound";
import { GrLocation } from "react-icons/gr";
import { GetCms, GetOffersDetails } from "../Axios/cms.axios";
import config from "../lib/config"
import BannerImg from '../assets/images/banner.png'
import Footer from "../components/Footer";
function Banner() {
  const [active, setActive] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [customTabs, setCustomtabs] = useState("hotels");
  const [number, setNumber] = useState([0]);
  const [SearchData, setSearchData] = useState([]);
  const [Submitstatus, setSubmitstatus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [locationDisplay, setLocationDisplay] = useState(false);
  const [cityName, setCityName] = useState("");
  const FoucsInp = useRef(null);
  const searchLocRef = useRef(null);
  const initial = {
    Searchdata: "",
    Checkin: new Date(),
    Checkout: new Date(new Date().setDate(new Date().getDate() + 1)),
    Type: "",
    SelectedData: {},
    Citydata: {},
    roomInfo: [
      {
        numberOfAdults: 1,
        numberOfChild: 0,
        childAge: [],
      },
    ],
  };

  const [formdata, setFormdata] = useState(initial);
  const [Error, setErr] = useState({});
  const [BannerData, setBannerData] = useState({});
  const [OfferstopData, setOfferstopData] = useState({});
  const [OffersTop, setOffersTop] = useState([])
  useEffect(() => {
    GetCmsBanner();
    GetCmsOfferTop();
    GetOfferstop();
  }, [])
console.log("OfferstopData",OfferstopData);
  const Intro = [
    {
      img: require("../assets/images/landing/card-bg1.png"),
      name: "Pie Homes by dPie",
      dec: "Escape to the epitome of luxury.",
    },
    {
      img: require("../assets/images/landing/card-bg2.png"),
      name: "Pie Homes by dPie",
      dec: "Escape to the epitome of luxury.",
    },
    {
      img: require("../assets/images/landing/card-bg3.png"),
      name: "Pie Homes by dPie",
      dec: "Escape to the epitome of luxury.",
    },
  ];


  const GetCmsBanner = async () => {
    try {
      let resp = await GetCms({ Type: "banner" });
      console.log("Repsnsesin_setBannerData", resp);
      if (resp.status == "success") {
        setBannerData(resp?.data)
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const GetCmsOfferTop = async () => {
    try {
      let resp = await GetCms({ Type: "offerstop" });
      console.log("nerDanerDanerDa", resp);
      if (resp.status == "success") {
        setOfferstopData(resp?.data)
      }
    } catch (error) {
      console.log("error", error);
    }
  }
  const GetOfferstop = async () => {
    try {
      let resp = await GetOffersDetails({ Type: "all" });
      console.log("GetOfferstop", resp?.data);
      let Offertop = resp?.data?.filter((item)=> item?.pagename == "OffersTop")
      console.log("dfgdgdgfdgfdg",Offertop);
      if (resp.status == "success") {
        setOffersTop(Offertop)
      }
    } catch (error) {
      console.log("error", error);
    }
  }
  const options1 = [
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
    { value: "13", label: "13" },
    { value: "14", label: "14" },
    { value: "15", label: "15" },
    { value: "16", label: "16" },
    { value: "17", label: "17" },
  ];

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      return {
        ...styles,
        backgroundColor: isHovered
          ? "#653883"
          : isSelected
            ? "#653883"
            : isFocused
              ? "#653883"
              : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
            ? "#fff"
            : isFocused
              ? "#fff"
              : "",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "38px",
      padding: "0px 10px",
      border: "none",
      backgroundColor: "#fff",
      color: "#000",
      borderColor: "#BEBEBE",
      borderRadius: "4px",
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: "4px",
      backgroundColor: "transparent",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "red",
      padding: "0px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#ffffff",
    }),
    menuList: (base) => ({
      ...base,

      padding: 0,
      width: "100%",

      borderRadius: 5,
      background: "transparent",
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#000",
      };
    },
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown1 = () => {
    setIsOpen1(!isOpen1);
  };
  useEffect(() => {
    if (formdata?.Searchdata) {
      Search();
    }
  }, [formdata?.Searchdata]);

  const handleTabs = (val) => {
    setCustomtabs(val);
  };

  const onchange = (e) => {
    if (e.target.value) {
      setLocationDisplay(true);
      setCityName("");
    }
    setErr({});
    const { id, value } = e.target;
    setFormdata({ ...formdata, ...{ [id]: value } });
  };

  const Search = async () => {
    if (formdata?.Searchdata) {
      let { status, data } = await Hotels.SerachCountryandCities({
        searchdata: formdata?.Searchdata,
        limit: 10,
      });
      if (status) {
        setSearchData(data);
      }
    }
  };
  const Roomsdata = async (index, id, value, childindex, type) => {
    var Roomdata = formdata.roomInfo;

    if (id == "numberOfChild") {
      if (type == "add" && Roomdata[index].numberOfChild < 3) {
        Roomdata[index]["childAge"].push("");
        Roomdata[index][id] = value > 0 ? value : 0;
        setSubmitstatus(false);
      } else if (type == "sub") {
        Roomdata[index]["childAge"].pop();
        Roomdata[index][id] = value > 0 ? value : 0;
      }
      setFormdata({ ...formdata, ...{ ["roomInfo"]: [...Roomdata] } });
      setSubmitstatus(false);
    } else if (id == "childAge") {
      Roomdata[index]["childAge"][childindex] = value;
      setFormdata({ ...formdata, ...{ ["roomInfo"]: [...Roomdata] } });
    } else if (id == "numberOfAdults") {
      if (type == "add" && Roomdata[index].numberOfAdults < 7) {
        Roomdata[index][id] = value > 1 ? value : 1;
        setFormdata({ ...formdata, ...{ ["roomInfo"]: [...Roomdata] } });
      } else if (type == "sub") {
        Roomdata[index][id] = value > 1 ? value : 1;
        setFormdata({ ...formdata, ...{ ["roomInfo"]: [...Roomdata] } });
      }
    }
  };

  const Addrooms = async (data, index) => {
    if (data == "add") {
      setNumber([...number, number.length]);
      setFormdata({
        ...formdata,
        ...{
          ["roomInfo"]: [
            ...formdata.roomInfo,
            {
              numberOfAdults: 1,
              numberOfChild: 0,
              childAge: [],
            },
          ],
        },
      });
    } else if (data == "sub") {
      let Rooms = formdata;
      Rooms.roomInfo.pop();
      setFormdata({ ...Rooms });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (!isOpen) {
        setSubmitstatus(true);
        formdata?.roomInfo.map((item) => {
          item.childAge.length > 0 &&
            item.childAge.map((value, idx) => {
              if (isEmpty(value) && !isEmpty(item?.numberOfChild)) {
                setIsOpen(true);
              }
            });
        });
      }
    }, 50);
  }, [isOpen]);

  const DateSelect = async (data, id) => {
    let CheckoutDate = new Date(formdata.Checkout).setDate(
      new Date(formdata.Checkout).getDate()
    );
    let initialCheckinDate = new Date(formdata.Checkin).setDate(
      new Date(formdata.Checkin).getDate() + 1
    );

    if (id == "Checkin") {
      if (CheckoutDate == initialCheckinDate || CheckoutDate < data) {
        setFormdata({
          ...formdata,
          ...{
            ["Checkin"]: data,
            ["Checkout"]: new Date(
              new Date(data).setDate(new Date(data).getDate() + 1)
            ),
          },
        });
      } else if (CheckoutDate < data) {
        setFormdata({
          ...formdata,
          ...{
            ["Checkin"]: data,
            ["Checkout"]: new Date(
              new Date(data).setDate(new Date(data).getDate() + 1)
            ),
          },
        });
      } else {
        setFormdata({
          ...formdata,
          ...{ ["Checkin"]: data },
        });
      }
    } else if (id == "Checkout") {
      setFormdata({
        ...formdata,
        ...{ ["Checkout"]: data },
      });
    } else {
      setFormdata({
        ...formdata,
        ...{ [id]: data },
      });
    }
  };
  const Guestcount = (data) => {
    const Adults = data?.reduce((accumulator, currentValue) => {
      return accumulator + currentValue?.numberOfAdults;
    }, 0);
    const Children = data?.reduce((accumulator, currentValue) => {
      return accumulator + currentValue?.numberOfChild;
    }, 0);
    return { Adults: Adults, Children: Children };
  };

  const handleClearSearch = () => {
    setFormdata({
      ...formdata,
      ...{
        ["Searchdata"]: "",
      },
    });
    setCityName("");
    FoucsInp.current.focus();
  };

  useEffect(() => {
    if (formdata?.Searchdata == "") {
      setLocationDisplay(false);
    }
  }, [formdata?.Searchdata]);

  const handleClickOutside = (event) => {
    if (searchLocRef.current && !searchLocRef.current.contains(event.target)) {
      setLocationDisplay(false);
    }
  };

  useEffect(() => {
    if (locationDisplay) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [locationDisplay]);

  return (
    <div>
      <div className="banner" style={{ backgroundImage: `url(${BannerImg})` }}>
        <p className="banner_heading mb-1">
          {/* A dream stay for a bucket list trip */}
          Sorry!
        </p>
        <p className="banner_subheading">
        We looked everywhere, but couldn't find the page you requested.Shall we start a new exploration?
        </p>

        <div className="main_card_banner">
          <div className="custom-tabs-sec">
            <div
              className={customTabs == "hotels" ? "cmn-sec active" : "cmn-sec"}
              onClick={(e) => handleTabs("hotels")}
            >
              <img
                src={require("../assets/images/banner/hotels-inactive.png")}
                className="img-fluid logo hotel-inactive"
              />
              <img
                src={require("../assets/images/banner/hotels-active.png")}
                className="img-fluid logo hotel-active"
              />
              <span className="name">Hotels</span>
            </div>
            <div
              className={customTabs == "flights" ? "cmn-sec active" : "cmn-sec"}
              onClick={(e) => handleTabs("flights")}
            >
              <img
                src={require("../assets/images/banner/flights.png")}
                className="img-fluid logo"
              />
              <span className="name">Flights</span>
            </div>
            <div
              className={
                customTabs == "activities" ? "cmn-sec active" : "cmn-sec"
              }
              onClick={(e) => handleTabs("activities")}
            >
              <img
                src={require("../assets/images/banner/activity-inactive.png")}
                className="img-fluid logo activity-inactive"
              />
              <img
                src={require("../assets/images/banner/activity-active.png")}
                className="img-fluid logo activity-active"
              />
              <span className="name">Activities</span>
            </div>
            <div
              className={customTabs == "aiAgent" ? "cmn-sec active" : "cmn-sec"}
              onClick={(e) => handleTabs("aiAgent")}
            >
              <img
                src={require("../assets/images/banner/ai-agent.png")}
                className="img-fluid logo"
              />
              <span className="name">AI Agent</span>
            </div>
            <div className="cmn-sec" onClick={(e) => handleTabs("comingSoon")}>
              <span className="name">Coming Soon</span>
            </div>
          </div>
          {customTabs == "hotels" ? (
            <>
              <div className="domestic-international mb-3">
                <p className="book_text mb-0 f-15">
                  Book Domestic and International Property Online.
                </p>
              </div>
              <div className="room-details search-page justify-content-between">
                <div className="filter-search filter-search_cu">
                  <div className="city sec1">
                    <div className="city cmn-sec">
                      {/* <Dropdown
                        className="area-city"
                        show={isOpen1}
                        onToggle={toggleDropdown1}
                      >
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="cmn-heading"
                        >
                          CITY, AREA OR PROPERTY
                          <p className="mb-0 mt-2 city-name">
                            {formdata?.SelectedData?.cityName
                              ? formdata?.SelectedData?.cityName
                              : "Choose City"}
                          </p>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          className="locaPick-drop"
                          rootCloseEvent={null}
                        >
                          <div className="cityArea-Property">
                            <div className="search mb-2">
                              <img src={search} className="img-fluid" />
                              <input
                                type="text"
                                className="searchInp"
                                placeholder="Search"
                                id="Searchdata"
                                value={formdata?.Searchdata ?? "Choose City"}
                                onChange={(e) => onchange(e)}
                              />
                            </div>
                            {SearchData?.length > 0 && (
                              <p className="mb-2 f-15 fw-600">Cities</p>
                            )}

                            <div className="loc">
                              {SearchData?.length > 0 ? (
                                SearchData?.map((item) => (
                                  <div
                                    className="loc-details mb-2"
                                    onClick={() => {
                                      setIsOpen1(false);
                                      setFormdata({
                                        ...formdata,
                                        ...{
                                          ["SelectedData"]: item,
                                          ["Citydata"]: {
                                            city: item?.id,
                                            nationality:
                                              item?.Countries[0]?.countryid,
                                            countrycode:
                                              item?.Countries[0]?.code,
                                          },
                                        },
                                      });
                                    }}
                                  >
                                    <img
                                      src={map}
                                      className="loc-logo img-fluid"
                                    />
                                    <span className="f-13 loc-txt gray fw-500">
                                      {item?.cityName}
                                    </span>
                                  </div>
                                ))
                              ) : (
                                <> {formdata?.Searchdata && <NodataFound />} </>
                              )}
                            </div>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown> */}
                      {/* <p className="mb-0 f-14 fw-500">
                        {formdata?.SelectedData?.cityName ? formdata?.SelectedData?.cityName : "Choose City" }
                      </p> */}

                      <div className="location_customSearch position-relative">
                        <p className="cmn-heading mb-0">
                          CITY, AREA OR PROPERTY
                        </p>
                        <div className="search-Inp mt-2">
                          <div className="searchDetails">
                            <CiSearch className="searchIcon" />
                            <FiSearch className="searchIcon" />
                            <input
                              type="text"
                              className="searchInp"
                              placeholder="Search for Places"
                              id="Searchdata"
                              // value={cityName ? cityName : formdata?.Searchdata}
                              value={cityName ? cityName : formdata?.Searchdata}
                              onChange={(e) => onchange(e)}
                              ref={FoucsInp}
                            />
                            {(locationDisplay ||
                              formdata?.Searchdata != "") && (
                                <span onClick={handleClearSearch}>
                                  <IoMdClose className="closeIcon" />
                                </span>
                              )}
                          </div>
                          <div className="" ref={searchLocRef}>
                            {locationDisplay && (
                              <div className="search_loc_display">
                                <div className="cusHeight">
                                  {SearchData?.length > 0 ? (
                                    SearchData?.map((item) => (
                                      <div
                                        className="loc-details"
                                        onClick={() => {
                                          setLocationDisplay(false);
                                          setCityName(item?.cityName);
                                          setFormdata({
                                            ...formdata,
                                            ...{
                                              ["SelectedData"]: item,
                                              ["Citydata"]: {
                                                city: item?.id,
                                                nationality:
                                                  item?.Countries[0]?.countryid,
                                                countrycode:
                                                  item?.Countries[0]?.code,
                                              },
                                            },
                                          });
                                        }}
                                      >
                                        <GrLocation className="loc-logo" />
                                        <p className="mb-0 f-13 loc-txt gray fw-500">
                                          {item?.cityName}
                                        </p>
                                      </div>
                                    ))
                                  ) : (
                                    <>
                                      {formdata?.Searchdata && <NodataFound />}
                                    </>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {Error?.Searchdata && (
                      <p
                        className="text-left err_msg_txt mb-0 mt-0"
                        style={{ padding: "0px 12px" }}
                      >
                        {Error?.Searchdata}
                      </p>
                    )}
                  </div>
                </div>
                <div className="check-in custom-datepicker-drop sec2">
                  <div>
                    <Dropdown className="check-in-drop">
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="cmn-heading"
                      >
                        <span className="d-flex align-items-center gap-1">
                          Checkin
                          <TiArrowSortedDown />
                        </span>
                        <h6 className="mb-0 fw-700 mt-2 startEnd-date">
                          {formdata?.Checkin
                            ? DateTimeForm({
                              date: formdata.Checkin,
                              datealone: true,
                            })
                            : "Check in"}
                        </h6>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <DatePicker
                          selected={formdata.Checkin}
                          onChange={(e) => {
                            DateSelect(e, "Checkin");
                          }}
                          open={true}
                          minDate={new Date()}
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                    {Error?.Checkin && (
                      <p
                        className="text-left err_msg_txt mb-0 mt-0"
                        style={{ padding: "0px 12px" }}
                      >
                        {Error?.Checkin}
                      </p>
                    )}
                    {/* <h6 className="mb-0 fw-700 mt-2 startEnd-date">
                      {formdata?.Checkin
                        ? DateTimeForm({ date:formdata.Checkin,datealone:true })
                        : "Check in"}
                    </h6> */}
                  </div>
                </div>
                <div className="check-in custom-datepicker-drop sec3">
                  <div>
                    <Dropdown className="check-in-drop">
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="cmn-heading"
                      >
                        {/* Checkout */}
                        <span className="d-flex align-items-center gap-1">
                          Checkout
                          <TiArrowSortedDown />
                        </span>
                        <h6 className="mb-0 fw-700 mt-2 startEnd-date">
                          {formdata?.Checkout
                            ? DateTimeForm({
                              date: formdata.Checkout,
                              datealone: true,
                            })
                            : "Check out"}
                        </h6>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <DatePicker
                          selected={formdata?.Checkout}
                          onChange={(e) => {
                            DateSelect(e, "Checkout");
                          }}
                          open={true}
                          minDate={
                            new Date(
                              new Date(formdata.Checkin).setDate(
                                new Date(formdata.Checkin).getDate() + 1
                              )
                            )
                          }
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                    {Error?.Checkout && (
                      <p
                        className="text-left err_msg_txt mb-0 mt-0"
                        style={{ padding: "0px 12px" }}
                      >
                        {Error?.Checkout}
                      </p>
                    )}
                    {/* <h6 className="mb-0 fw-700 mt-2 startEnd-date">
                      {formdata?.Checkout
                        ? DateTimeForm({date:formdata.Checkout,datealone:true})
                        : "Check out"}
                    </h6> */}
                  </div>
                </div>
                <div className="check-in sec4">
                  <Dropdown
                    className="check-in-drop"
                    show={isOpen}
                    onToggle={toggleDropdown}
                  >
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="cmn-heading"
                    >
                      {/* Rooms & Guests */}
                      <span className="d-flex align-items-center gap-1">
                        Rooms & Guests
                        <TiArrowSortedDown />
                      </span>
                      <h6 className="mb-0 fw-700 mt-2 room-adult-txt">
                        {formdata?.roomInfo?.length} Rooms &
                        {Guestcount(formdata?.roomInfo)?.Adults} Adults {' '} {Guestcount(formdata?.roomInfo)?.Children} Children




                      </h6>
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      className="rooms-guest"
                      rootCloseEvent={null}
                    >
                      {formdata?.roomInfo?.map((value, index) => (
                        <>
                          <div className="mb-1">
                            <div className="room-no">
                              <span className="f-15 fw-600">
                                Room {index + 1}
                              </span>
                              <span className="close-icon cur_pointer">
                                {index !== 0 && (
                                  <IoMdCloseCircleOutline
                                    onClick={() => Addrooms("sub", index)}
                                  />
                                )}
                              </span>
                            </div>

                            <div className="customer-details">
                              <div>
                                <span className="f-13 fw-500"> Adults</span>
                                <div className="inc-dec mt-1">
                                  <Button
                                    className="decrement-btn"
                                    onClick={() =>
                                      Roomsdata(
                                        index,
                                        "numberOfAdults",
                                        value?.numberOfAdults - 1,
                                        undefined,
                                        "sub"
                                      )
                                    }
                                  >
                                    <FaMinus />
                                  </Button>
                                  <span className="value">
                                    {" "}
                                    {value?.numberOfAdults} Adults
                                  </span>
                                  <Button
                                    className="increment-btn"
                                    onClick={() =>
                                      Roomsdata(
                                        index,
                                        "numberOfAdults",
                                        value?.numberOfAdults + 1,
                                        undefined,
                                        "add"
                                      )
                                    }
                                  >
                                    <FaPlus />
                                  </Button>
                                </div>
                              </div>
                              <div>
                                <span className="f-13 fw-500">
                                  Children
                                  <span className="age"> (Age 0-17)</span>
                                </span>
                                <div className="inc-dec mt-1">
                                  <Button
                                    className="decrement-btn"
                                    onClick={() =>
                                      Roomsdata(
                                        index,
                                        "numberOfChild",
                                        value?.numberOfChild - 1,
                                        undefined,
                                        "sub"
                                      )
                                    }
                                  >
                                    <FaMinus />
                                  </Button>
                                  <span className="value">
                                    {" "}
                                    {value?.numberOfChild} Child
                                  </span>
                                  <Button
                                    className="increment-btn"
                                    onClick={() =>
                                      Roomsdata(
                                        index,
                                        "numberOfChild",
                                        value?.numberOfChild + 1,
                                        undefined,
                                        "add"
                                      )
                                    }
                                  >
                                    <FaPlus />
                                  </Button>
                                </div>
                              </div>
                            </div>

                            <div className="childrens-age mt-2">
                              {value?.childAge?.length > 0 && (
                                <span className="f-13 fw-500">
                                  Age of children
                                </span>
                              )}{" "}
                              <div className="row">
                                {value?.childAge?.map((itm, childindex) => (
                                  <div className="col-6 mb-3">
                                    <Select
                                      //  value={selectedOption1}
                                      onChange={(e) =>
                                        Roomsdata(
                                          index,
                                          "childAge",
                                          e?.label,
                                          childindex
                                        )
                                      }
                                      options={options1}
                                      styles={stylesgraybg}
                                      isSearchable={false}
                                      placeholder="Select Age"
                                      classNamePrefix="customselect"
                                      value={{
                                        value: itm ? itm : 0,
                                        label: itm ? itm : 0,
                                      }}

                                    // menuIsOpen
                                    />
                                    {Submitstatus && isEmpty(itm) && (
                                      <p className="error_msg mt-2">{`Select the child's age`}</p>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                      <div
                        className={
                          formdata?.roomInfo?.length < 4
                            ? "mt-1 f-14 fw-600 d-flex align-items-center justify-content-end"
                            : "d-none"
                        }
                      >
                        <div
                          className="add-room"
                          onClick={() => Addrooms("add")}
                        >
                          <span className="">ADD ROOM</span>
                          <FaCirclePlus className="plus-icon ms-1" />
                        </div>
                        {/* <div
                          className="add-room"
                          onClick={() =>   setIsOpen(false)}
                        >
<Button>Apply

</Button>                         
                        </div> */}
                      </div>
                      <div
                        className="mt-3 d-flex align-items-center justify-content-center"
                        onClick={() => setIsOpen(false)}
                      >
                        <Button className="f-14 px-3 cmn-purple-btn">
                          {" "}
                          Apply
                        </Button>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* <h6 className="mb-0 fw-700 mt-2 room-adult-txt">
                    {formdata?.roomInfo?.length} Rooms 
                  </h6> */}
                </div>
              </div>
            </>
          ) : customTabs == "flightss" ? (
            <div className="flights-details">
              <div className="radio-sec">
                <div className="d-flex align-items-center">
                  <Form.Check type="radio" label="Round trip" />
                </div>
                <div className="d-flex align-items-center">
                  <Form.Check type="radio" label="One Way" />
                </div>
              </div>
              <div className="flight-search-details">
                <div className="first-sec">
                  <div className="from">
                    <img src={plane} className="img-fluid logo" />
                    <input
                      type="text"
                      className="input"
                      placeholder="Flying From"
                    />
                  </div>
                  <img src={arrow} className="img-fluid arrow" />
                  <div className="from">
                    <img src={marker} className="img-fluid logo" />
                    <input
                      type="text"
                      className="input"
                      placeholder="Flying to"
                    />
                  </div>
                </div>
                <div className="snd-sec">
                  <div className="from">
                    <Dropdown className="from-date-drop">
                      <Dropdown.Toggle className="">
                        <div className="d-flex align-items-center gap-2">
                          <img
                            src={calendar}
                            className="img-fluid datepicker-img"
                          />
                          <div className="d-flex flex-column align-items-start">
                            <p className="date mb-0 f-14">15 May 2024</p>
                            <p className="day mb-0 f-13 fw-500">Wednesday</p>
                          </div>
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          open={true}
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="to">
                    <Dropdown className="from-date-drop">
                      <Dropdown.Toggle className="">
                        <div className="d-flex align-items-center gap-2">
                          <img
                            src={calendar}
                            className="img-fluid datepicker-img"
                          />
                          <div className="d-flex flex-column align-items-start">
                            <p className="date mb-0 f-14">23 May 2024</p>
                            <p className="day mb-0 f-13 fw-500">Thursday</p>
                          </div>
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          open={true}
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="third-sec">
                  <div className="custom-left-drop">
                    <Dropdown className="passenger">
                      <Dropdown.Toggle className="">
                        <div className="d-flex align-items-center gap-2">
                          <img
                            src={passenger}
                            className="img-fluid passenger-logo"
                          />
                          <div className="d-flex flex-column align-items-start">
                            <p className="date mb-0 f-14">
                              1 Passenger, Economy
                            </p>
                          </div>
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div className="passengers-details">
                          <div className="pas-counts">
                            <span className="cmn-plus-minus">
                              <FaMinus size={13} />
                            </span>
                            <span className="pas-val f-14 fw-500">
                              1 Adult (age:2-10)
                            </span>
                            <span className="cmn-plus-minus">
                              <FaPlus className="minus" size={13} />
                            </span>
                          </div>
                          <div className="pas-counts">
                            <span className="cmn-plus-minus">
                              <FaMinus size={13} />
                            </span>
                            <span className="pas-val f-14 fw-500">
                              o Child (age:2-10)
                            </span>
                            <span className="cmn-plus-minus">
                              <FaPlus className="minus" size={13} />
                            </span>
                          </div>
                          <div className="pas-counts">
                            <span className="cmn-plus-minus">
                              <FaMinus size={13} />
                            </span>
                            <span className="pas-val f-14 fw-500">
                              0 Infant (age:2-10)
                            </span>
                            <span className="cmn-plus-minus">
                              <FaPlus className="minus" size={13} />
                            </span>
                          </div>
                          <div className="row mt-3">
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                              <Button className="cmn-passenger-btn cmn-btn">
                                Economy
                              </Button>
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                              <Button className="cmn-passenger-btn cmn-btn">
                                Premium Economy
                              </Button>
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                              <Button className="cmn-passenger-btn cmn-btn">
                                Business
                              </Button>
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                              <Button className="cmn-passenger-btn cmn-btn">
                                First Class
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="search-btn ">
                    <Button className="cmn-btn">SEARCH</Button>
                  </div>
                </div>
              </div>
            </div>
          ) : customTabs == "activities" ? (
            <div className="room-details search-page activities-tabs justify-content-between">
              <div className="filter-search filter-search_cu">
                <div className="city sec1">
                  <div className="city  cmn-sec">
                    <Dropdown className="area-city">
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="cmn-heading"
                      >
                        CITY, AREA OR PROPERTY
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="locaPick-drop">
                        <div className="cityArea-Property">
                          <div className="search mb-2">
                            <img src={search} className="img-fluid" />
                            <input
                              type="text"
                              className="searchInp"
                              placeholder="Search"
                              id="Searchdata"
                              value={formdata?.Searchdata}
                              onChange={onchange}
                            />
                          </div>
                          <p className="mb-2 f-15 fw-600">Cities</p>
                          <div className="loc">
                            <div className="loc-details mb-2">
                              <img
                                src={require("../assets/images/map.png")}
                                className="loc-logo img-fluid"
                              />
                              <span className="f-13 loc-txt gray fw-500">
                                Tirunelveli,Tamilnadu,India.
                              </span>
                            </div>
                            <div className="loc-details mb-2">
                              <img src={map} className="loc-logo img-fluid" />
                              <span className="f-13 loc-txt gray fw-500">
                                Madurai,Tamilnadu,India.
                              </span>
                            </div>
                            <div className="loc-details mb-2">
                              <img
                                src={require("../assets/images/map.png")}
                                className="loc-logo img-fluid"
                              />
                              <span className="f-13 loc-txt gray fw-500">
                                Chennai,Tamilnadu,India.
                              </span>
                            </div>
                          </div>
                          <div className="properties mt-4">
                            <p className="mb-2 f-15 fw-600">Properties</p>
                            <div>
                              <div className="prop-details mb-2">
                                <div className="d-flex align-items-center gap-2">
                                  <img
                                    src={bed}
                                    className="loc-logo img-fluid"
                                  />

                                  <div className="">
                                    <p className="mb-0 f-13 fw-500 d-flex align-items-center gap-1">
                                      <span className="purple fw-700">AMD</span>{" "}
                                      Hotel
                                      <span className="ratings">
                                        <FaStar className="star-filled" />
                                        <FaStar className="star-filled" />
                                        <FaStar className="star-filled" />
                                      </span>
                                    </p>
                                    <p className="prop-loc f-13 fw-500 mb-0">
                                      Filderstadt, DE
                                    </p>
                                  </div>
                                </div>
                                <div className="hotel fw-600 f-12 gray">
                                  Hotel
                                </div>
                              </div>
                              <div className="prop-details mb-2">
                                <div className="d-flex align-items-center gap-2">
                                  <img
                                    src={bed}
                                    className="loc-logo img-fluid"
                                  />

                                  <div className="">
                                    <p className="mb-0 f-13 fw-500 d-flex align-items-center gap-1">
                                      <span className="purple fw-700">AMD</span>{" "}
                                      Hotel
                                      <span className="ratings">
                                        <FaStar className="star-filled" />
                                        <FaStar className="star-filled" />
                                        <FaStar className="star-filled" />
                                      </span>
                                    </p>
                                    <p className="prop-loc f-13 fw-500 mb-0">
                                      Filderstadt, DE
                                    </p>
                                  </div>
                                </div>
                                <div className="hotel fw-600 f-12 gray">
                                  Hotel
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                    <p className="mb-0 f-14 fw-500">Dubai</p>
                  </div>
                  {/* <input
                    type="email"
                    placeholder="City, Property Name Or Location"
                    className="cred_input"
                    id="Searchdata"
                    value={formdata.Searchdata}
                    onChange={onchange}
                  /> */}{" "}
                  {Error?.Searchdata && (
                    <p className="text-left err_msg_txt">{Error?.Searchdata}</p>
                  )}
                  {/* <h3 className="loc fw-700 my-1">Dubai</h3> */}
                  {/* <p className="mb-0 fw-500 f-15">Dubai</p> */}
                </div>
              </div>
              <div className="check-in custom-datepicker-drop sec2">
                <div>
                  <Dropdown className="check-in-drop">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="cmn-heading"
                    >
                      Checkin
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        // open={true}
                        value={Date.now()}
                        onSelect={true}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                  <h6 className="mb-0 fw-700 mt-2 startEnd-date">Start Date</h6>
                </div>
              </div>
              <div className="check-in custom-datepicker-drop sec3">
                <div>
                  <Dropdown className="check-in-drop">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="cmn-heading"
                    >
                      Checkout
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        open={true}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                  <h6 className="mb-0 fw-700 mt-2 startEnd-date">End Date</h6>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* <div className="">
            <div className="trending-searches">
              <span className="header">Trending Searches:</span>
              <div className="countries">
                <span className="name">Singapore,Singapore</span>
                <span className="name">Bangkok,Thailand</span>
                <span className="name">Delhi,India</span>
              </div>
            </div>
          </div> */}
          <div
            className={
              // !isEmpty(formdata?.SelectedData)
                // ?
                 "total-search-btn"
                // : "total-search-btn disable"
            }
          >
            
            <Link
              to={
                customTabs == "activities" && !isEmpty(formdata?.Searchdata)
                  ? "/activities-search"
                  : `/search?searchdata=${EncryptData(formdata)}`
              }
            >
              {/* <Link> */}
              <Button className="">SEARCH</Button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />

      
    </div>
  );
}

export default Banner;
// {OfferstopData?.offers?.map((item, index) => {
//   return (
//     <div className="mb-4 col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
//       <div className="intro-card-sec">
//         <Card>
//           {OfferstopData?.image?.map((val, i) => {
//             console.log("wefreft",val);
//             <Card.Img src={`${config.IMG_URL}/cmsimages/${val}`} className="img-fluid" />
//           })}
//           <Card.Body>
//             <p className="mb-0 fw-800 f-15">{item?.Description}</p>
//             <p className="mb-0 f-13 lite-gray">{item?.Content}</p>
//           </Card.Body>
//         </Card>
//       </div>
//     </div>
//   );
// })}