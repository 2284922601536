import { AppenData, axiosFunc } from "../lib/common"
import config from "../lib/config"


/** Get Faqcategory list*/
export const Get_Faqcategory =async(data)=>{
    try {

        let formdata = AppenData(data)

        let SendData = {
            'method': 'GET',
            'url': `${config.Back_Url}/front/cms/faqcategory`,
            'data': formdata[0],

            'headers': {
                'Content-Type': 'application/json',
            },
            
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        console.log('AddLikeAction_err',error);
        return false

    }
}

/** Get Faq list */
export const Get_Faq =async(data)=>{
    try {
        let formdata = AppenData(data)


        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/cms/faq`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        console.log('AddLikeAction_err',error);
        return false

    }
}

/** Get CMS list */
export const GetCms =async(data)=>{
    try {
        let formdata = AppenData(data)


        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/cms/getcms`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        console.log('AddLikeAction_err',error);
        return false

    }
}

/** Get Blogs list */

export const Get_Blogs =async(data)=>{
    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/cms/blogs`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        console.log('AddLikeAction_err',error);
        return false

    }
}
/** Get Social Links*/

export const Getsociallinks  = async(data)=>{
    try {


        let SendData = {
            'method': 'GET',
            'url': `${config.Back_Url}/front/cms/sociallinks`,
            'headers': {
                'Content-Type': 'application/json',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp

        // let formdata = AppenData(data)
        // let SendData = {
        //     'method': 'POST',
        //     'url': `${config.Back_Url}/front/cms/sociallinks`,
        //     'data': formdata[0],
        //     'headers': {
        //         'Content-Type': 'multipart/form-data',
        //     }
        // }
        // let Resp = await axiosFunc(SendData)

        // return Resp


    } catch (error) {
        console.log('Getsociallinks_err',error);
        return false

    }
}

//offers
export const GetOffersDetails =async(data)=>{
    try {
        let formdata = AppenData(data)


        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/cms/getOffers`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        console.log('AddLikeAction_err',error);
        return false

    }
}

//Get Regions category
export const GetRegionsCategories =async(data)=>{
    try {
        // let formdata = AppenData(data)


        let SendData = {
            'method': 'GET',
            'url': `${config.Back_Url}/front/cms/getRegionsCategory`,
            // 'data': formdata[0],
            'headers': {
                'Content-Type': 'application/json',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        console.log('AddLikeAction_err',error);
        return false

    }
}

//Get Regions lists
export const GetRegionsLists =async(data)=>{
    try {
        let formdata = AppenData(data)


        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/front/cms/getRegionsLists`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        console.log('AddLikeAction_err',error);
        return false

    }
}