import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "../src/pages/Landing";
import Search from "./pages/Search";
import "./App.css";
import RoomDetails from "./pages/RoomDetails";
import ReviewBooking from "./pages/ReviewBooking";
import Account from "./pages/Account";
import PaymentOption from "./pages/PaymentOption";
import ConfirmBooking from "./pages/ConfirmBooking";
import CancellationPolicy from "./pages/CancellationPolicy";
import ViewBenefits from "./pages/ViewBenefits";
import ActivitiesSearch from "./pages/ActivitiesSearch";
import ActivityDetails from "./pages/ActivityDetails";
import ActivityReviewBooking from "./pages/ActivityReviewBooking";
import ActivityPaymentOption from "./pages/ActivityPaymentOption";
import ActivityConfirmBooking from "./pages/ActivityConfirmBooking";
import BookingConfirmation from "./components/BookingConfirmation";
import { loadStripe } from "@stripe/stripe-js";
import BlogDetails from "./pages/BlogDetails";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import WrongUrlpage from "./pages/404"
import { connectWallet } from "./pages/Contract";
import { toast } from "react-toastify"
import { LocalStoragefunc } from "./lib/common";

function App() {

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);
  const dispatch = useDispatch();


  const WalletAutoConnectfunc = async (address,accounttype) => {
    console.log("accouttnss",address,accounttype);
    if(address){
      let metamaskconnect = await connectWallet()
      const id = toast.loading("loading...")
  
      if (metamaskconnect?.address) {
        toast.update(id, { render: "Wallet Connected Successfully", type: "success", isLoading: false, autoClose: 1000 });
        LocalStoragefunc.setItem("account", metamaskconnect?.address)
        LocalStoragefunc.setItem("account_wallettype", accounttype)
  
        dispatch({
          type: "WalletConnectAddress",
          WalletConnectAddress: { ConnetedAddress: metamaskconnect?.address, web3: metamaskconnect?.web3 }
        })
      }
    }
   
  }


  useEffect(() => {

      WalletAutoConnectfunc(LocalStoragefunc?.getItem("account"),LocalStoragefunc?.getItem("account_wallettype"))

  }, [])



  //   const [exchangeRate, setExchangeRate] = useState(null); // State to hold the exchange rate
  // console.log("exchangeRate",exchangeRate);
  //   useEffect(() => {
  //     const fetchExchangeRate = async () => {
  //       try {
  //         const response = await fetch("https://open.er-api.com/v6/latest/INR");
  //         const data = await response.json();
  //         setExchangeRate(data?.rates?.USD);
  //         dispatch({
  //           type: "CurrencyConvert",
  //           CurrencyConvert: data?.rates?.USD 


  //         });
  //       } catch (error) {
  //         // toast.error("Failed to fetch exchange rate");
  //       }
  //     };

  //     fetchExchangeRate();
  //   }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Landing />} />
        {/* <Route exact path="/booking-confirm" element={<BookingConfirmation />} /> */}
        <Route exact path="/search" element={<Search />} />
        <Route exact path="/activities-search" element={<ActivitiesSearch />} />
        <Route exact path="/roomDetails" element={<RoomDetails />} />
        <Route exact path="/activityDetails" element={<ActivityDetails />} />
        <Route exact path="/activityReviewBooking" element={<ActivityReviewBooking />} />

        <Route exact path="/activityPaymentOption" element={<ActivityPaymentOption />} />
        <Route exact path="/activityConfirmBooking" element={<ActivityConfirmBooking />} />



        <Route exact path="/reviewBooking" element={<ReviewBooking />} />
        <Route exact path="/account" element={<Account stripe={stripePromise} />} />
        <Route exact path="/paymentOption" element={<PaymentOption stripe={stripePromise} />} />
        <Route exact path="/confirmBooking" element={<ConfirmBooking />} />
        <Route exact path="/cms" element={<CancellationPolicy />} />
        <Route exact path="/viewBenefits" element={<ViewBenefits />} />
        <Route exact path="/blog-details" element={<BlogDetails />} />
        <Route exact path="*" element={<WrongUrlpage />} />





      </Routes>
    </BrowserRouter>
  );
}

export default App;
