import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FaInfoCircle } from "react-icons/fa";
import Select from "react-select";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import payone from "../assets/images/book/payone.png"
import phone from "../assets/images/book/phone.png"
import ReservationModal from "../modals/ReservationModal";
import StripepaymentModal from "../modals/Strpepaymentmodel";
import NowpaymentModal from "../modals/NowPaymentModal";
import Walletpayment from "../modals/Walletpayment"
// import NowpaymentModal from "../modals/NowPaymentModal";
import * as Hotels from "../Axios/hotel.axios";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";



function Paymenttab(props) {
  // const  {CurrencyConvert}  = useSelector((state) => state.LoginReducer);
  // console.log("InrTouseddddds345",CurrencyConvert);
  const [completeReservation, SetCompleteReservation] = useState(false);
  const [Stripepay, SetStripepay] = useState(false);
  const [NowPayment, SetNowpayment] = useState(false);
  const [Currencies, SetCurrencies] = useState([]);
  const [TotalAmount, setTotalAmount] = useState(0);
  const [Commissions, setCommissions] = useState(0);
  const initial = {
    Currency: "",
    // Network:""
  };
  const [formdata, setFormdata] = useState(initial);
  const [err, setErr] = useState({});
  const [Paymentmethod, setpaymentmethod] = useState('');
  const [Client, setClient] = useState({});
  const [loader, setloader] = useState(false);
  const [Bookingid, setBookingid] = useState('');
  const navigate = useNavigate();

  const [Walletpay, setWalletpay] = useState(false);




  const handleReservationClose = () => {
    SetCompleteReservation(false)
  }

  const handleReservationShow = (data) => {
    console.log("dtaaaaaa",data);
    SetCompleteReservation(true)
    setpaymentmethod(data)

  }
  const handleStrpieClose = () => {
    SetStripepay(false)
  }

  const handleStrpieShow = () => {
    SetStripepay(true)
  }
  const handleNowpayClose = () => {
    SetNowpayment(false)
  }
  const handleNowpayOpen = () => {
    SetNowpayment(true)
  }

  const handleWalletpayClose = () => {
    setWalletpay(false)
  }
  const handleWalletpayOpen = () => {
    setWalletpay(true)
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);

  const options2 = [
    { value: 'Mainnet', label: 'MAINNET' },


  ];
  useEffect(() => {
    Totalamountcalculation()
  })


  const Totalamountcalculation = async () => {
    var { status, data, msg } = await Hotels.Commissions({ Path: 'get' })
    let HotelCommissions = data.filter(val => val?.Type == 'Hotels')[0]
    let Percentage = (HotelCommissions?.Percentage / 100) * props?.hoteldata?.ops?.[0]?.tp
    let TotalnetAmount = props?.hoteldata?.ops?.[0]?.tp + Percentage;
    setCommissions(parseInt(Percentage)?.toFixed(2))
    setTotalAmount(parseInt(TotalnetAmount)?.toFixed(2));
  };




  // useEffect(()=>{
  //    NowPaymentAvailablecurrecy()

  //   },[])


  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis leo nisi, blandit a euismod ut, euismod ut eros. Duis quam urna, luctus id facilisis nec, condimentum nec nisi.
    </Tooltip>
  );


  const OpenPopup = async (data) => {
    //    if(data=='Card'){
    //     handleReservationShow(data)
    //     setErr({});

    //    }
    //    else{

    //   let err = await validation(Object.keys(initial), formdata);
    //   if (Object.keys(err)?.length > 0) {
    //     setErr(err);
    //     setloader(false)
    //     return;
    //   }
    //   else{
    //     setErr({});
    //     handleReservationShow(data)


    //   }
    // }

    handleReservationShow(data)
    setErr({});
  }
  return (
    <div className="container custom-container px-0">
      {
        completeReservation && props &&
        <ReservationModal
          show={completeReservation}
          handleClose={handleReservationClose}
          Data={props}
          setClient={(e) => setClient(e)}
          handleStrpieShow={handleStrpieShow}
          Paymentmethod={Paymentmethod}
          handleNowpayment={handleNowpayOpen}
          setBookingid={setBookingid}
          Totalamount={TotalAmount}
          Commission={Commissions}
        />
      }
      {props?.Stripe && Client?.client_secret && Stripepay && Paymentmethod &&
        <StripepaymentModal
          show={Stripepay}
          handleClose={handleStrpieClose}
          Data={props.Stripe}
          Client={Client.client_secret}
          Travellerdata={{ name: props.travellerdata[0]?.travellerInfo?.[0].fN + props.travellerdata[0]?.travellerInfo?.[0].lN, phone: props.travellerdata[0]?.Contact?.MobileNumber }}


        />
      }
      {Paymentmethod && NowPayment && Bookingid &&
        <NowpaymentModal
          show={NowPayment}
          handleClose={handleNowpayClose}
          Bookingid={Bookingid}
          Amount={TotalAmount}
          Pricecurrency={'usd'}


        />
      }

      {Paymentmethod && Walletpay &&
        <Walletpayment
          show={Walletpay}
          handleClose={handleWalletpayOpen}
          Bookingid={Bookingid}
          Amount={TotalAmount}
          Travellerdata={{ name: props.travellerdata[0]?.travellerInfo?.[0].fN + props.travellerdata[0]?.travellerInfo?.[0].lN, phone: props.travellerdata[0]?.Contact?.MobileNumber }}



        />
      }
      {/* {  props?.Stripe && Client?.client_secret &&
 
 (             <Elements
                            stripe={props?.Stripe}
                            options={{
                              clientSecret: Client?.client_secret,
                              appearance: {
                                theme: "flat",
                                variables: { colorPrimaryText: "#262626" },
                              },
                            }}
                          >
                            <Payment
                            Datas={props}
                              // submitcall={submitcall}
                              // Setsubmitcall={Setsubmitcall}
                      
                              // SetDisabledstatus={SetDisabledstatus}
                            />
                          </Elements>
                        )} */}


      <div>

        <div className="payment_custom_tabs mt-0">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="one"
            className=""
          >
            <div className="row mx-0">
              <div className="col-12 col-sm-12 col-md-4 mb-4 mb-sm-0 px-0">
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="one">
                      <div className="d-flex align-items-start">
                        <div className="pay_icon_div">
                          <img src={payone} className="pay_icon me-3" />
                        </div>
                        <div>
                          <p className="crypto_head mb-1">Card Payment</p>
                          <p className="crypto_desc mb-0">Tripmoney, Lazypay, Simpl, ZestMoney, ICICI, HDFC</p>
                        </div>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="two">
                      <div className="d-flex align-items-start">
                        <div className="pay_icon_div">
                          <img src={payone} className="pay_icon me-3" />
                        </div>
                        <div>
                          <p className="crypto_head mb-1">Crypto Payment</p>
                          <p className="crypto_desc mb-0">Tripmoney, Lazypay, Simpl, ZestMoney, ICICI, HDFC</p>
                        </div>
                      </div>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link eventKey="three">
                      <div className="d-flex align-items-start">
                        <div className="pay_icon_div">
                          <img src={payone} className="pay_icon me-3" />
                        </div>
                        <div>
                          <p className="crypto_head mb-1">Wallet Payment</p>
                          <p className="crypto_desc mb-0">Tripmoney, Lazypay, Simpl, ZestMoney, ICICI, HDFC</p>
                        </div>
                      </div>
                    </Nav.Link>
                  </Nav.Item>

                </Nav>

              </div>
              <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 mt-3 mt-md-0">
                <Tab.Content>
                  <Tab.Pane eventKey="one">
                    <div className="content-sec">
                      <p>
                        <img src={phone} className="me-2" />
                        <span className="room_dark_purple">Keep your phone handy!</span>
                      </p>

                      <div className="card card_book mb-3">



                        <div className="px-3">

                          <Form>


                            <Row>
                            </Row>
                            {/* <p className="payment_desc_form mt-3"><span>Cryptocurrency Refund Policy:</span> Due to price volatility and regulatory requirements, refunds
                              (if applicable) will be processed in Travel Credits and credited to your TripFi.io account.</p> */}

                          </Form>


                        </div>


                      </div>

                      <p className="room_dark">Partial payment
                        {/* <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip}
                        >
                          <Button variant="success" className="tooltip_btn_trans">
                            <FaInfoCircle className="mx-2" />
                          </Button>
                        </OverlayTrigger> */}
                      </p>

                      <p className="payment_desc_form"><span>You don't have enough Travel Credits, Promo Credits, AVA in your account. Send TripDAO to
                        your account or top up your Travel Credits.</span></p>
                      <p className="room_dark_footer_bold mb-0">
                        ₹{parseInt(TotalAmount)}
                        {/* <a className="purple_a_tag ms-2">Due Now</a> */}
                      </p>

                      <p className="room_dark mt-3">By completing this booking, you agree to the Booking Conditions,
                        Terms and Conditions, and Privacy Policy.</p>

                      <Button to={props?.type == "Rooms" ? "/confirmBooking" : "/activityConfirmBooking"} className={`cmn-btn pay-btn btn btn-primary mt-2 mb-4 text-uppercase ${!props?.Button ? 'disabled cmn-btn mt-2 mb-4' : ""}`} onClick={() => props?.Button ? OpenPopup('Card') : null}>Complete Reservation</Button>



                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="two">
                    <div className="content-sec">
                      <p>
                        <img src={phone} className="me-2" />
                        <span className="room_dark_purple">Keep your phone handy!</span>
                      </p>


                      <p className="room_dark">Partial payment
                      
                      </p>

                      <p className="payment_desc_form"><span>You don't have enough Travel Credits, Promo Credits, AVA in your account. Send TripDAO to
                        your account or top up your Travel Credits.</span></p>
                      <p className="room_dark_footer_bold mb-0">
                        ₹{parseInt(TotalAmount)}
                        {/* <a className="purple_a_tag ms-2">Due Now</a> */}
                      </p>

                      <p className="room_dark mt-3">By completing this booking, you agree to the Booking Conditions,
                        Terms and Conditions, and Privacy Policy.</p>


                      <Button to={props?.type == "Rooms" ? "/confirmBooking" : "/activityConfirmBooking"} className={`cmn-btn pay-btn btn btn-primary mt-2 mb-4 text-uppercase ${!props?.Button ? 'disabled cmn-btn mt-2 mb-4' : ""}`} onClick={() => props?.Button ? OpenPopup('Crypto') : null}>Complete Reservation</Button>



                    </div>
                  </Tab.Pane>
             
                  <Tab.Pane eventKey="three">
                    <div className="content-sec">
                      <p>
                        <img src={phone} className="me-2" />
                        <span className="room_dark_purple">Keep your phone handy!</span>
                      </p>


                      <p className="room_dark">Partial payment
                      
                      </p>

                      <p className="payment_desc_form"><span>You don't have enough Travel Credits, Promo Credits, AVA in your account. Send TripDAO to
                        your account or top up your Travel Credits.</span></p>
                      <p className="room_dark_footer_bold mb-0">
                        ₹{parseInt(TotalAmount)}
                        {/* <a className="purple_a_tag ms-2">Due Now</a> */}
                      </p>

                      <p className="room_dark mt-3">By completing this booking, you agree to the Booking Conditions,
                        Terms and Conditions, and Privacy Policy.</p>


                      <Button to={props?.type == "Rooms" ? "/confirmBooking" : "/activityConfirmBooking"} className={`cmn-btn pay-btn btn btn-primary mt-2 mb-4 text-uppercase ${!props?.Button ? 'disabled cmn-btn mt-2 mb-4' : ""}`} onClick={() => props?.Button ? OpenPopup('Wallet') : null}>Complete Reservation</Button>



                    </div>
                  </Tab.Pane>
             
                </Tab.Content>
              </div>

            </div>
          </Tab.Container>
        </div>


      </div>
    </div>
  );
}

export default Paymenttab;
