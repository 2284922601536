import { combineReducers } from "redux";
import { Register_Section, Logout, Hotels, WalletConnectAddress } from "./constants";

const Initial_State = {

  User: {
    token: '',
    UserDetails: '',
  },

}
const Hotel_Initial_State = []
const Wallet_connect_initial_state = []


function LoginReducer(state = Initial_State, action) {
  console.log("actionaction", action);
  switch (action.type) {

    case Register_Section:
      return {
        ...state,
        ...action.Register_Section
      }
    case "CurrencyConvert":
      return {
        ...state, ...action
      }
    case Logout:
      return Initial_State


    default:
      return state;
  }
}
function HotelReducer(state = Hotel_Initial_State, action) {
  // console.log("actionaction",action);
  switch (action.type) {

    case Hotels:
      return [...action.Hotels]

    default:
      return state;
  }
}

function WalletConnect(state = Wallet_connect_initial_state, action) {
  console.log("Wallet_connect_initial_state", action, state);
  switch (action.type) {

    case WalletConnectAddress:
      return { ...action.WalletConnectAddress }

    default:
      return state;
  }
}





const PieTrip = combineReducers({ LoginReducer: LoginReducer, HotelReducer: HotelReducer, WalletConnect: WalletConnect });

export default PieTrip;