import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Dropdown from "react-bootstrap/Dropdown";

import Offcanvas from "react-bootstrap/Offcanvas";

import logo from "../assets/images/logo.png";
import stay from "../assets/images/stay.svg";
import wallet_blue from "../assets/images/wallet_blue.svg";
import credits_blue from "../assets/images/credits_blue.svg";
import trip_dao from "../assets/images/trip_dao.svg";
import bulb from "../assets/images/bulb.png"


import wallet from "../assets/images/wallet.png";
import profile from "../assets/images/profile.png"

import flight from "../assets/images/flight_color.png";
import activities from "../assets/images/activities_color.png";
import alltravelagent from "../assets/images/all_travel_agent_color.png";
import rewards from "../assets/images/rewards.png";
import flag from "../assets/images/flag.png";
import LoginModal from "../modals/LoginModal";
import SignupModal from "../modals/SignupModal";
import { LoadToast, LocalStoragefunc, Name_showing, isEmpty, updateToast } from "../lib/common";
import * as User from "../Axios/user.axios";
import { useDispatch, useSelector } from "react-redux";
import ForgotEmail from "../modals/ForgotEmail";
import VerifyOtp from "../modals/Verifyotp"
import { toast } from "react-toastify";
import Otpmail from "../modals/Otpmail";
import ResetPassword from "../modals/ResetPassword";
import { GetUserCookieToken } from "../Axios/hotel.axios";
import config from "../lib/config"
import ConnectWalletModal from "../modals/ConnectWalletModal";




export default function Header() {
  const { ConnetedAddress, web3 } = useSelector((state) => state?.WalletConnect)
  console.log("vvvvvvvvv", ConnetedAddress, web3);

  const [loginShow, setLoginShow] = useState(false);
  const [signupShow, setSignupShow] = useState(false);
  const [showForgotEmail, setShowForgotEmail] = useState(false);
  const [showverifyotp, setshowverifyotp] = useState(false);
  const [usermail, setusermail] = useState("");
  const [showOtpEmail, setShowOtpEmail] = useState(false);
  const [OTP, setOTP] = useState("");
  const [Time, setTime] = useState("");
  const [wallletShow, setWalletShow] = useState(false);

  const navigate = useNavigate();





  const handleLoginClose = () => setLoginShow(false);
  const handleLoginShow = () => setLoginShow(true);
  const handleSignupClose = () => setSignupShow(false);
  const handleSignupShow = () => setSignupShow(true);
  const handleForgotEmailClose = () => setShowForgotEmail(false)
  const handleVerifyotpClose = () => setshowverifyotp(false)
  const handleVerifyotpShow = () => setshowverifyotp(true)


  const handleCloseWallet = () => {
    setWalletShow(false);
  };

  const handleShowWallet = () => {
    setWalletShow(true);
  };

  const handleOtpEmailShow = () => {
    setShowOtpEmail(true);

  };
  const handleOtpEmailClose = () => {
    setShowOtpEmail(false);
  };

  const [showResetPassword, setShowResetPassword] = useState(false);
  const handleResetPasswordClose = () => setShowResetPassword(false);
  const handleResetPasswordShow = () => {
    handleOtpEmailClose();
    setShowResetPassword(true);
  };



  const dispatch = useDispatch();

  const { UserDetails } = useSelector((state) => state.LoginReducer.User);

  useEffect(() => {
    if (
      localStorage.getItem("userInfo")
    ) {

      Userlogin(localStorage.getItem("userInfo"), localStorage.getItem("logintype"));
    }


  }, []);

  const Userlogin = async (email, logintype) => {
    // const id = LoadToast();
    let senddata = {
      Email: email,
      Type: "userdata",
      logintype: "logintype",
    }
    let { status, msg, token, data } = await User.Login(senddata);
    // updateToast(
    //   id,
    //   msg,
    //   success
    // );
    if (status == 'success') {
      localStorage.setItem("userInfo", data.Email);
      document.cookie = "token" + "=" + token + ";" + ";path=/";

      dispatch({
        type: "Register_Section",
        Register_Section: {
          User: {
            UserDetails: data,
            token: token,
          },
        },
      });
      GetUserCookieToken()


      // history.push("/dashboard");
    };

  }

  const Logout = async () => {
    localStorage?.removeItem("userInfo");
    localStorage?.removeItem("logintype");
    dispatch({
      type: "Logout",

    });
    navigate('/')
    toast.success('Logout Successfully')

  }



  const onDisconnect = () => {
    LocalStoragefunc.removeItem("account")
    LocalStoragefunc.removeItem("account_wallettype")

    dispatch({
      type: "WalletConnectAddress",
      WalletConnectAddress: { ConnetedAddress: "", web3: {} }
    })
    toast.success("Wallet disconnected")
  }


  return (
    <>
      {/* start of modals */}
      {loginShow &&
        <LoginModal
          loginShow={loginShow}
          handleLoginClose={handleLoginClose}
          handleForgotEmail={setShowForgotEmail}
          handleSignup={setSignupShow}
          handleotp={setShowOtpEmail}
          UserEmail={setusermail}
          setotp={setOTP}

        />
      }


      {signupShow &&
        <SignupModal
          signupShow={signupShow}
          handleSignupClose={handleSignupClose}
          handleLogin={setLoginShow}
          handleotp={setShowOtpEmail}
          UserEmail={setusermail}
          setotp={setOTP}


        />
      }
      {console.log("TimeTimeTime", Time)}

      {showForgotEmail &&
        <ForgotEmail
          showForgotEmail={showForgotEmail}
          handleForgotEmailClose={handleForgotEmailClose}
          handleLogin={setLoginShow}
          showOtpEmail={handleOtpEmailShow}
          UserEmail={setusermail}
          setTime={setTime}


        />
      }
      {showverifyotp &&
        <VerifyOtp
          showVerifyotp={showverifyotp}
          handleVerifyotpClose={handleVerifyotpClose}
          handleForgotEmailClose={handleForgotEmailClose}
          UserEmail={usermail}



        />
      }
      {showOtpEmail &&
        <Otpmail
          showOtpEmail={showOtpEmail}
          handleOtpEmailClose={handleOtpEmailClose}
          handleForgotEmailClose={handleForgotEmailClose}
          UserEmail={usermail}
          showResetPassword={handleResetPasswordShow}
          Registerotp={OTP}
          // handlesignup={setSignupShow}
          handlelogin={setLoginShow}
          Time={Time}

        />}

      {showResetPassword && (
        <ResetPassword
          showResetPassword={showResetPassword}
          handleResetPasswordClose={handleResetPasswordClose}
          handleLoginShow={handleLoginShow}
          UserEmail={usermail}

        />

      )}


      {

        wallletShow &&
        <ConnectWalletModal

          show={wallletShow}
          handleClose={handleCloseWallet}
        />
      }


      {/* end of modals */}
      <Navbar expand="xl" className="bg-body-tertiary mb-0 header" fixed="top">
        <Container className="custom_container">
          <Navbar.Brand href="#">
            <Link to="/">
              <img src={logo} className="img-fluid" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-xl`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-xl`}
            aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
            placement="end"
            className="navbar-offcanvas"
          >
            <Offcanvas.Header closeButton>
              <img src={logo} className="img-fluid mobile_logo" />
            </Offcanvas.Header>

            <Offcanvas.Body>
              <Nav className="justify-content-start flex-grow-1 pe-3 nav_bar">
                <Link to="/" className="nav-link">
                  <img src={stay} className="pe-2" />
                  Stay
                </Link>
                <Nav.Link to="/">
                  <img src={flight} className="pe-2" />
                  Flight
                </Nav.Link>

                <Link to="/activities-search" className="nav-link">
                  <img src={activities} className="pe-2" />
                  Activities
                </Link>

                {/* <Nav.Link to="/account"> */}
                {!isEmpty(UserDetails) &&
                  <Link to="/account" className="nav-link">

                    <img src={alltravelagent} className="pe-2" />


                    {/* Ai Travel Agent */}
                    Account
                  </Link>}
                {/* </Nav.Link> */}
              </Nav>
              <div className="flex_content_btn_header">
                <div className="text-start text-xl-center">
                  <div className="badge badge_purple">
                    <img src={rewards} />
                    <span>TripyAi</span>
                    {/* <span>Trip DAO</span> */}

                  </div>
                  {/* <p className="trip_text mt-1">$Trip Smart Program</p> */}
                  <p className="trip_text mt-1">Automated Services</p>

                </div>

                <div className="bar_text"></div>
                {/* {!isEmpty(UserDetails) ?
                  <div>
                    <button className="trans_btn">
                      <img src={flag} />
                      USD
                    </button>
                  </div>
                  : ""
                } */}


                {/* <div className="bar_text"></div> */}
                {
                  !isEmpty(UserDetails) ?
                    <>

                      {/* <div>
                    <button className="trans_btn">
                      <img src={flag} />
                      USD
                    </button>
                  </div>
                <div className="bar_text"></div>

                      <div>
                        <img src={wallet} className="pe-2" />
                        <span className="wallet_balance">₹0</span>
                      </div>
                      <div className="bar_text"></div> */}



                      <Dropdown className="acc_dropdown">
                        {/* <div className="bulb_text">
                <img src={bulb} />
                </div> */}
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          align="end"
                          className="cmn-heading"
                        >
                          <div>
                            {isEmpty(UserDetails) ?

                              <img src={profile} className="nav_profileImg" />
                              :
                              <img src={`${config?.IMG_URL}/user/${UserDetails?._id}/profile/${UserDetails?.Profile}`} className="nav_profileImg" />}

                            <span className="acc_name">{Name_showing(UserDetails.FirstName)}</span>
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="locaPick-drop header_menu_dd">
                          <div className="cityArea-Property">

                            <div className="loc">

                              <div className="loc-details">
                                <Link to="/account">

                                  <span className="f-13 loc-txt gray fw-600">
                                    My Account
                                  </span>
                                </Link>
                              </div>

                              {/* <div className="loc-details mb-2">
                <Link to="/account">
                 
                 <span className="f-13 loc-txt gray fw-600">
                  My Bookings
                 </span>
                 </Link>
               </div> */}


                              {/* <div className="loc-details mb-2">
               <Link to="/">
                 <span className="f-13 loc-txt gray fw-600">
                  Coming Soon
                 </span>
                 </Link>
               </div> */}


                              {/* <div className="loc-details mb-2">
               <Link to="/account">
                 <span className="f-13 loc-txt gray fw-600">
                  My Wishlist
                 </span>
                 </Link>
               </div> */}


                              {/* <div className="loc-details mb-2">
               <Link to="/">
                 
                 <span className="f-13 loc-txt gray fw-600">
                 Vote
                 </span>
                 </Link>
               </div> */}

                              <hr className="my-2" />



                              <div className="loc-details mb-2">
                                <Link to="/">
                                  <img
                                    src={trip_dao}
                                    className="loc-logo img-fluid"
                                  />
                                  <span className="f-13 loc-txt gray fw-600 ms-2">
                                    {/* Trip DAO */}
                                    TripyAi
                                  </span>
                                </Link>
                              </div>
                              <div className="f-13 loc-txt gray fw-600 ms-2">
                                {ConnetedAddress ? ConnetedAddress?.length > 15 ? ConnetedAddress?.slice(0, 15) + "..." : ConnetedAddress : ""}
                              </div>
                              {ConnetedAddress && <Button className="cmn-btn mb-2 mt-0" onClick={() => onDisconnect()}>

                                Disconnect

                              </Button>}
                              {!ConnetedAddress && <Button className="cmn-btn mb-2 mt-0" onClick={handleShowWallet}>

                                Connect Wallet

                              </Button>}

                              <div className="loc-details mb-2">
                                <Link to="/">
                                  <img
                                    src={wallet_blue}
                                    className="loc-logo img-fluid"
                                  />
                                  <span className="f-13 loc-txt gray fw-600 ms-2">
                                    My Wallet
                                  </span>
                                </Link>
                              </div>

                              <div className="loc-details mb-2">
                                <Link to="/">
                                  <img
                                    src={credits_blue}
                                    className="loc-logo img-fluid"
                                  />
                                  <span className="f-13 loc-txt gray fw-600 ms-2">
                                    My Credits
                                  </span>
                                </Link>
                              </div>
                              <div className="loc-details mb-2">
                                <Link to="/account?tabname=WishList">
                                  <img
                                    src={credits_blue}
                                    className="loc-logo img-fluid"
                                  />
                                  <span className="f-13 loc-txt gray fw-600 ms-2">
                                    My Wishlist
                                  </span>
                                </Link>
                              </div>

                              <Button className="cmn-btn mt-0" onClick={() => Logout()}>
                                {/* <Link to="/reviewBooking"> */}
                                Logout
                                {/* </Link> */}
                              </Button>
                            </div>

                          </div>
                        </Dropdown.Menu>
                      </Dropdown>

                    </>

                    :
                    <>
                      <Button
                        className="btn btn_border_white"
                        onClick={() => handleLoginShow()}
                      >
                        Login
                      </Button>

                      {isEmpty(UserDetails) &&
                        <Button
                          className="btn btn_border_purple sign-up-btn"
                          onClick={() => handleSignupShow()}
                        >
                          Signup
                        </Button>}
                    </>

                }






              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}
