import React, { useState, useEffect, useRef } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/esm/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Col, Container, Row, Form, ProgressBar } from "react-bootstrap";
import Select from "react-select";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "swiper/css";
import "swiper/css/navigation";

import InclusionModal from "../modals/InclusionModal";
import InformationModal from "../modals/InformationModal";

import { FaCheck, FaPlus, FaInfoCircle, FaArrowRight } from "react-icons/fa";

import CouponCard from "../components/couponCard";

import bookroom from "../assets/images/book/bookroom.png";
import successdollar from "../assets/images/book/successdollar.png";
import successtick from "../assets/images/book/successtick.png";
import dangertimer from "../assets/images/book/dangertimer.png";

import trip from "../assets/images/book/trip.png";
import * as Hotels from "../Axios/hotel.axios";
import {
  DateTimeForm,
  Decryptdata,
  Describtion_showing,
  EncryptData,
  LoadToast,
  NightdaysCalculation,
  isEmpty,
  updateToast,
  validation,
} from "../lib/common";
import Fecility from "../modals/fecilitesModel";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import NodataFound from "../components/NodataFound";

export default function ReviewBooking() {
  const { CurrencyConvert } = useSelector((state) => state.LoginReducer);
  console.log("InrTouseddddds234", CurrencyConvert);
  const [value, setValue] = useState();
  const [count, setCount] = useState(1);
  const [inclusionShow, setInclusionShow] = useState(false);
  const [informationShow, setInformationShow] = useState(false);
  const [selectedOption1, setSelectedOption1] = useState("Mr");
  const [HotelData, setHotelData] = useState(false);
  const [SelectedData, setSelcetedRooms] = useState([]);
  const [Fecilities, setFecility] = useState("");
  const [type, settype] = useState("");
  const [discount, setdiscount] = useState(0);
  const [Donateamount, setDonateamount] = useState(100);
  const [Donateamountstatus, setDonateamountstatus] = useState(false);
  const [Err, setErr] = useState([]);
  const [amenitiesShow, setAmenitiesShow] = useState(false);
  const [TotalAmount, setTotalAmount] = useState(0);
  const [Commissions, setCommissions] = useState(0);

  const [Country, setcountry] = useState(false);
  const scrollTop = useRef(null);

  const handleAmenitiesClose = () => setAmenitiesShow(false);
  const handleAmenitiesShow = (data, type) => {
    setFecility(data);
    setAmenitiesShow(true);
    settype(type);
  };

  /** Models*/
  const handleInclusionClose = () => setInclusionShow(false);
  const handleInclusionShow = () => setInclusionShow(true);
  const handleInformationClose = () => setInformationShow(false);
  const handleInformationShow = () => setInformationShow(true);

  /**Navigation */
  const location = useLocation();
  const navigate = useNavigate();

  /**Props Data Get */
  const searchParams = new URLSearchParams(location?.search);
  const Hotelid = searchParams.get("hotelid");
  const Roomid = searchParams.get("roomid");
  const paramsData = searchParams.get("searchdata");
  const SearchData = Decryptdata(paramsData);
  const Selectedroomindex = searchParams.get("index");
  const { UserDetails } = useSelector((state) => state.LoginReducer.User);

  useEffect(() => {
    localStorage.setItem("payment", "")
  }, [])


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);

  const initial = {
    Guesttype: "",
    Title: "",
    FirstName: "",
    LastName: "",
    Email: "",
    MobileNumber: "",
    Hotelid: Hotelid,
    Roomid: Roomid,
  };

  const object = {
    fN: "",
    lN: "",
    ti: "",
    pt: "",
    // pan: "",
  };
  const [formdata, setFormdata] = useState([]);

  const [coupons, setCoupons] = useState([
    {
      title: "WELCOMEPT",
      description:
        "Congratulations! Discount of INR 489 applied for your 1st hotel booking on TripFi. Use Code WELCOME20 to Book With 0 Payment",
      price: "₹ 1200",
    },
    {
      title: "TRIPFIFEAST",
      description:
        "Exclusive Offer on Citi Credit & Debit Cards. Get Flat INR 442 off",
      price: "₹ 1442",
    },
    {
      title: "PTBOBDC",
      description:
        "Exclusive Offer on Bank of Baroda Debit Cards. Get INR412 Off",
      price: "₹ 812",
    },
    {
      title: "PTBESTBUY",
      description: "Get INR 286 Off",
      price: "₹ 286",
    },
    {
      title: "PTBIG5G",
      description: "EMI Offer on Payment using Credit Cards",
      price: "₹ 20",
    },
  ]);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis leo nisi,
      blandit a euismod ut, euismod ut eros. Duis quam urna, luctus id facilisis
      nec, condimentum nec nisi.
    </Tooltip>
  );

  const renderTooltip1 = (props) => (
    <Tooltip id="button-tooltip1" {...props}>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis leo nisi,
      blandit a euismod ut, euismod ut eros. Duis quam urna, luctus id facilisis
      nec, condimentum nec nisi.
    </Tooltip>
  );

  const options = [
    { value: "Mr", label: "Mr" },
    { value: "Miss", label: "Miss" },
    { value: "Mrs", label: "Mrs" },
  ];

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      return {
        ...styles,
        backgroundColor: isHovered
          ? "#653883"
          : isSelected
            ? "#653883"
            : isFocused
              ? "#653883"
              : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
            ? "#fff"
            : isFocused
              ? "#fff"
              : "",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "38px",
      padding: "0px 10px",
      border: "none",
      backgroundColor: "#fff",
      color: "#000",
      borderColor: "#BEBEBE",
      borderRadius: "4px",
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: "4px",
      backgroundColor: "transparent",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "red",
      padding: "0px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#000",
    }),
    menuList: (base) => ({
      ...base,

      padding: 0,
      width: "100%",

      borderRadius: 5,
      background: "transparent",
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#000",
      };
    },
  };

  const handleChange1 = (selectedOption) => {
    setSelectedOption1(selectedOption);
  };

  useEffect(() => {
    HotelDetails();
  }, [location]);

  /** Particular Hotel and Room  Details*/

  const HotelDetails = async () => {
    let { success, data } = await Hotels.Hotel_Details({ id: Hotelid });
    setHotelData(data?.hotel);

    setSelcetedRooms(data?.hotel?.ops[parseInt(Selectedroomindex)]);
  };

  useEffect(() => {
    if (SelectedData) {
      Totalamountcalculation();
    }
  }, [Donateamountstatus, SelectedData]);

  const Totalamountcalculation = async () => {
    var { status, data, msg } = await Hotels?.Commissions({ Path: 'get' })
    let HotelCommissions = data?.filter(val => val?.Type == 'Hotels')[0]
    let Percentage = (HotelCommissions?.Percentage / 100) * SelectedData?.tp
    let TotalnetAmount = SelectedData?.tp + Percentage;
    setCommissions(parseInt(Percentage)?.toFixed(2))
    setTotalAmount(parseInt(TotalnetAmount));
  };

  useEffect(() => {
    let Arr = [];
    let Obj = object
    if (Country) Obj.pNum = ''
    for (let i = 0; i < SearchData?.roomInfo?.length; i++) {
      let members =
        SearchData?.roomInfo[i].numberOfAdults +
        SearchData?.roomInfo[i].numberOfChild;

      Arr.push({
        travellerInfo: [],
        Contact: { MobileNumber: "", Email: "", Code: "+91" },
      });
      {
        [...Array(members)].map((value) => {
          Arr[Arr?.length - 1].travellerInfo.push({ ...Obj });
        });
      }
    }

    setFormdata(Arr);
  }, [Country]);

  useEffect(() => {
    GetCurrentlocation()
  }, [])

  const onchange = (id, value, index, idx, pt) => {
    setErr({});
    let data = [...formdata];

    if (pt == "CHILD") {
      data[index].travellerInfo[idx] = {
        ...data[index].travellerInfo[idx],
        ...{ [id]: value, ["pt"]: "CHILD", ["ti"]: "Master" },
      };
    } else if (pt == "ADULT") {
      data[index].travellerInfo[idx] = {
        ...data[index].travellerInfo[idx],
        ...{ [id]: value, ["pt"]: "ADULT" },
      };
    } else if (id == "Email" || id == "MobileNumber" || id == "Code") {
      data[index].Contact[id] = value;
    }

    setFormdata([...data]);
  };

  const FormSubmit = async () => {

    if (isEmpty(UserDetails)) {
      return toast.error('Please Login First....!')
    }

    var Errorstatus = false;

    var arr = [];

    formdata?.map((val, index) => {
      arr.push({ room: [] });
console.log("valuesss",val);
      val.travellerInfo.map((item) => {
        if (item.pt == 'CHILD') {
          delete item?.pan
          delete item?.pNum

          var Errors = validation(Object.keys(item), item);
        }
        else {
          var Errors = validation(Object.keys(item), item);

        }
        let conterror = validation(Object.keys(val?.Contact), val?.Contact);
        arr[arr.length - 1]?.room?.push({ ...Errors, ...conterror });
      });
    });

    arr.map((value) => {
      value.room.map((err) => {
        console.log("errorrs", err);
        if (Object.keys(err)?.length > 0) {
          Errorstatus = true;
          setErr(arr);
          return;
        }
      });
    });

    if (!Errorstatus) {

      setErr([]);
      navigate(
        `/paymentOption?hotelid=${Hotelid}&&optionid=${SelectedData?.id
        }&&travellerdata=${EncryptData(formdata)}&&index=${Selectedroomindex}
        &&searchdata=${EncryptData(SearchData)}
        &&roomid=${Roomid}`
      );

    }
    else {
      // console.log("arrarr",arr);
      toast.error('Fix All Validation')
      scrollTop.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };



  const GetCurrentlocation = async () => {

    let { status, data, msg } = await Hotels.GetCountry({ id: SearchData?.Citydata?.nationality })
    let Country = await Hotels.CurrentCountry()
    if (Country?.data?.country !== data?.code) {
      setcountry(true)
    }




  }
  return (
    <>
      <Header />
      <div className="cmn_pdt">
        <div className="search-page particular-room-details">
          <div className="search-header">
            <div className="container">
              <p className="filter_text mb-0">Review your Booking </p>
            </div>
          </div>

          <Container>
            <Row className="justify-content-center mt-5">
              <Col lg={8}>
                <div className="card card_book mb-3">
                  <Row className="p-3">
                    <Col sm={9}>
                      <h2 className="rd__blackTitle m-0 text-left">
                        {HotelData?.name}
                      </h2>
                      <p className="book_text_light mt-3">
                        {HotelData?.ad?.adr},{HotelData?.ad?.city?.name}-
                        {HotelData?.ad?.postalCode}, {HotelData?.ad?.state?.name}
                      </p>
                    </Col>
                    <Col sm={3} className="text-start text-sm-end">
                      <img
                        src={
                          HotelData?.img?.[0]?.url
                            ? HotelData?.img?.[0]?.url
                            : bookroom
                        }
                        className="img-fluid img_book"
                      />
                    </Col>
                  </Row>
                  <div className="bg_grey_book">
                    <Row className="p-3 align-items-center">
                      <Col md={3}>
                        <p className="book_text_small mb-0">CHECK IN</p>
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="book_text_small mb-0">
                            <span className="px-1">
                              {DateTimeForm({
                                date: SearchData?.Checkin,
                                words: true,
                              })}
                            </span>
                          </p>

                        </div>
                      </Col>
                      <Col md={2}>
                        <span className="badge badge_white_outline text-uppercase">
                          {NightdaysCalculation(
                            SearchData?.Checkin,
                            SearchData?.Checkout
                          )}
                          Night
                        </span>
                      </Col>
                      <Col md={3}>
                        <p className="book_text_small mb-0">CHECK OUT</p>
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="book_text_small mb-0">
                            <span className="px-1">
                              {DateTimeForm({
                                date: SearchData?.Checkout,
                                words: true,
                              })}
                            </span>
                          </p>
                        </div>
                      </Col>
                      <Col md={4}>
                        <p className="book_text_small_bold mb-0">
                          <span>
                            {NightdaysCalculation(
                              SearchData?.Checkin,
                              SearchData?.Checkout
                            )}{" "}
                            Night
                          </span>
                          <span className="px-1">|</span>
                          <span>{SelectedData?.ris?.[0]?.adt} Adults</span>
                          <span className="px-1">|</span>
                          <span>{SearchData?.roomInfo?.length} Room</span>
                        </p>
                      </Col>
                    </Row>
                  </div>

                  <div className="px-3">
                    <div className="d-flex align-items-center justify-content-between flex_title_block">
                      <div className="d-flex flex-column">
                        <p className="black_bold_text mt-3 mb-1">
                          {SelectedData?.ris?.[0]?.rc}
                        </p>
                        <p className="book_text_light">
                          {SelectedData?.ris?.[0]?.adt} Adults
                        </p>
                      </div>
                      {SelectedData?.ris?.[0]?.des && <a
                        className="purple_a_tag"
                        onClick={() =>
                          handleAmenitiesShow(
                            SelectedData?.ris[0]?.des,
                            "Fecilites"
                          )
                        }
                      >
                        More Details
                      </a>}
                    </div>
                  </div>

                  <div className="rd__leftBoxSingleFlex mb-2 px-3">
                    <span className="rd__diamond"></span>
                    <p className="mb-2 list_dot">
                      <span className="dot_li" />
                      {!SelectedData?.cnp?.ifra
                        ? "Room With Free Cancellation"
                        : " Non refundable"}
                    </p>

                    <p className="mb-2 list_tick_grey">
                      <span>
                        <FaCheck className="green_check" />
                      </span>
                      Pay with Crypto
                    </p>
                    <p className="mb-2 list_tick_green">
                      <span>
                        <FaCheck className="green_check" />
                      </span>
                      Free Cancellation till check-in
                      <span className="ps-2">
                        <Link to="/cms?content=canellationpolicy">
                          Cancellation policy details
                        </Link>
                      </span>
                    </p>
                  </div>

                  <div className="px-3">
                    <div className="progress_div">
                      {SelectedData?.cnp?.ifra && (
                        <>
                          <ProgressBar now={100} className="custom_progress" />
                          <p className="refund_text">100% Refund</p>
                        </>
                      )}

                    </div>
                  </div>

                  <div className="card_footer px-3 pb-3">
                    <div className="d-flex align-items-center justify-content-between flex_title_block">
                      <div className="d-flex flex-column">
                        <p className="black_bold_text mt-3 mb-3">
                          Important information
                        </p>
                      </div>
                      <a className="purple_a_tag" onClick={handleInformationShow}>
                        View All
                      </a>
                    </div>

                    {HotelData?.inst?.length > 0 ?
                      (
                        HotelData?.inst?.map((item) => (
                          item?.type == 'POLICIES' &&
                          <>
                            <p className="checkin fw-600 mt-2 mb-2">
                              <span className="fw-800">{item?.type}</span>
                            </p>

                            {
                              Object?.values(JSON.parse(item?.msg)).map((item) => (
                                <p className="mb-2 list_dot">
                                  <span className="dot_li" />
                                  {/* {Describtion_showing(item)}{" "} */}
                                  {item}
                                </p>
                              ))
                            }
                          </>
                        ))
                      )
                      :
                      <>

                        <NodataFound />

                      </>

                    }


                    {/* <p className="mb-2 list_dot">
                   
                   <span className="dot_li" />
                    
                  Room With Free Cancellation. Passport, Aadhar, Govt. ID and Driving License are accepted as ID proof(s)
                  </p>

                  <p className="mb-2 list_dot">
                   
                   <span className="dot_li" />
                    
                   Pets are not allowed.
                  </p>

                  <p className="mb-2 list_dot">
                   
                   <span className="dot_li" />
                    
                   Local ids are allowed
                  </p> */}
                  </div>

                </div>

                <div className="card card_book mb-3 px-2 px-sm-3 py-2 py-sm-3">
                  <div className="guest_details" ref={scrollTop}>
                    <p className="black_bold_text mb-3">Guest Details </p>

                    <div className="">
                      <Form>
                        {SearchData?.roomInfo?.map((item, index) => (
                          <div className="rooms_inp">
                            <p className="mb-2 roomNo">Room{index + 1}</p>
                            {
                              <>
                                <p className="mb-3 adult">Adults</p>

                                {item?.numberOfAdults > 0 &&
                                  [...Array(item?.numberOfAdults)]?.map(
                                    (val, idx) => (
                                      <>
                                        <Row className="">
                                          {/* <Col md={12}>
                      <div>
                <Form.Check
                  inline
                  label="Myself"
                  name="group1"
                  type="radio"
                  className="form_chekc_circle"
                  id={`inline-radio-1`}
                  onChange={()=>onchange('Guesttype','Myself',index,idx,"ADULT")}
                
                />

<Form.Check
                  inline
                  label="Someone Else"
                  name="group1"
                  type="radio"
                  className="form_chekc_circle"
                  id={`inline-radio-2`}
                  onChange={()=>onchange('Guesttype','Someone',index,idx,"ADULT")}

                
                />
                </div>
                {Err[idx]?.Guesttype && (
                                      <p className="text-left err_msg_txt">{Err[idx]?.Guesttype}</p>
                                    )}
                      </Col> */}

                                          <Col md={2} className="mb-3">
                                            <p className="black_label_sm mb-2">
                                              Title
                                            </p>
                                            <Select
                                              value={formdata?.Title}
                                              options={options}
                                              defaultValue="theme1"
                                              styles={stylesgraybg}
                                              isSearchable={false}
                                              classNamePrefix="customselect"
                                              onChange={(e) =>
                                                onchange(
                                                  "ti",
                                                  e?.value,
                                                  index,
                                                  idx,
                                                  "ADULT"
                                                )
                                              }
                                            />
                                            {Err[index]?.room[idx]?.ti && (
                                              <p className="text-left err_msg_txt">
                                                {"Title is required"}
                                              </p>
                                            )}
                                          </Col>
                                          <Col md={5} className="mb-3">
                                            <p className="black_label_sm mb-2">
                                              First Name
                                            </p>
                                            <input
                                              type="text"
                                              placeholder="First Name"
                                              className="cred_input_book"
                                              onChange={(e) =>
                                                onchange(
                                                  "fN",
                                                  e.target.value,
                                                  index,
                                                  idx,
                                                  "ADULT"
                                                )
                                              }
                                            />
                                            {Err[index]?.room[idx]?.fN && (
                                              <p className="text-left err_msg_txt">
                                                {"FirstName is required"}
                                              </p>
                                            )}
                                          </Col>

                                          <Col md={5} className="mb-3">
                                            <p className="black_label_sm mb-2">
                                              Last Name
                                            </p>
                                            <input
                                              type="text"
                                              placeholder="Last Name"
                                              className="cred_input_book"
                                              onChange={(e) =>
                                                onchange(
                                                  "lN",
                                                  e.target.value,
                                                  index,
                                                  idx,
                                                  "ADULT"
                                                )
                                              }
                                            />
                                            {Err[index]?.room[idx]?.lN && (
                                              <p className="text-left err_msg_txt">
                                                {Err[index]?.room[idx]?.lN}
                                              </p>
                                            )}
                                          </Col>

                                          <Col md={7} className="mb-3">
                                            <p className="black_label_sm mb-2">
                                              Email Address
                                              <span className="label_desc ms-1">
                                                (Booking voucher will be sent to
                                                this email ID)
                                              </span>
                                            </p>
                                            <input
                                              type="email"
                                              placeholder="Email"
                                              className="cred_input_book"
                                              onChange={(e) =>
                                                onchange(
                                                  "Email",
                                                  e.target.value,
                                                  index,
                                                  ""
                                                )
                                              }
                                            />
                                            {Err[index]?.room[idx]?.Email && (
                                              <p className="text-left err_msg_txt">
                                                {Err[index]?.room[idx]?.Email}
                                              </p>
                                            )}
                                          </Col>

                                          <Col md={5} className="mb-3">
                                            <p className="black_label_sm mb-2">
                                              Phone Number
                                            </p>

                                            <PhoneInput
                                              className="custom_phoneinput"
                                              international
                                              defaultCountry="IN"
                                              onCountryChange={(e) =>
                                                onchange("Code", e, index, idx)
                                              }
                                              placeholder="Enter phone number"
                                              value={value}
                                              // onChange={setValue}
                                              onChange={(e) =>
                                                onchange(
                                                  "MobileNumber",
                                                  e,
                                                  index,
                                                  idx
                                                )
                                              }
                                            />
                                            {Err[index]?.room[idx]
                                              ?.MobileNumber && (
                                                <p className="text-left err_msg_txt">
                                                  {
                                                    "Phone number is required"
                                                  }
                                                </p>
                                              )}

                                            {Err[index]?.room[idx]
                                              ?.MobileNumberval && (
                                                <p className="text-left err_msg_txt">
                                                  {
                                                    Err[index]?.room[idx]
                                                      ?.MobileNumberval
                                                  }
                                                </p>
                                              )}

                                          </Col>

                                          <Col md={5} className="mb-0">
                                          <p className="black_label_sm mb-2">
                                            Pan Card
                                          </p>
                                          <input
                                            type="texyt"
                                            placeholder="Pancard Number"
                                            className="cred_input_book"
                                            onChange={(e) =>
                                              onchange(
                                                "pan",
                                                e.target.value,
                                                index,
                                                idx,
                                                "ADULT"
                                              )
                                            }
                                          />
                                     

                                          {Err[index]?.room[idx]?.pan && (
                                            <p className="text-left err_msg_txt">
                                              {"Pan card number is required"}
                                            </p>
                                          )}
                                              {Err[index]?.room[idx]?.panval && (
                                            <p className="text-left err_msg_txt">
                                              {Err[index]?.room[idx]?.panval}
                                            </p>
                                          )}
                                        </Col>

                                          {Country &&
                                            <Col md={5} className="mb-3">
                                              <p className="black_label_sm mb-2">
                                                Passport Number
                                              </p>
                                              <input
                                                type="text"
                                                placeholder="Passport Number"
                                                className="cred_input_book"
                                                onChange={(e) =>
                                                  onchange(
                                                    "pNum",
                                                    e.target.value,
                                                    index,
                                                    idx,
                                                    "ADULT"
                                                  )
                                                }
                                              />
                                              {/* <p className="black_label_sm mt-4 mb-2">Please Enter Valid</p> */}
                                              {/* {console.log("Err[index]?.room[idx]?.pNum",Err[index]?.room[idx]?.pNum)} */}
                                              {Err[index]?.room[idx]?.pNum && (
                                                <p className="text-left err_msg_txt">
                                                  {"Passportnumber is required"}
                                                </p>
                                              )}
                                              {Err[index]?.room[idx]?.pNumval && (
                                                <p className="text-left err_msg_txt">
                                                  {Err[index]?.room[idx]?.pNumval}
                                                </p>
                                              )}
                                            </Col>}

                                          {/* <Col md={12}>
                      <Form.Check
                          className="mt-3 check_review_book"
                          name="group1"
                          label="Enter GST Details (Optional)"
                          type="checkbox"
                          id={"new"}
                          onChange={(e)=>onchange('entergst',true,index)}

                        />
                      </Col> */}
                                        </Row>
                                      </>
                                    )
                                  )}
                              </>
                            }

                            {
                              <div className="mt-3">
                                {item?.numberOfChild > 0 && (
                                  <p className="mb-2 adult">Children</p>
                                )}

                                {item?.numberOfChild > 0 &&
                                  [...Array(item?.numberOfChild)]?.map(
                                    (val, idx) => (
                                      <>
                                        <Row className="">
                                          {/* <Col md={2} className="mb-3">
                                          <p className="black_label_sm mb-2">
                                            Title
                                          </p>
                                          <Select
                                            value={formdata?.ti}
                                            options={options}
                                            defaultValue="theme1"
                                            styles={stylesgraybg}
                                            isSearchable={false}
                                            classNamePrefix="customselect"
                                            onChange={(e) =>
                                              onchange(
                                                "ti",
                                                e?.value,
                                                index,
                                                item?.numberOfAdults + idx,
                                                "CHILD"
                                              )
                                            }
                                          />
                                          {Err[index]?.room[
                                            item?.numberOfAdults + idx
                                          ]?.ti && (
                                            <p className="text-left err_msg_txt">
                                              {"Title is required"}
                                            </p>
                                          )}
                                        </Col> */}
                                          <Col md={5} className="mb-3">
                                            <p className="black_label_sm mb-2">
                                              First Name
                                            </p>
                                            <input
                                              type="text"
                                              placeholder="First Name"
                                              className="cred_input_book"
                                              onChange={(e) =>
                                                onchange(
                                                  "fN",
                                                  e.target.value,
                                                  index,
                                                  item?.numberOfAdults + idx,
                                                  "CHILD"
                                                )
                                              }
                                            />
                                            {Err[index]?.room[
                                              item?.numberOfAdults + idx
                                            ]?.fN && (
                                                <p className="text-left err_msg_txt">
                                                  {"FirstName is required"}
                                                </p>
                                              )}
                                          </Col>

                                          <Col md={5} className="mb-3">
                                            <p className="black_label_sm mb-2">
                                              Last Name
                                            </p>
                                            <input
                                              type="text"
                                              placeholder="Last Name"
                                              className="cred_input_book"
                                              onChange={(e) =>
                                                onchange(
                                                  "lN",
                                                  e.target.value,
                                                  index,
                                                  item?.numberOfAdults + idx,
                                                  "CHILD"
                                                )
                                              }
                                            />
                                            {Err[index]?.room[
                                              item?.numberOfAdults + idx
                                            ]?.lN && (
                                                <p className="text-left err_msg_txt">
                                                  {"LastName is required"}
                                                </p>
                                              )}
                                          </Col>
                                        </Row>
                                      </>
                                    )
                                  )}
                              </div>
                            }
                          </div>
                        ))}
                      </Form>
                    </div>
                  </div>
                </div>

                <div className="card card_book mb-3">
                  <div className="px-3">
                    <div className="d-flex align-items-center justify-content-between flex_title_block">
                      <div className="d-flex flex-column">
                        <p className="black_bold_text mt-3 mb-3">Trip Secure</p>
                      </div>
                      <a className="purple_a_tag">View Benefits</a>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-2">
                        <img src={trip} className="img-fluid img_trip" />
                      </div>
                      <div className="col-12 col-md-7 mt-3 mt-md-0">
                        <div className="rd__leftBoxSingleFlex mb-2 px-3">
                          <p className="trip_title">Worry free hotel stay!</p>
                          <div className="row">
                            <div className="col-12 col-lg-6 mb-lg-0">
                              <p className="mb-2 list_dot list_dot_small">
                                <span className="dot_li" />
                                24*7 emergency medical assistance..
                              </p>

                              <p className="mb-2 list_dot list_dot_small">
                                <span className="dot_li" />
                                Upto ₹ {parseInt(10000)} for hotel booking cancellation charges
                                if cancelled due to illness
                              </p>

                              <p className="mb-2 list_dot list_dot_small">
                                <span className="dot_li" />
                                {/* Upto ₹ 20K for bounced booking */}
                                Upto ₹ {parseInt(20000)} for bounced booking

                              </p>
                            </div>
                            <div className="col-12 col-lg-6 mb-3">
                              <p className="mb-2 list_dot list_dot_small">
                                <span className="dot_li" />
                                Upto ₹ {parseInt(20000)} for loss of tablets and laptops
                              </p>

                              <p className="mb-2 list_dot list_dot_small">
                                <span className="dot_li" />
                                Upto ₹ {parseInt(20000)} for loss of baggage
                              </p>

                              <p className="mb-0 list_dot list_dot_small">
                                <span className="dot_li" />
                                Upto ₹ {parseInt(10000)} for emergency travel of family members
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-3 flex_end_md">
                        <div className="progress_text_right">
                          <p className="mb-0">₹ {parseInt(29)}</p>
                          <p className="text_light_grey mb-0">per person</p>
                          <p className="text_light_grey">
                            Non Refundable | 18% GST Included
                          </p>
                          <div className="text-end">
                            <button className="add_btn_puple mt-0 mb-4 ms-auto d-flex align-items-center justify-content-center gap-1">
                              <FaPlus className="mr-2" /> Add
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card card_book mb-3">
                  <div className="px-3">
                    <p className="black_bold_text mt-3 mb-3">Payment Options</p>

                    <div className="px-0">
                      <Form>
                        <Row className="mt-3">
                          <Col md={12}>
                            <div>
                              <Form.Check
                                type={"radio"}
                                id={`check-api-radio`}
                                className="checkbox_multiline"
                              >
                                <Form.Check.Input type={"radio"} />
                                <Form.Check.Label>
                                  Make full payment with other options
                                </Form.Check.Label>
                                <Form.Control.Feedback className="custom_feedback">
                                  Cancel for free any time before 29 Mar
                                </Form.Control.Feedback>
                              </Form.Check>

                              {/* <Form.Check
                     inline
                     label="Make full payment with other options"
                     name="group1"
                     type="radio"
                     className="form_chekc_circle"
                     id={`inline-radio-1`}
                   
                   /> */}

                              {/* <Form.Check
                     inline
                     label="Someone Else"
                     name="group1"
                     type="radio"
                     className="form_chekc_circle"
                     id={`inline-radio-2`}
                   
                   /> */}
                            </div>
                            <hr className="hr_grey" />
                            <div className="mb-4">
                              <Form.Check
                                type={"radio"}
                                id={`check-api-radio1`}
                                className="checkbox_multiline disabled_check"
                              >
                                <Form.Check.Input type={"radio"} disabled />
                                <Form.Check.Label>
                                  Book with ₹0 Payment
                                </Form.Check.Label>
                                <Form.Control.Feedback className="custom_feedback">
                                  You will need to remove the WELCOMEMMT coupon to
                                  avail this option
                                </Form.Control.Feedback>
                              </Form.Check>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </div>
                </div>

                <p className="m-0 rd__secondaryHint text-left mt-2">
                  By proceeding, I agree to TripFi’s User Agreement, Terms of
                  Service and Cancellation & Property Booking Policies.
                </p>
              </Col>
              <Col lg={4} className="mt-3 mt-lg-0">
                <div className="card card_book mb-3">
                  <div className="px-3">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex flex-column">
                        <p className="black_bold_text mt-3 mb-3">Price Breakup</p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-7 col-md-8">
                        <p className="room_dark mb-0">{SearchData?.roomInfo?.length}Room x
                          {isNaN(NightdaysCalculation(
                            SearchData?.Checkin,
                            SearchData?.Checkout
                          )) ? 0
                            :
                            NightdaysCalculation(
                              SearchData?.Checkin,
                              SearchData?.Checkout
                            )
                          }{" "}
                          Night</p>
                        <p className="room_light mb-0">Base Price</p>
                      </div>
                      <div className="col-5 col-md-4">
                        <p className="text-end room_dark mb-0">
                        ₹{parseInt(SelectedData?.ris?.[0]?.tfcs.BF)}

                          {/* {console.log("selectedddd",SelectedData?.ris?.[0]?.tfcs.BF
)} */}

                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-7 col-md-8">
                        <p className="room_green mb-0">
                          Commission
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip1}
                          >
                            <Button
                              variant="success"
                              className="tooltip_btn_trans"
                            >
                              {/* <FaInfoCircle className="mx-2" /> */}
                            </Button>
                          </OverlayTrigger>
                        </p>
                      </div>
                      <div className="col-5 col-md-4">
                        {/* <p className="text-end room_green mb-0">₹ {SelectedData?.ris?.[0]?.tfcs.BF}</p> */}
                        <p className="text-end room_green mb-0">₹ {isNaN(Commissions) ? 0 : (parseInt(Commissions))}</p>


                      </div>
                    </div>
                    <hr className="hr_grey" />

                    <div className="row">
                      <div className="col-7 col-md-8">
                        <p className="room_dark mb-0"> Discount Price</p>
                      </div>
                      <div className="col-5 col-md-4">
                        <p className="text-end room_dark mb-0">
                        ₹ {parseInt(discount)}
                        </p>
                      </div>
                    </div>
                    <hr className="hr_grey" />

                    <div className="row">
                      <div className="col-7 col-md-8">
                        <p className="room_dark mb-0">
                          Taxes & Service Fees
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip1}
                          >
                            <Button
                              variant="success"
                              className="tooltip_btn_trans"
                            >
                              <FaInfoCircle className="mx-2" />
                            </Button>
                          </OverlayTrigger>
                        </p>
                      </div>
                      <div className="col-5 col-md-4">
                        <p className="text-end room_dark mb-0">

                        ₹ {parseInt(SelectedData?.ris?.[0]?.tfcs.TAF)}
                        </p>
                      </div>
                    </div>
                    <hr className="hr_grey" />
                    <Form.Check
                      type={"checkbox"}
                      id={`check-api-checkbox`}
                      className="checkbox_multiline check_with_a"
                    >
                      <Form.Check.Input
                        type={"checkbox"}
                        onClick={(e) => {
                          setDonateamountstatus(!Donateamountstatus);
                        }}
                      />
                      {/* <Form.Check.Label> */}
                      <div className="d-flex">
                        <p>
                          <span>
                            Donate ₹{parseInt(Donateamount)} to build resilient travel
                            destinations.
                          </span>{" "}
                          <span className="list_tick_green">
                            <Link to="/cms?content=termsandconditions" className="">
                              T&Cs
                            </Link>
                          </span>
                        </p>
                        <p className="min_w_100">
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                          >
                            <Button
                              variant="success"
                              className="tooltip_btn_trans"
                            >
                              <FaInfoCircle className="me-2" />
                            </Button>
                          </OverlayTrigger>
                          <span>₹ 0</span>
                        </p>
                      </div>
                      {/* </Form.Check.Label> */}
                    </Form.Check>
                  </div>

                  <div className="card_footer card_footer_grey px-3 py-3">
                    <div className="row">
                      <div className="col-7 col-md-8">
                        <p className="room_dark_footer mb-0">
                          Total Amount to be paid
                        </p>
                      </div>
                      <div className="col-5 col-md-4">
                        <p className="text-end room_dark_footer mb-0">
                        ₹ {isNaN(TotalAmount) ? 0 : (parseInt(TotalAmount))}
                          {/* ₹ {SelectedData?.tp} */}

                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card card_success mb-3 p-3">
                  <div className="d-flex align-items-center">
                    <img src={successdollar} className="icon_card_img me-3" />

                    <div>
                      <p className="mb-0">Congrats! You’ll receive approx.</p>
                      <p className="mb-0">
                        <span>US$1.98</span> giveback in Trip on this booking
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card card_success mb-3 p-3">
                  <div className="d-flex align-items-center">
                    <img src={successtick} className="icon_card_img me-3" />

                    <div>
                      <p className="mb-0 text_green">
                        Earn a proof-of-travel NFT stamp for this booking
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card card_danger mb-3 p-3">
                  <div className="d-flex align-items-center">
                    <img src={dangertimer} className="icon_card_img me-3" />

                    <div>
                      <p className="mb-0 text_red">
                        Hurry! We only have 5 rooms left at this price
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card card_book mb-3 pb-4">
                  <div className="px-3">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex flex-column">
                        <p className="black_bold_text mt-3 mb-3">Coupon Codes</p>

                        {coupons.map((item, index) => {
                          return <CouponCard data={item} Currency={CurrencyConvert} />;
                        })}

                        <div className="card card_pink mb-3 p-3">
                          <p className="mb-0 text_red">
                            PT Gift Cards can be applied at payment step
                          </p>
                        </div>

                        <div className="search_input_grp">
                          <input
                            type="email"
                            placeholder="Enter your voucher code"
                          />
                          <Button className="btn">
                            <FaArrowRight />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            {/* "/paymentOption" */}
            <Button
              type="button"
              className="mt-3  pay-btn btn btn-primary mt-3 mb-4"
              onClick={() => FormSubmit()}
            >
              PAY NOW
            </Button>
          </Container>
        </div>
      </div>
      <Footer />
      {inclusionShow && (
        <InclusionModal
          inclusionShow={inclusionShow}
          handleInclusionClose={handleInclusionClose}
        />
      )}

      {amenitiesShow && Fecilities && type && (
        <Fecility
          amenitiesShow={amenitiesShow}
          handleAmenitiesClose={handleAmenitiesClose}
          Fecilities={Fecilities}
          Type={type}
        />
      )}

      {informationShow && HotelData && (
        <InformationModal
          informationShow={informationShow}
          handleInformationClose={handleInformationClose}
          Hoteldata={HotelData}
        />
      )}


    </>
  );
}
