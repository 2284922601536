import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import Image from "../components/Image";
import { Link, useNavigate } from "react-router-dom";
import LoginModal from "./LoginModal";
import { LoadToast, isEmpty, updateToast, validation } from "../lib/common";
import * as User from "../Axios/user.axios";
import { IoEyeOutline } from "react-icons/io5";
import { IoEyeOffOutline } from "react-icons/io5";
const SignupModal = ({
  signupShow,
  handleSignupClose,
  handleLogin,
  handleotp,
  UserEmail,
  setotp,
}) => {
  const initial = {
    FirstName: "",
    LastName: "",
    Email: "",
    CreatePassword: "",
    ConfirmPassword: "",
  };

  const [formdata, setFormdata] = useState(initial);
  const [err, setErr] = useState({});
  const [passwordshow, setpasswordshow] = useState(false);
  const [createPwdEyeshow, setCreatePwdEyeshow] = useState(false);
  const [cnfmPwdEyeshow, setCnfmPwdEyeshow] = useState(false);
  const [loader, setloader] = useState(false);
  const navigate = useNavigate();

  const onchange = (e) => {
    setloader(false);
    setErr({});
    const { id, value } = e.target;
    setFormdata({ ...formdata, ...{ [id]: value } });
  };

  const submit = async () => {
    setloader(true);
    const id = LoadToast();
    let err = await validation(Object.keys(initial), formdata);

    if (Object.keys(err)?.length > 0) {
      updateToast(id, "fix");
      setErr(err);
      setloader(false);

      return;
    }
    if(formdata?.ConfirmPassword!=formdata?.CreatePassword){
      updateToast(id, "Confirm password must be same as the create password ","error");
      setErr(err);
      setloader(false);

      return;
    }
    setErr({});
    formdata.verifyStatus = false;
    var { success, msg, data } = await User.Register(formdata);

    updateToast(id, msg, success);
    if (success == "success") {
      setloader(false);

      OTPshow();
      UserEmail(formdata?.Email);
      setotp(data);
    } else {
      setloader(false);
      setErr(err);
    }
  };
  const Loginshow = () => {
    handleLogin(true);
    handleSignupClose();
  };
  const OTPshow = () => {
    handleotp(true);
    handleSignupClose();
  };

  return (
    <div>
      <Modal
        show={signupShow}
        onHide={handleSignupClose}
        centered
        size="xl"
        className="custom_modal signup_modal common_modal"
      >
        <Modal.Header closeButton className="p-0">
          {/* <Modal.Title>
       
            </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row className="mx-auto sgn_row">
            <Col xl={5} className="d-none d-xl-block p-0">
              {/* <div className="opacticy_bg d-flex align-items-center justify-content-center">
                <div className="login_mdl_right no_border">
                  <div className="d-flex flex-column align-items-center justify-content-center  sign_optn">
                    <p>Registered With Smart way</p>
                    <div>
                      <button>
                        <img src={Image.eth} alt="eth" className="img-fluid" />
                      </button>{" "}
                      <button>
                        <img
                          src={Image.google}
                          alt="google"
                          className="img-fluid"
                        />
                      </button>{" "}
                      <button>
                        <img
                          src={Image.message}
                          alt="message"
                          className="img-fluid"
                        />
                      </button>{" "}
                      <button>
                        <img
                          src={Image.facebook}
                          alt="facebook"
                          className="img-fluid"
                        />
                      </button>{" "}
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="login_mdl_left h-100">
                <div className="opacticy_bg">
                  {/* <OwlCarousel
                    id="customer-testimonoals"
                    className="modal_owl_carousel"
                    {...options}
                  > */}
                  <div className="h-100 d-flex flex-column justify-content-evenly align-items-center">
                    <div>
                      {/* <h4 className="title_bold text-center">TripFi.io</h4> */}
                      <h4 className="title_bold text-center">TripFi</h4>

                      <p className="text-center mdl_subtitle">
                        BOOK 3M+ HOTELS, FLIGHTS & ACTIVITIE WITH CRYPTO
                      </p>
                      <div className="d-flex justify-content-center">
                        <img
                          src={Image.coins}
                          alt="coin"
                          className="img-fluid coin_img"
                        />
                      </div>
                    </div>
                    <div>
                      <h5 className="acc_create mb-4">
                        Create an account or sign in to
                      </h5>
                      <div className="d-flex align-items-center gap-2 benefits mb-2">
                        <img
                          src={Image.tick}
                          alt="tick"
                          className="img-fluid"
                        />{" "}
                        <p className="mb-0">
                          Access 90+ Crypto Payment Options
                        </p>
                      </div>
                      <div className="d-flex align-items-center gap-2 benefits mb-2">
                        <img
                          src={Image.tick}
                          alt="tick"
                          className="img-fluid"
                        />{" "}
                        <p className="mb-0">Get Up To 10% Back In Rewards</p>
                      </div>
                      <div className="d-flex align-items-center gap-2 benefits mb-4">
                        <img
                          src={Image.tick}
                          alt="tick"
                          className="img-fluid"
                        />{" "}
                        <p className="mb-0">See Exclusive Member pricing</p>
                      </div>
                    </div>
                  </div>{" "}
                  {/* </OwlCarousel> */}
                </div>
              </div>
            </Col>
            <Col xs={12} xl={7} className="p-0">
              <div className="login_mdl_right">
                <h4 className="modal_title">Signup</h4>
                <div className="mt-3 mt-xl-4">
                  <p className="thm_labl mb-2">First Name</p>
                  <input
                    type="text"
                    placeholder="First Name"
                    className="cred_input"
                    id="FirstName"
                    value={formdata.FirstName}
                    onChange={onchange}
                  />{" "}
                  {err?.FirstName && (
                    <p className="text-left err_msg_txt mb-0">{'First Name is required'}</p>
                  )}
                </div>
                <div className="mt-3 mt-xl-4">
                  <p className="thm_labl mb-2">Last Name</p>
                  <input
                    type="text"
                    placeholder="Last Name"
                    className="cred_input"
                    id="LastName"
                    value={formdata.LastName}
                    onChange={onchange}
                  />{" "}
                  {err?.LastName && (
                    <p className="text-left err_msg_txt mb-0">{'Last Name is required'}</p>
                  )}
                </div>
                <div className="mt-3 mt-xl-4">
                  <p className="thm_labl mb-2">Email</p>
                  <input
                    type="email"
                    placeholder="Email"
                    className="cred_input"
                    id="Email"
                    value={formdata.Email}
                    onChange={onchange}
                  />
                  {err?.Email && (
                    <p className="text-left err_msg_txt mb-0">{err?.Email}</p>
                  )}
                </div>
                <div className="mt-3 mt-xl-4">
                  <p className="thm_labl mb-2">Create Password</p>
                  <div className="cus-pwdInp">
                    <input
                      type={createPwdEyeshow ? "text" : "password"}
                      placeholder="Enter password"
                      className="cred_input"
                      id="CreatePassword"
                      value={formdata.CreatePassword}
                      onChange={onchange}
                    />
                    <span className="showHideIcon">
                      {createPwdEyeshow ? (
                        <IoEyeOutline
                          onClick={() => setCreatePwdEyeshow(!createPwdEyeshow)}
                        />
                      ) : (
                        <IoEyeOffOutline
                          onClick={() => setCreatePwdEyeshow(!createPwdEyeshow)}
                        />
                      )}
                    </span>
                  </div>
                  {err?.CreatePassword && (
                    <p className="text-left err_msg_txt mb-0">
                      {err?.CreatePassword}
                    </p>
                  )}
                </div>
                <div className="mt-3 mt-xl-4">
                  <p className="thm_labl mb-2">Confirm Password</p>
                  <div className="cus-pwdInp">
                    <input
                      type={cnfmPwdEyeshow ? "text" : "password"}
                      placeholder="Enter password"
                      className="cred_input"
                      id="ConfirmPassword"
                      value={formdata.ConfirmPassword}
                      onChange={onchange}
                    />
                    <span className="showHideIcon">
                      {cnfmPwdEyeshow ? (
                        <IoEyeOutline
                          onClick={() => setCnfmPwdEyeshow(!cnfmPwdEyeshow)}
                        />
                      ) : (
                        <IoEyeOffOutline
                          onClick={() => setCnfmPwdEyeshow(!cnfmPwdEyeshow)}
                        />
                      )}
                    </span>
                  </div>
                  {err?.ConfirmPassword && (
                    <p className="text-left err_msg_txt mb-0">
                      {err?.ConfirmPassword}
                    </p>
                  )}
                </div>
                {loader ? (
                  <button className="themeButton my-4 disabled">
                    Processing...
                  </button>
                ) : (
                  <button
                    className="themeButton mt-4 mb-3"
                    onClick={() => submit()}
                  >
                    Continue
                  </button>
                )}
                <div className="d-flex justify-content-center mt-3 mb-3 or_section">
                  <p className="mb-0 position-relative">OR</p>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center  sign_optn">
                  {/* <p>Registered With Smart way</p> */}
                  <div>
                    {/* <button>
                      <img src={Image.eth} alt="eth" className="img-fluid" />
                    </button>{" "} */}
                    <button>
                      <img
                        src={Image.google}
                        alt="google"
                        className="img-fluid"
                      />
                    </button>{" "}
                    {/* <button>
                      <img
                        src={Image.message}
                        alt="message"
                        className="img-fluid"
                      />
                    </button>{" "} */}
                    {/* <button>
                      <img
                        src={Image.facebook}
                        alt="facebook"
                        className="img-fluid"
                      />
                    </button>{" "} */}
                  </div>
                </div>
                <p className="black_label my-3 text-center">
                  By clicking 'Register', I agree to the Terms of
                  <br></br>Use and 
                  <Link to="">
                  <span className="sgn_term_btn" onClick={()=>navigate("/privacypolicy")}>Privacy Policy</span>
                  </Link>
                </p>
                <div className="sgn_bot_sec">
                  <p className="mb-0">
                    Already registered ?{" "}
                    <span className="sgn_term_btn" onClick={() => Loginshow()}>
                      {" "}
                      LOGIN
                    </span>
                  </p>
                </div>
              </div>
            </Col>{" "}
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SignupModal;
