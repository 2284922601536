import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Dropdown from "react-bootstrap/Dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/esm/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link, useLocation } from "react-router-dom";

import Image from "../components/Image";
import { Col, Container, Row, Form, ProgressBar } from "react-bootstrap";

import "react-phone-number-input/style.css";
import config from "../lib/config"
export default function BlogDetails(props) {
  console.log("sdefdsffg",props);
  let location = useLocation()
  console.log("locatinsdfdfn",location);
  let records = location?.state
  return (
    <>
      <Header />
      <div className="cmn_pdt blog_details">
        <div className="search-page">
          <div className="search-header">
            <div className="container">
              <p className="filter_text mb-0">Blog Details</p>
            </div>
          </div>
        </div>
        <Container className="pt-5 pb-4">
          <h3 className="title-txt mt-0 mb-3">{records?.Heading}</h3>

          <p className="desc_txt mt-0 mb-0"><div dangerouslySetInnerHTML={{__html:records?.Content}}></div></p>
          <div className="img_sec mt-4">
            {/* <img src={Image.bannerImg} className="img-fluid" /> */}
            <img src={ `${config.IMG_URL}/blogs/${records?.Symbol}/${records?.Image}`} className="img-fluid" />

           
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
}
